import {  useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ServerCard from "./ServerCard"
import APAAccountCard from "../APA/APAAccountCard"
import { APAAccount, ServerData } from "../../types/types"
import { useStore } from "../../contexts/StoreContext"

const ServerAndAccountCard: React.FC<{ server: ServerData }> = ({ server }) => {
    const [accs, setAccs] = useState<APAAccount[]>([])
    const { accounts } = useStore()

    const navigate = useNavigate()


    useEffect(() => {
        if (accounts) {
            let accs = accounts.filter(a => a.serverId === server.id)
            setAccs(accs)
        }
    }, [accounts])

    return <div><ServerCard key={server.id} server={server} onClick={() => navigate(`/apa/${server.id}`)} />
        {
            accs.map((acc, accId) =>
                <><APAAccountCard key={accId} serverId={server.id.toString()} account={acc} onClick={() => navigate(`/apa/${server.id}/${acc.id}`)} /></>
            )
        }
    </div>

}

export default ServerAndAccountCard