import { ReactNode, useState } from "react"
import { HiPencil } from "react-icons/hi"
import { AggregationType, BucketTemplate, BucketTemplateCategory, Position, ReportTemplate, getPossibleAggTypes } from "../../types/template_types"
import { Modal } from "../Modals"
import ShowIf from "../ShowIf"
import Select from "../Select";
import CustomCheckbox from "../Buttons/CustomCheckbox";


interface ReportTemplateMatrixConfigProps {
    tmpReport: ReportTemplate,
    setTmpReport: React.Dispatch<React.SetStateAction<ReportTemplate | undefined>>,
    bucket: BucketTemplate,
}

const ReportTemplateMatrixConfig = ({tmpReport, setTmpReport, bucket, ...props}: ReportTemplateMatrixConfigProps) => {

    const [tmpDescription, setTmpDescription] = useState("")
    const [tmpPosition, setTmpPosition] = useState<Position>()
    const [showEditDescriptions, setShowEditDescription] = useState(false)

    const editDescription = (pos: Position) => {
        setTmpPosition(pos)
        const description = tmpReport.view_settings.actual.overview.descriptions.find(d => d.position.x === pos.x && d.position.y === pos.y)
        const text = description?.text.join("\n") ?? ""
        setTmpDescription(text)
        setShowEditDescription(true)
    }

    const saveDescription = () => {
        if (tmpPosition === undefined) return
        const text = tmpDescription.trim()
        let tmp = structuredClone(tmpReport)
        const index = tmp.view_settings.actual.overview.descriptions.findIndex(d => d.position.x === tmpPosition.x && d.position.y === tmpPosition.y)
        if (text === "") {
            if (index > -1) {
                tmp.view_settings.actual.overview.descriptions.splice(index, 1)
            }
        } else {
            if (index > -1) {
                tmp.view_settings.actual.overview.descriptions[index].text = text.split("\n")
            } else {
                tmp.view_settings.actual.overview.descriptions.push({position: tmpPosition, text: text.split("\n")})
            }
        }

        setTmpReport(tmp)
        setShowEditDescription(false)
        setTmpPosition(undefined)
        setTmpDescription("")
    }

    const onColumnChange = (column: string) => {
        let tmp = structuredClone(tmpReport)
        const oldCol = bucket.columns.find(x => x.name === tmp.view_settings.actual.overview.selectedColumn)
        const newCol = bucket.columns.find(x => x.name === column)
        tmp.view_settings.actual.overview.selectedColumn = column
        if (oldCol?.type !== newCol?.type) {
            tmp.view_settings.actual.overview.selectedAggregation = getPossibleAggTypes(column, bucket)[0].value
        }
        setTmpReport(tmp)
    }

    const onAggregationChange = (agg: AggregationType) => {
        let tmp = structuredClone(tmpReport)
        tmp.view_settings.actual.overview.selectedAggregation = agg
        setTmpReport(tmp)
    }

    const onDecimalChange = (checked: boolean) => {
        let tmp = structuredClone(tmpReport)
        tmp.view_settings.actual.overview.useDecimals = checked
        setTmpReport(tmp)
    }

    const onPercentageChange = (checked: boolean) => {
        let tmp = structuredClone(tmpReport)
        tmp.view_settings.actual.overview.usePercentage = checked
        setTmpReport(tmp)
    }

    const EmptyCategoryCard = () => {
        return <div className='w-[200px] h-[100px] border opacity-0'>
        </div>
    }

    const CategoryCard = ({cat, pos}: {cat: BucketTemplateCategory, pos: Position}) => {
        const description = tmpReport.view_settings.actual.overview.descriptions ? tmpReport.view_settings.actual.overview.descriptions.find(d => d.position.x === pos.x && d.position.y === pos.y) : null
        return <div className="flex flex-col justify-end gap-1">
            <ShowIf if={cat.is_custom_category}>
                <div onClick={() => editDescription(pos)} className="relative group border rounded min-h-[20px] w-[180px] p-2 bg-white cursor-pointer flex flex-col">
                    <div className="rounded-full flex justify-center p-1 items-center text-sm absolute right-[-10px] top-[-10px] bg-white border group-hover:bg-almondine-mist-400">
                        <HiPencil />
                    </div>
                    {
                        description ? 
                            description.text.map(t => <span className="text-center">{t}</span>)
                            : <span className="opacity-50 text-center">No custom description</span>
                    }
                </div>
            </ShowIf>
            <div className='flex flex-col items-center w-[180px] h-[100px] border rounded-xl overflow-hidden bg-white' title='Click to edit'>
                <div className="bg-[#5d5959] w-full text-white text-center h-[40px] flex items-center justify-center">
                {cat.name}
                </div>
                <ShowIf if={cat.is_custom_category}>
                    <span className='text-sm opacity-50'>Custom</span>
                </ShowIf>
            </div>
        </div>
    }

    let gridHeight = bucket.categorization.map(c => c.position?.y ?? 0).reduce((a, b) => Math.max(a, b), 2)

    const categoryGrid: ReactNode[][] = []
    for (let row = 0; row <= gridHeight; row++) {
        categoryGrid[row] = []
        for (let col = 0; col < 3; col++) {
            categoryGrid[row][col] = <EmptyCategoryCard key={`${row}-${col}-Empty`} />
        }
    }
    bucket.categorization.forEach((c) => {
        if (c.position === undefined) c.position = {x: 0, y: 0}
        categoryGrid[c.position.y][c.position.x] = <CategoryCard pos={c.position} cat={c} key={`${c.position.y}-${c.position.x}`} />
    })

    return <>
        <div>
            <Select className="w-full" value={tmpReport.view_settings.actual.overview.selectedColumn} onChange={(e) => onColumnChange(e.target.value)}>
                    <option value={""}>Default (usually an ID column)</option>
                {
                    tmpReport.columns.map((x, i) => <option key={i} value={x}>{x}</option>)
                }
            </Select>
            <div className="flex w-full">
                <Select className="grow w-full" value={tmpReport.view_settings.actual.overview.selectedAggregation} onChange={(e) => onAggregationChange(e.target.value as AggregationType)}>
                    <option value={""}>Default (usually 'count')</option>
                    {
                        getPossibleAggTypes(tmpReport.view_settings.actual.overview.selectedColumn, bucket).map((x, i) => <option key={i} value={x.value}>{x.label}</option>)
                    }
                </Select>
                <CustomCheckbox text="0.0" value={tmpReport.view_settings.actual.overview.useDecimals ?? false} onChange={(val) => onDecimalChange(val)} />
                <CustomCheckbox text="%" value={tmpReport.view_settings.actual.overview.usePercentage ?? false} onChange={(val) => onPercentageChange(val)} />
            </div>
        </div>
        <div className='flex flex-col divide-y divide-gray-200'>
            <div className='grid grid-cols-3 w-fit gap-2'>
                {
                    categoryGrid
                }
            </div>
        </div>

        <Modal
            isOpen={showEditDescriptions}
            title="Edit description"
            onClose={() => {setShowEditDescription(false); setTmpDescription(""); setTmpPosition(undefined)}}
            onAction={saveDescription}
            actionText="Save"
            closeText="Cancel"
            size="small"
        >
            <textarea id='new-desc' className='text-sm w-full' value={tmpDescription} onChange={e => setTmpDescription(e.target.value)} />
        </Modal>
    </>
}

export default ReportTemplateMatrixConfig
