import { useState, useEffect, useMemo } from "react"
import CustomCombobox from "../CustomCombobox"
import { CloudDashboard, CloudReport } from "../../types/cloud_types"
import { canUseChecklists, canUseDashboards, canUseReports } from "../../helpers/permissions"
import { FaFile, FaTachometerAlt, FaTasks, FaTimes } from "react-icons/fa"

export default function EditHomePageSetup(props: any) {
    const [selectedDashboard, setSelectedDashboard] = useState(() => {
        const dashboard = props.user.dashboards?.find((d: CloudDashboard) => d.id === props.homeDashboard)
        return dashboard ? { label: dashboard.title, value: dashboard.id } : null
    })
    const [selectedFavouriteReports, setSelectedFavouriteReports] = useState(!!props.favReports ? props.favReports : [])
    const reportsOptions = useMemo<any[]>(
        () => {

            if (!!props.user.reports) {
                return props.user.reports
                    .filter((i: CloudReport) => selectedFavouriteReports.find((si: any) => i.report_id === si.value) == null)
                    .sort((a: CloudReport, b: CloudReport) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    .map((r: CloudReport) => {
                        return { label: r.name, value: r.report_id }
                    })
            } else { return []; }
        },
        [props.user.reports, selectedFavouriteReports, props.user.reports?.length]
    )
    const dashboardOptions = useMemo(
        () => {
            return props.user.dashboards
                ?.filter((i: CloudDashboard) => selectedDashboard?.value !== i.id)
                .map((d: CloudDashboard) => {
                    return { id: d.id, label: d.title, value: d.id }
                })
        },
        [props.user.dashboards, selectedDashboard]
    )

    useEffect(() => {
        if (!selectedDashboard && !!props.home_dashboard) setSelectedDashboard(props.user.dashboards?.find((d: CloudDashboard) => d.id === props.user.home_dashboard))
        if (!selectedFavouriteReports && !!props.favReports)
            setSelectedFavouriteReports(
                props.favReports?.map((fr: CloudReport) => {
                    return props.reports.find((r: CloudReport) => r.report_id === fr.report_id)
                })
            )
    }, [])

    useEffect(() => {
        props.homepageSetupChangeHandler(selectedDashboard, "dashboard")
    }, [selectedDashboard])

    useEffect(() => {
        props.homepageSetupChangeHandler(selectedFavouriteReports, "favouriteReports")
    }, [selectedFavouriteReports])

    function changeFavouriteReportHandler(e: any) {
        const fr = [...selectedFavouriteReports]
        fr.push(e)
        setSelectedFavouriteReports(fr)
    }

    function removeFavouriteReportHandler(value: any) {
        const fr = [...selectedFavouriteReports].filter((fr) => fr.value !== value.value)
        setSelectedFavouriteReports(fr)
    }

    function changeDashboardHandler(value: any) {
        setSelectedDashboard(value)
    }
    function removeDashboardHandler() {
        setSelectedDashboard(null)
    }

    return (
        <div className="max-w-lg mx-auto">
            <section className="mb-3 p-3 border">
                <h3 className="ml-2 mb-2 font-bold text-md text-slate-700">Home Dashboard</h3>
                <div className="card-body p-3 d-flex flex-column">
                    <div className="mb-3">
                        {
                            <CustomCombobox disabled={!canUseDashboards(props.permissions)} options={dashboardOptions} setSelectedOption={changeDashboardHandler} ></CustomCombobox>
                        }
                    </div>

                    <div className="flex-fill mw-50">
                        {!canUseDashboards(props.permissions) ? (
                            <div className="text-center">
                                <i>The user does not have permission to view dashboards.</i>{" "}
                            </div>
                        ) : !!selectedDashboard ? (
                            <h4 className="font-weight-normal flex mt-1 mb-0 p-0 items-center position-relative">
                                <span className=" bg-tangerine-100 border text-sm rounded-md bottom-0 text-stone-800 font-weight-medium p-1 px-3 m-0 flex items-center ">
                                    <FaTachometerAlt className="mr-2"></FaTachometerAlt>
                                    <span>{selectedDashboard?.label} </span>
                                    <button onClick={() => removeDashboardHandler()} className=" btn btn-text p-0 m-0 ml-3 lh-0 ">
                                        <FaTimes></FaTimes>
                                    </button>
                                </span>
                            </h4>
                        ) : (
                            <div className="text-center">
                                <i>No Dashboard selected</i>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <section className="p-3 flex flex-col mb-3 border">
                <h3 className="ml-2 mb-2 font-bold text-md text-slate-700">Favourite Insights</h3>
                <div className="mb-2">
                    <CustomCombobox disabled={!canUseReports(props.permissions)} options={reportsOptions} setSelectedOption={changeFavouriteReportHandler}  ></CustomCombobox>
                </div>

                {!canUseReports(props.permissions) ? (
                    <div className="text-center">
                        <i>The user does not have permission to view Insights.</i>{" "}
                    </div>
                ) : selectedFavouriteReports?.length > 0 ? (
                    <section className="">
                        <ul className="list-group list-group-flush p-0 m-0">
                            {selectedFavouriteReports.map((r: any) => {
                                return (
                                    <li className="list-group-item border-0 p-0 mt-1" key={r.value}>
                                        <h4 className="font-weight-normal flex align-items-center position-relative m-0 mt-1">
                                            <span className="bg-tangerine-100 border text-sm rounded-md bottom-0 text-stone-800 font-weight-medium p-1 px-3 m-0 flex items-center ">
                                                <FaFile></FaFile>
                                                <i className="fa fa-file mr-2 text-primary"></i>
                                                <span>{r.label} </span>
                                                <button onClick={() => removeFavouriteReportHandler(r)} className=" btn btn-text p-0 m-0 ml-3 lh-0 ">
                                                    <FaTimes></FaTimes>
                                                </button>
                                            </span>
                                        </h4>
                                    </li>
                                )
                            })}
                        </ul>
                    </section>
                ) : (
                    <div className="text-center">
                        <i>No Favourite Insights selected</i>
                    </div>
                )}
            </section>
            {props.mode === "edit" && (
                <>
                    <section className="mb-3 p-3 py-1 border">
                        <h3 className="ml-2 mb-2 font-bold text-md text-slate-700">Assigned Actions</h3>
                        {!canUseChecklists(props.permissions) ? (
                            <div className="text-center">
                                <i>The user does not have permission to view Actions.</i>{" "}
                            </div>
                        ) : props.user.checklists?.length > 0 ? (
                            <ul className="list-group list-group-flush mb-1 ml-2">
                                {props.user.checklists.map((c: any) => {
                                    return (
                                        <li key={c.id} className="list-group-item border-0 pl-0 pt-2 pb-0 my-0">
                                            <h4 className="font-weight-normal text-sm flex items-center position-relative p-0 m-0">
                                                <span className="fw-normal   bottom-0 text-muted font-weight-medium p-1 px-3 flex items-center ">
                                                    <FaTasks className="mr-2"></FaTasks>
                                                    <span>{c.name} </span>
                                                </span>
                                            </h4>
                                        </li>
                                    )
                                })}
                            </ul>
                        ) : (
                            <div className="text-center">
                                <i>No Actions assigned</i>
                            </div>
                        )}
                    </section>
                </>
            )}
        </div>
    )
}
