import { Store } from "../contexts/StoreContext";
import { ServerAction } from "./actions";

export function serverReducer(state: Store, action: ServerAction) {
    switch (action.type) {
        case 'change_server': {
            const servers = [...state.servers]
            const index = servers.findIndex(server => action.id === server.id)
            servers.splice(index, 1, action.server)
            return servers;
        }
        case 'set_servers': {
            return action.servers
        }
        default: {
            return state.servers
        }
    }

}
