import {
    CloudOrgInfo,
    CloudTrackedBucket,
    CloudTrackedBucketStatus,
} from "../types/cloud_types";
import { del, get, post } from "../helpers/Requests";
import { useEffect, useState } from "react";
import { Bucket } from "../types/bucket_types";
import BucketsTable from "../components/Tracked/BucketsTable";
import { useNavigate } from "react-router-dom";

interface OrganizationBucketSetupProps {
    org: CloudOrgInfo;
}

const OrganizationBucketSetup = ({
    org
}: OrganizationBucketSetupProps) => {
    const [buckets, setBuckets] = useState<Bucket[]>();
    const navigate = useNavigate();

    useEffect(() => {
        getBuckets();
    }, []);

    const getBuckets = () => {
        if (org.server === undefined) return;
        get(`cloud/${org.server.id}/buckets/${org.id}`)
            .then((b) => setBuckets(b))
            .catch((e) => console.log(e));
    };

    const track = (b: CloudTrackedBucket) => {
        post(
            `cloud/${b.serverID}/organization/${b.organization_id}/bucket/${b.id}/track`,
            {},
        )
            .then((e) => {
                if (buckets === undefined) return;
                let tmp = structuredClone(buckets);
                const index = tmp.findIndex((t) => t.id === b.id);
                if (index < 0) return;
                tmp[index].tracked = true;
                setBuckets(tmp);
            })
            .catch((err) => console.log(err));
    };

    const untrack = (b: CloudTrackedBucket) => {
        del(
            `cloud/${b.serverID}/organization/${b.organization_id}/bucket/${b.id}/untrack`,
        )
            .then((_) => {
                if (buckets === undefined) return;
                let tmp = structuredClone(buckets);
                const index = tmp.findIndex((t) => t.id === b.id);
                if (index < 0) return;
                tmp[index].tracked = false;
                setBuckets(tmp);
            })
            .catch((err) => console.log(err));
    };

    const onRowClick = (b: CloudTrackedBucket) => {
        const index = buckets?.findIndex((b2) => b.id === b2.id) ?? -1;
        if (index < 0) return;
        navigate(`${b.id}`);
    };

    const tableBuckets: CloudTrackedBucketStatus[] =
        buckets?.map((b) => ({
            name: b.info.name,
            last_data_time: b.info.last_update,
            id: b.id,
            organization_id: org.id,
            organization_name: org.name,
            isTracked: b.tracked,
            serverID: org.server?.id ?? 0,
        })) ?? [];

    return (
        <>
            {buckets === undefined ? (
                <h1>Loading buckets...</h1>
            ) : (
                <>
                    <div className="mb-2">
                        <span>Click on a bucket to edit the setup</span>
                        <h3 className="text-lg font-bold text-sky-900 mb-2">
                            Tracked
                        </h3>
                        <BucketsTable
                            buckets={tableBuckets.filter((b) => b.isTracked)}
                            action={untrack}
                            isTracked
                            buttonText="Untrack"
                            onRowClick={onRowClick}
                        />
                    </div>
                    <div>
                        <h3 className="text-lg font-bold text-sky-900 mb-2">
                            Untracked
                        </h3>
                        <BucketsTable
                            buckets={tableBuckets.filter((b) => !b.isTracked)}
                            action={track}
                            buttonText="Track"
                            onRowClick={onRowClick}
                        />
                    </div>
                </>
            )}
        </>
    );
};

export default OrganizationBucketSetup;
