import { ReactNode, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { get, post } from "../helpers/Requests"
import { CloudOrgWithReporting, ReportingItem } from "../types/reporting_types"
import { ReportingItemStats } from "../components/Reporting/ReportingItem";
import { getReportingStats } from "../helpers/reporting_helpers";
import ReportingDashboards from "./ReportingDashboards";
import ReportingDashboardTables from "./ReportingDashboardTables";
import ReportingReports from "./ReportingReports";
import ReportingTrendViews from "./ReportingTrendViews";
import { CloudUser, Consultant, MailBundleWithRelations } from "../types/cloud_types";
import {Plan} from "../types/reporting_types";
import TabBar from "../components/TabBar"
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { ServerData} from "../types/types"
import ReportingMailBundles from "./ReportingMailBundles";
import Button from "../components/Button";

type ReportingOrgProps = {
    serverId?: number,
    orgId?:number
}
const ReportingOrganization = (props:ReportingOrgProps) => {
    
    let { serverId, orgId } = useParams()

    if(!serverId && props.serverId) {
        serverId = props.serverId.toString();
    }

    if(!orgId && props.orgId) {
        orgId = props.orgId.toString()
    }
    const { setUp } = useSideBarMenuContext()

    const [server, setServer] = useState<ServerData>()
    const [tab, setTab] = useState(0)
    const [cloudOrganization, setCloudOrganization] = useState<CloudOrgWithReporting>();
    const [cloudUsers, setCloudUsers] = useState<CloudUser[]>([])
    const [consultants, setConsultants] = useState<Consultant[]>([])

    const [showAddModal, setShowAddModal] = useState<boolean>(false);

    const [mailBundleIDsSending, setMailBundleIDsSending] = useState<number[]>([]);

    useEffect(() => {
        get(`servers/${serverId}`)
        .then(setServer)
        .catch(err => {
            console.log(err)
        })
    }, [serverId])
    
    const getCloudOrgWithReporting = useCallback(async (): Promise<CloudOrgWithReporting> => {
        if(server?.cloud && serverId){
            try {
                // getting orgs from cloud, and dashboards and reports from reporting
                const [orgs, dashboards, dashboardtables, trendviews, reports, plans, mailbundles] = 
                    await Promise.all([get(`cloud/${server?.cloud}/organizations`), get(`reporting/${serverId}/dashboard`), get(`reporting/${serverId}/dashtable`), get(`reporting/${serverId}/trendview`), get(`reporting/${serverId}/report`), get(`cloud/${server?.cloud}/plans`), get(`cloud/${server?.cloud}/mailbundle/${orgId}`)])
                // Finding the org with the orgId from the url
                const org = orgs.find((o: any) => o.id === parseInt(orgId ?? "-1"))
                // Getting only dashboards and reports that belong to the org
                const org_dashboards = dashboards.filter((d: any) => org.id === d.org_id)
                const org_reports = reports.filter((r: any) => org.id === r.org_id)
                const org_dashtables = dashboardtables.filter((r: any) => org.id === r.org_id)
                const org_trendviews = trendviews.filter((r: any) => org.id === r.org_id)
                const sorted_plans =  [...plans].sort((a, b) => a.description > b.description ? 1 : -1)
                const org_mailbundles = mailbundles
                return { id: org.id, name: org.name, deleted: org.deleted, dashboards: org_dashboards, dashboardtables: org_dashtables, trendviews: org_trendviews, reports: org_reports, plans: sorted_plans, mailbundles: org_mailbundles, use_access_control: org.use_access_control, location_enabled: org.location_field_enabled, self_subscription_enabled: org.self_subscription_enabled }
            } catch (e) {
                console.log(e)
                return { id: -1, name: "", deleted: false, dashboards: [], dashboardtables: [], trendviews: [], reports: [], plans: [], mailbundles: [], use_access_control: false, location_enabled: false, self_subscription_enabled: false}
            }
        }
        else {
            return { id: -1, name: "", deleted: false, dashboards: [], dashboardtables: [], trendviews: [], reports: [], plans: [], mailbundles: [], use_access_control: false, location_enabled: false, self_subscription_enabled: false }
        }
    }, [server, orgId, serverId])

    useEffect(() => {
        setUp("/reporting")
        async function fetch() {
            const org = await getCloudOrgWithReporting()
            const users = await get(`cloud/${server?.cloud}/organizations/${orgId}/users`) // users are used when creating/editting a reporting dashboard/report with easy dropdown
            const cons = await get(`cloud/${server?.cloud}/consultant`)
            setConsultants(cons)
            setCloudOrganization(org)
            setCloudUsers(users)
        }
        if(server?.cloud) {
            fetch()
        }
    }, [orgId, setUp, server, getCloudOrgWithReporting])

    const sendMail = (id: number, email: string, test: boolean) => {
        let body = {id: id, email: email}

        let url = test ? `reporting/${serverId}/emailTest` : `reporting/${serverId}/emailReal`

        if(window.confirm("Are you sure you want to send a " + ( test ? "test" : "REAL" ) + " e-mail")){
            post(url, body).then(res => {
                alert('Email queued')
                update()
                
            }).catch(err => {console.log(err); alert('Failed to send')})
        }
    }

    const sendMailBundle = (mb: MailBundleWithRelations, test: boolean) => {
        if (!mb.plan_id) return;
        let recipients : (CloudUser | Consultant | undefined)[] = mb.user_ids.map(id => cloudUsers.find(u => u.id === id)).filter(u => !!u)
        if(test){
            recipients = [...recipients, ...mb.user_ids.map(id => consultants.find(u => u.id === id)).filter(u => !!u)]
        }
        
        const body = {
            plan_id: mb.plan_id, 
            emails: recipients.map(r => r?.email), 
            mail_bundle_id: mb.id
        }
        
        const url = test ? `reporting/${serverId}/emailBundleTest` : `reporting/${serverId}/emailBundleReal` 
        
        if(!test){
            if(window.confirm("Are you sure you want to send a REAL e-mail to all recipients of " + mb.name)){
                setMailBundleIDsSending([...mailBundleIDsSending, mb.id])
                post(url, body).then(res => {
                    update()
                }).catch(err => {console.log(err); alert('Failed to send')})
                setTimeout(() => {
                    setMailBundleIDsSending(mailBundleIDsSending.filter(id => id !== mb.id))
                }, 5000)
            }
        } else {
            post(url, body).then(res => {
                setMailBundleIDsSending(mailBundleIDsSending.filter(id => id !== mb.id))
                update()
            }).catch(err => {console.log(err); alert('Failed to send')})
        }
        
    }

    const sendMultipleBundles = (mbs: MailBundleWithRelations[]) => {
        const body = mbs.map(mb => {
            const recipients = mb.user_ids.map(id => cloudUsers.find(u => u.id === id)).filter(u => !!u)
            return {
                plan_id: mb.plan_id, 
                emails: recipients.map(r => r?.email),
                mail_bundle_id: mb.id
            }
        })
        setMailBundleIDsSending([...mailBundleIDsSending, ...mbs.map(mb => mb.id)])
        post(`reporting/${serverId}/emailBundleMultiple`, body).then(res => {
            update()
        }).catch(err => {console.log(err); alert('Failed to send')})
        setTimeout(() => {
            setMailBundleIDsSending(mailBundleIDsSending.filter(id => mbs.map(mb => mb.id).includes(id)))
        }, 5000)
        
    }

    const update = async () => {
        // Updates local state after every CRUD operation to DB
        try {
            const org = await getCloudOrgWithReporting()
            setCloudOrganization(org)
        } catch(e) {
            console.log(e)
        }
    }

    return <div className="bg-gray-100 h-full">
        <div className="bg-white p-8 border-b border-gray-200">
            <div className="flex w-full items-center justify-between">
                <h1 className="font-bold text-3xl mb-4">Settings</h1>
                <Button color={"primary"} text="Add" onClick={() => setShowAddModal(true) }/>
            </div>
            <TabBar 
                selected={tab} 
                tabs={["Mail Bundles", "Dashboards", "Dashboard tables", "Trend views", "Reports"]} 
                setTab={setTab} 
                labels={[
                    (cloudOrganization?.mailbundles.length ?? 0), 
                    (cloudOrganization?.dashboards?.length ?? 0), 
                    (cloudOrganization?.dashboardtables?.length ?? 0), 
                    (cloudOrganization?.trendviews?.length ?? 0), 
                    (cloudOrganization?.reports?.length ?? 0)
                ]} 
            />
            <div>
                {tab === 0 && <ReportingMailBundles 
                    server={server} 
                    update={update} 
                    sendmail={sendMailBundle} 
                    reporting_mail_bundles={cloudOrganization?.mailbundles ?? []} 
                    plans={cloudOrganization?.plans ?? []} 
                    users={cloudUsers} 
                    consultants={consultants} 
                    org={cloudOrganization} 
                    mailBundleIDsSending={mailBundleIDsSending}
                    setMailBundleIDsSending={setMailBundleIDsSending}
                    sendMultipleMails={sendMultipleBundles}
                    showAddModal={showAddModal}
                    setShowAddModal={setShowAddModal}
                />}
                {tab === 1 && <ReportingDashboards add={showAddModal} setAdd={setShowAddModal} server={server} update={update} sendmail={sendMail} reporting_dashboards={cloudOrganization?.dashboards ?? []} plans={cloudOrganization?.plans ?? []} users={cloudUsers} />}
                {tab === 2 && <ReportingDashboardTables add={showAddModal} setAdd={setShowAddModal} server={server} update={update} sendmail={sendMail} reporting_dashboards={cloudOrganization?.dashboardtables ?? []} plans={cloudOrganization?.plans ?? []} users={cloudUsers} />}
                {tab === 3 && <ReportingTrendViews add={showAddModal} setAdd={setShowAddModal} server={server} update={update} sendmail={sendMail} reporting_reports={cloudOrganization?.trendviews ?? []} plans={cloudOrganization?.plans ?? []} users={cloudUsers} />}
                {tab === 4 && <ReportingReports add={showAddModal} setAdd={setShowAddModal} server={server} update={update} sendmail={sendMail} reporting_reports={cloudOrganization?.reports ?? []}  plans={cloudOrganization?.plans ?? []} users={cloudUsers} />}
            </div>

        </div>
    </div>
}

export const ReportingOrgItem: React.FC<{ items: ReportingItem[], plans: Plan[], textColor: string, bgColor: string, icon: ReactNode }> = ({ items, plans, textColor, bgColor, icon }) => {
    return <div className="flex flex-row items-center justify-between gap-16">
        <div className="flex flex-row gap-6">
            <ReportingItemStats color={textColor} count={items.length} stats={getReportingStats(items, plans)} />
        </div>
    </div>
}

export default ReportingOrganization