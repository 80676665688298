import { accountReducer } from "./accountReducers";
import { orgsReducer } from "./orgReducers";
import { latestRunReducer } from "./latestRunReducer";
import { serverReducer } from "./serverReducer";
import { Store } from "../contexts/StoreContext";
import { AccountAction, LatestRunAction, OrgAction, DispatchAction, ServerAction } from "./actions";


const mainReducer = (state: Store, action: DispatchAction): Store => {
    return {
        orgs: orgsReducer(state, action as OrgAction),
        accounts: accountReducer(state, action as AccountAction),
        latest_runs: latestRunReducer(state, action as LatestRunAction),
        servers: serverReducer(state, action as ServerAction)
    };
};

export default mainReducer;

