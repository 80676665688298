import { PropsWithChildren } from "react"

type TableHeadProps = {
    className?: string,
}

const TableHead = ({ className, children }: PropsWithChildren<TableHeadProps>) => {
    return <thead className={`${className}`}>
        {children}
    </thead>
}

export default TableHead