import { useEffect, useState, useCallback } from "react"
import { get } from "../../helpers/Requests"
import { CloudTrackedBucket } from "../../types/cloud_types"

const BucketsCard: React.FC<{ serverId: string, onClick(): any }> = ({ serverId, onClick }) => {
    const [tracked, setTracked] = useState<CloudTrackedBucket[]>([])
    const [untracked, setUntracked] = useState<CloudTrackedBucket[]>([])
   
    const getBuckets = useCallback(() => {
        Promise.all([
            get("cloud/" + serverId + "/tracked"),
            get("cloud/" + serverId + "/untracked")
        ]).then(([tracked, untracked]) => {
            setTracked(tracked)
            setUntracked(untracked)
        }).catch(err => console.log(err))

    }, [serverId])

    useEffect(() => {
        getBuckets()
    }, [getBuckets])
  
    return <div className="bg-neutral-100 h-full">
    <div className="p-8 bg-white border-b border-gray-200 cursor-pointer" onClick={onClick}>
        Tracked: {tracked.length} &nbsp; Untracked: {untracked.length}
    </div>
</div >
}

export default BucketsCard