
import { get, put } from "../helpers/Requests";
import { APAAccount, ServerData } from "../types/types";

export const getAllAccounts = async function (servers: ServerData[],) {
    let error: any;
    let response: APAAccount[] = []
    const s = servers.filter(s => s.type === "apa")

    const getAccounts = async (serverId: number): Promise<APAAccount[]> => {
        try {
            const [result] = await Promise.all([get('apa/' + serverId + '/accounts')])
            return result;
        } catch (e) {
            error = e;
            return []
        }
    }

    const allAccounts = async (servers: ServerData[]): Promise<APAAccount[]> => {
        let result: APAAccount[] = []
        for (let j = 0; j < servers.length; j++) {

            if(servers[j].type === "apa"){
                const [accounts] = await Promise.all([getAccounts(servers[j].id)])
                for (let i = 0; i < accounts.length; i++) {
                    accounts[i].serverId = servers[j].id
                }
                result = result.concat(accounts)
            }
        }
        return result;
    }

    response = await allAccounts(s)
    return error ? Promise.reject(error) : Promise.resolve(response)
}


export const updateAccount = async function (serverID: Number, account: APAAccount) {
    let error: any;
    let response: APAAccount | undefined;
    await put('apa/' + serverID + '/accounts/' + account.id, account)
        .then((result: APAAccount) => {
            response = result;
        })
        .catch(err => {
            error = err;
        });
    return error ? Promise.reject(error) : Promise.resolve(response)

}
