import React, { useState } from "react"
import { LoginFooter } from "./Login"

const Forgot: React.FC<{ setForgot(b: boolean): any }> = ({ setForgot }) => {
    const [email, setEmail] = useState("")
    const [hasReset, setHasReset] = useState(false)

    const handleReset = () => {
        return (e: React.MouseEvent) => {
            // TODO: call reset password in backend
            setHasReset(true)
        }
    }

    return <div className="flex-1 flex flex-col">
        <div>
            <div onClick={_ => setForgot(false)} className="cursor-pointer -ml-4">
                <img className="bg-white rounded-md scale-75" src="./logo.png" alt="" />
            </div>
        </div>
        <div className="bg-red-600 py-8 h-[370px]">
            <div className="flex flex-row items-center justify-center">
                <div className="bg-white rounded-xl flex flex-col p-8 w-[400px] gap-4">
                    <div className="text-2xl font-semibold">Forgot password?</div>
                    {!hasReset ? <>
                        <input onChange={e => setEmail(e.target.value)} className="border border-gray-300 rounded w-full py-2 px-3 text-gray-900" id="email" type="email" placeholder="E-mail" />
                        <button
                            disabled={email === ""}
                            onClick={handleReset()}
                            className="text-white font-bold py-2 px-4 rounded bg-gray-500 hover:bg-gray-700 cursor-pointer disabled:bg-gray-300 w-full"
                            type="button">
                            Reset
                        </button>
                    </> : <div className="text-sm text-gray-700">
                        An email has been sent to {email} with further instructions.
                    </div>}
                    <div className="border-t border-gray-300 pt-4 text-center">
                        <button
                            onClick={() => setForgot(false)}
                            className="font-bold text-gray-400 hover:text-gray-700"
                            type="button">
                            Back
                        </button>
                    </div>

                </div>
            </div>
        </div>
        <div className="pt-20">
            <LoginFooter />
        </div>
    </div>

}

export default Forgot