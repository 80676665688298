import { useEffect, useRef } from "react";

export const useOutsideClick = (callback: () => any) => {
    const ref = useRef<HTMLDivElement>(null);
  
    useEffect(() => {
        const handleClick = (event:any) => {
            if (ref.current && !ref.current.contains(event.target)) {
              callback();
            }
          };
  
      document.addEventListener('mousedown', handleClick, true);
  
      return () => {
        document.removeEventListener('mousedown', handleClick,true);
      };
    }, [ref]);
  
    return ref;
  };