import React, { useState, useEffect } from "react"
import Button from "../Button";
import "../../css/EditUserModal.css"
import { BsCaretRightFill } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";

export default function EditAccessControl(props: any) {

    const [deselectedBuckets, setDeselectedBuckets] = useState(!!props.items ? props.items?.buckets?.filter((b: any) => !props.bucket_access?.find((ub: any) => ub === b.id)) : []);
    const [selectedBuckets, setSelectedBuckets] = useState(!!props.items ? props.items?.buckets?.filter((b: any) => !!props.bucket_access?.find((ub: any) => ub === b.id)) : []);

    useEffect(() => {
        props.bucketChangedHandler({ selected: [...selectedBuckets], deselected: [...deselectedBuckets] })
    }, [deselectedBuckets, selectedBuckets])

    function selectBucketHandler(bucket: any) {
        const index = deselectedBuckets.findIndex((b: any) => b.id === bucket.id)
        let deselected = [...deselectedBuckets];
        deselected.splice(index, 1)
        const selected = [...selectedBuckets]
        selected.push(bucket)
        setDeselectedBuckets(deselected)
        setSelectedBuckets(selected)
    }
    function selectAllBucketsHandler() {
        setDeselectedBuckets([])
        setSelectedBuckets([...props.items.buckets])
    }

    function deselectAllBucketsHandler() {
        setDeselectedBuckets([...props.items.buckets])
        setSelectedBuckets([])
    }

    function deselectBucketHandler(bucket: any) {
        const index = selectedBuckets.findIndex((b: any) => b.id === bucket.id)
        let selected = [...selectedBuckets];
        selected.splice(index, 1)
        const deselected = [...deselectedBuckets]
        deselected.push(bucket)
        setDeselectedBuckets(deselected)
        setSelectedBuckets(selected)
    }

    return (
        <div className="max-w-xl mx-auto overflow-auto h-full relative">

            <section className="org-admin-access-control-wrapper flex overflow-auto min-h-full h-full   " >
                <section className="org-admin-access-control-column w-full p-2 flex flex-col min-h-min h-full ">

                    <h3 className="h6">User has <strong>not</strong> access to:</h3>
                    <Button className=" self-center mb-2" color="default" text="Select all" disabled={deselectedBuckets.length === 0} onClick={() => selectAllBucketsHandler()} />
                    <section className="org-admin-access-control-list">

                        <ul className="m-0 p-0 h-full min-h-[10px]">
                            {deselectedBuckets.map((bucket: any) => <li key={bucket.id} onClick={() => selectBucketHandler(bucket)} className="org-admin-access-control-list-item flex items-center justify-between">
                                {bucket.name} <span className="flex items-center"><span>|</span> <BsCaretRightFill className="mt-0.5" size="12" /></span>
                            </li>
                            )}
                        </ul>
                    </section>
                </section>
                <section className="org-admin-access-control-column w-full p-2 position-relative flex flex-col">
                    <h3 className="h6">User <strong>has</strong> access to:</h3>
                    <Button color="default" className=" btn-sm self-center mb-2" disabled={selectedBuckets.length === 0} onClick={() => deselectAllBucketsHandler()} text="Deselect all" />
                    <section className="card org-admin-access-control-list">
                        <ul className="m-0 p-0">
                            {selectedBuckets.map((bucket: any) => <li key={bucket.id} onClick={() => deselectBucketHandler(bucket)} className="org-admin-access-control-list-item flex items-center justify-between" >
                                {bucket.name} <span className="flex items-center"> <FaTimes size="12" /></span>
                            </li>
                            )}
                        </ul>
                    </section>
                </section>
            </section>
            {selectedBuckets.length === 0 ? <p style={{ color: "#d9534f", paddingLeft: 10, fontWeight: "bold" }} >User will not have access to anything</p> : null}
        </div>
    )
}