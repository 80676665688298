import { Dispatch, SetStateAction } from "react";
import { ReportingStats } from "../../types/reporting_types";

interface ReportingItemProps extends React.ComponentPropsWithoutRef<"div"> {
    name: string,
    count: number,
    icon: React.ReactNode,
    color: string,
    stats: ReportingStats,
    clickable?: boolean,
    bgColor: string
}

const ReportingItem: React.FC<ReportingItemProps> = ({ name, count = 0, icon, color, stats, clickable, bgColor, ...props }) => {
    return <div {...props} className={`flex-1 group p-4 bg-white border-gray-500 shadow-md rounded-md ${clickable ? 'transition duration-200 transform hover:scale-[1.02] hover:shadow-lg cursor-pointer' : ''}`}>
        <div className="flex flex-row items-center justify-between gap-4">
            <div className="flex flex-row items-center gap-6 px-2">
                <div className={`p-4 rounded-lg ${bgColor} bg-opacity-50`}>{icon}</div>
                <div className="flex flex-col">
                    <div className="text-gray-500 text-sm">{name === "Mailbundles"? "All Mailbundles" : "All "+ name + " emails"}</div>
                    <ReportingItemStats color={color} count={count} stats={stats} />
                </div>
            </div>
            <div>
                {clickable && <div className="px-2 text-gray-500 text-sm opacity-0 group-hover:opacity-100">Configure</div>}
            </div>
        </div>
    </div>
}

export const ReportingItemStats: React.FC<{ 
    color: string, 
    count: number, 
    stats: ReportingStats,
    selectedPlan?: "D" | "W" | "M" | "A"
    setSelectedPlan?: Dispatch<SetStateAction<"D" | "W" | "M" | "A">>
}> = ({ color, count, stats, selectedPlan, setSelectedPlan }) => {
    return <>
        <div className="flex flex-row gap-4 items-center">
            {!selectedPlan && <div className={`text-3xl font-semibold ${color}`}>{count}</div>}
            <div className="flex flex-row gap-4 border-x border-gray-300 px-4">
                <div 
                    title={`${selectedPlan ? selectedPlan === "D" ? "Show All" : "Show Daily" : ""}`}
                    className={`${selectedPlan ? selectedPlan === "D" ? "bg-blue-600 text-white cursor-pointer" : "text-blue-600 cursor-pointer" : "text-blue-600"} text-xs font-semibold border border-blue-200 rounded-full py-1 px-2 transition-colors`}
                    onClick={() => setSelectedPlan ? selectedPlan === "D" ? setSelectedPlan("A") : setSelectedPlan("D") : null}
                >
                    Daily: <span className={selectedPlan === "D" ? "" : "text-blue-900"}>{stats.daily}</span>
                </div>
                <div 
                    title={`${selectedPlan ? selectedPlan === "W" ? "Show All" : "Show Weekly" : ""}`}
                    className={`${selectedPlan ? selectedPlan === "W" ? "bg-green-600 text-white cursor-pointer" : "text-green-600 cursor-pointer" : "text-green-600"} text-xs font-semibold border border-green-300 rounded-full py-1 px-2 transition-colors`}
                    onClick={() => setSelectedPlan ? selectedPlan === "W" ? setSelectedPlan("A") : setSelectedPlan("W") : null }    
                >
                    Weekly: <span className={selectedPlan === "W" ? "" : "text-green-900"}>{stats.weekly}</span>
                </div>
                <div 
                    title={`${selectedPlan ? selectedPlan === "M" ? "Show All" : "Show Monthly" : ""}`}
                    className={`${selectedPlan ? selectedPlan === "M" ? "bg-red-600 text-white cursor-pointer" : "text-red-600 cursor-pointer" : "text-red-600"} text-xs font-semibold border border-red-200 rounded-full py-1 px-2 transition-colors`}
                    onClick={() => setSelectedPlan ? selectedPlan === "M" ? setSelectedPlan("A") : setSelectedPlan("M") : null}
                >
                    Monthly: <span className={selectedPlan === "M" ? "" : "text-red-900"}>{stats.monthly}</span>
                </div>
            </div>
        </div>
    </>
}

export default ReportingItem;
