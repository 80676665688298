import React, { useEffect, useState } from 'react'
import { HiOutlinePlus } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../components/Button'
import { Modal, WarningModal } from '../components/Modals'
import ReportTemplateGroupList from '../components/Template/ReportTemplateGroup'
import { del, get, post, put } from '../helpers/Requests'
import { BucketTemplate, defaultNewReportTemplate, NewReportTemplate, ReportTemplateGroup } from '../types/template_types'
import CustomInput from '../components/CustomInput'
import BucketTemplateCard from '../components/Template/BucketTemplateCard'
import useConfirmDialog from '../hooks/useConfirmDialog'

const TemplateReportOverview = () => {

    const [groups, setGroups] = useState<ReportTemplateGroup[]>([])
    const [buckets, setBuckets] = useState<BucketTemplate[]>([])
    const { cloudId } = useParams()
    const navigate = useNavigate();

    const [showCreateGroup, setShowCreateGroup] = useState(false)
    const [tmpName, setTmpName] = useState("")

    const [showCreateTemplate, setShowCreateTemplate] = useState(false)
    const [tmpReportTemplate, setTmpReportTemplate] = useState(defaultNewReportTemplate)

    const [groupToDelete, setGroupToDelete] = useState<undefined | number>()
    const [groupToEdit, setGroupToEdit] = useState<undefined | number>()


    const {confirm, ConfirmDialog } = useConfirmDialog()

    useEffect(() => {
        getGroups()
        getBuckets()
    }, [cloudId])

    const getGroups = () => {
        get(`cloud/${cloudId}/templates/reportGroup`)
        .then((result: ReportTemplateGroup[]) => {
            setGroups(result)
        }).catch(err => console.log(err))
    }

    const getBuckets = () => {
        get("cloud/" + cloudId + '/templates/buckets')
        .then(result => {
            let tmpBuckets: BucketTemplate[] = result
            setBuckets(tmpBuckets)
        }).catch(err => console.log(err))
    }

    const onCreateGroup = () => {
        if (tmpName === "") return
        post(`cloud/${cloudId}/templates/reportGroup`, {name: tmpName}).then(() => {
            getGroups()
            setShowCreateGroup(false)
            setTmpName("")
        }).catch(e => console.log(e))
    }

    const onDeleteGroup = (index: number) => {
        setGroupToDelete(index)
    }
    
    const confirmDeleteGroup = () => {
        if (groupToDelete === undefined || groupToDelete === 0) {
            setGroupToDelete(undefined)
            return
        }

        del(`cloud/${cloudId}/templates/reportGroup/${groupToDelete}`).then(() => {
            setGroupToDelete(undefined)
            getGroups()
        })
    }

    const onNewTemplate = (group: number) => {
        let tmp: NewReportTemplate = {
            name: "",
            description: "",
            group_id: group,
            bucket_id: 0
        }
        setTmpReportTemplate(tmp)
        setShowCreateTemplate(true)
    }

    const onEditGroupName = (group: number) => {
        setGroupToEdit(group)
        setTmpName(groups.find(g => g.id === group)?.name ?? "")
    }

    const onSaveGroupName = () => {
        if (groupToEdit === undefined || tmpName === "") return
        const newGroup = {id: groupToEdit, name: tmpName}
        put(`cloud/${cloudId}/templates/reportGroup`, newGroup).then(() => {
            setGroupToEdit(undefined)
            setTmpName("")
            getGroups()
        })
    }

    const createTemplate = () => {
        if (disableCreateTemplate()) return
        post(`cloud/${cloudId}/templates/report`, tmpReportTemplate).then((result: number) => {
            navigate(`${result}`)
        }).catch(e => console.log(e))
    }

    const disableCreateTemplate = () => {
        return tmpReportTemplate.name === "" || tmpReportTemplate.group_id === 0 || tmpReportTemplate.bucket_id === 0
    }

    const setTemplateBucket = (id: number) => {
        let tmp = structuredClone(tmpReportTemplate)
        tmp.bucket_id = id
        setTmpReportTemplate(tmp)
    }

    const setTemplateName = (name: string) => {
        let tmp = structuredClone(tmpReportTemplate)
        tmp.name = name
        setTmpReportTemplate(tmp)
    }

    const setTemplateDescription = (desc: string) => {
        let tmp = structuredClone(tmpReportTemplate)
        tmp.description = desc
        setTmpReportTemplate(tmp)
    }

    const deleteTemplate = async (templateId: number) => {
        if (!await confirm("Are you sure you want to delete this template? It can not be undone.", {confirmText: "Delete", declineText: "Cancel"})) return
        
        del(`cloud/${cloudId}/templates/report/${templateId}`).then(() => {
            getGroups()
        }).catch(e => console.error(e))
    }

    const cloneTemplate = (templateId: number) => {
        post(`cloud/${cloudId}/templates/report/clone`, {id: templateId})
            .then(() => {
                getGroups()
            })
            .catch(e => console.error(e))
    }

    return <div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 className="text-gray-500 text-lg font-semibold">Insight Templates</h1></div>
        </div>
        <div className="mx-4 flex flex-col gap-5">
            <div className='flex'>
                <Button color='primary' icon={<HiOutlinePlus />} text='Add group' onClick={() => setShowCreateGroup(true)} />
            </div>

            {
                groups.map((g, i) => <ReportTemplateGroupList key={i} group={g} onEditName={onEditGroupName} onNewTemplate={onNewTemplate} onDeleteGroup={onDeleteGroup} onDeleteTemplate={deleteTemplate} onCloneTemplate={cloneTemplate} />)
            }
        </div>
        <Modal 
            isOpen={showCreateGroup} 
            onClose={() => {setShowCreateGroup(false); setTmpName("")}} 
            onAction={onCreateGroup} 
            title={'Add group'} 
            disableAction={tmpName === ""}
            actionText={"Create"} 
            closeText={'Cancel'}>
                <div className='mt-3'>
                    <label htmlFor='name-input'>Name</label>
                    <CustomInput id='name-input' value={tmpName} onChange={e => setTmpName(e.target.value)} placeholder='Insert name...' />
                </div>
        </Modal>

        <Modal 
            isOpen={showCreateTemplate} 
            onClose={() => {setShowCreateTemplate(false); setTmpReportTemplate(defaultNewReportTemplate)}} 
            onAction={createTemplate} 
            title={"Create Template"} 
            actionText={"Create"} 
            closeText={'Cancel'}
            disableAction={disableCreateTemplate()}>
                <div className='mt-3 flex flex-col gap-2'>
                    <CustomInput label='Name' id='name-input' value={tmpReportTemplate.name} onChange={e => setTemplateName(e.target.value)} placeholder='Insert name...' />
                    <label htmlFor='new-desc' className='text-gray-600 text-xs mt-3'>Description</label>
                    <textarea id='new-desc' value={tmpReportTemplate.description} onChange={e => setTemplateDescription(e.target.value)} />
                    <span className='text-gray-600 text-xs mt-3'>Bucket template</span>
                    <div className='mx-4 flex gap-4'>
                        {
                            buckets.map((v,i) => {
                                return <BucketTemplateCard key={i} onClick={() => setTemplateBucket(v.id)} name={v.name} className={`${tmpReportTemplate.bucket_id === v.id ? 'bg-blue-100 hover:bg-blue-200' : 'hover:bg-neutral-50'} cursor-pointer`} />
                            })
                        }
                    </div>
                </div>
        </Modal>

        <WarningModal 
            isOpen={groupToDelete !== undefined}
            closeText='Cancel'
            actionText='Delete'
            title='Delete group'
            onClose={() => setGroupToDelete(undefined)}
            onAction={confirmDeleteGroup}
        >
        Are you sure you want to delete this group? All templates contained in the group will be deleted. <span className='text-red-600 font-bold'>This is permanent and cannot be undone.</span>
        </WarningModal>

        <Modal isOpen={groupToEdit !== undefined} 
            onClose={() => {setGroupToEdit(undefined); setTmpName("")}} 
            onAction={onSaveGroupName} 
            title='Edit name' 
            actionText='Save' 
            closeText='Cancel'
            size='small'
            disableAction={tmpName === ""}>
            <div className='mt-3 flex flex-col gap-2'>
                <CustomInput label='Name' id='name-input' value={tmpName} onChange={e => setTmpName(e.target.value)} placeholder='Insert name...' />
            </div>
        </Modal>

        <ConfirmDialog />
    </div>
}

export default TemplateReportOverview
