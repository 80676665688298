import { RadioGroup } from '@headlessui/react'
import React from 'react'

import '../../css/CustomRadioGroup.css'

interface CustomRadioGroupProps {
    children: React.ReactNode
    className?: any
    value: any
    onChange: any
    label?: string
    labelClass?:any
}

export default function CustomRadioGroup({children, className, labelClass, value, onChange, label}: CustomRadioGroupProps){

    return (

        <label className={`${labelClass ? labelClass : ''}`}>
    {label}

    <RadioGroup className={`radio-group ${className ? className : ''}`} value={value} defaultValue={value} onChange={onChange}>
        {children}
    </RadioGroup>
   </label>

    )
}
