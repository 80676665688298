import { Menu } from '@headlessui/react'
import React from 'react'

interface MenuDropdownItemProps extends React.ComponentPropsWithoutRef<React.ElementType> {
    onClick(): any;
    text: string;
    icon?: React.ReactNode;
}

const MenuDropdownItem: React.FC<MenuDropdownItemProps> = ({ onClick, text, icon }) => {
    return <Menu.Item>
        {({ active }) => (
            <button
                className={`${active ? 'bg-violet-500' : 'text-gray-800'
                    } group flex rounded-md items-center w-full px-2 py-2 text-sm hover:bg-blue-100 hover:text-gray-900`}
                onClick={onClick}
            >
                {icon ? icon : null}
                {text}
            </button>
        )}
    </Menu.Item>
}

export default MenuDropdownItem