import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom";
import AddEmail from "../components/Reporting/AddEmail";
import ReportingTable from "../components/Reporting/ReportingTable";
import { del, get, post, put } from "../helpers/Requests";
import { CloudDashboard, CloudDashboardMap, CloudUser } from "../types/cloud_types";
import { Dashboard, Plan} from "../types/reporting_types";
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { ServerData} from "../types/types"
import ReportingFilterBar from "../components/Reporting/ReportingFilterBar";
import { getPlanInterval, getReportingStats } from "../helpers/reporting_helpers";

const ReportingDashboards: React.FC<{
    server: ServerData | undefined,
    reporting_dashboards: Dashboard[],
    plans: Plan[],
    users: CloudUser[],
    update(): any,
    sendmail(id: number, email: string, test: boolean): void,
    add: boolean,
    setAdd: Dispatch<SetStateAction<boolean>>
}> = ({server, reporting_dashboards, plans, users, update, sendmail, add, setAdd }) => {
    const { orgId } = useParams()
    const { setUp } = useSideBarMenuContext()
    const serverId = server?.id.toString()

    const [editDashboardEmail, setEditDashboardEmail] = useState<Dashboard | null>(null);
    const [cloudDashboardsObj, setCloudDashboardsObj] = useState<CloudDashboardMap>()
    const [cloudDashboards, setCloudDashboards] = useState<CloudDashboard[]>([])

    const [shownDashboards, setShownDashboards] = useState<Dashboard[]>([])

    const [searchText, setSearchText] = useState<string>("");
    const [filteredDashboards, setFilteredDashboards] = useState<Dashboard[]>([]);

    const [planFilter, setPlanFilter] = useState<"D" | "W" | "M" | "A">("A")
    const [userFilter, setUserFilter] = useState<number|undefined>()

    const shownCloudUser = useMemo(() => users.find(u => u.id === userFilter), [userFilter, users])

    useEffect(() => {
        setUp(`/reporting/${orgId}`)
        if(server?.cloud){
            get(`cloud/${server?.cloud}/dashboards/${orgId}`) // cloud dashboards are retrieved in order to display dashboard name in the table
                .then((res: CloudDashboard[]) => {
                    // making an object of all dashboards with id as key for faster lookup when rendering in table
                    const _tmp: CloudDashboardMap = res.reduce((acc: CloudDashboardMap, cur) => { acc[cur.id] = cur; return acc }, {})
                    setCloudDashboardsObj(_tmp)
                    setCloudDashboards(res)
                }).catch(err => console.log(err))
        }
    }, [orgId, setUp, server])

    useEffect(() => {
        setFilteredDashboards(reporting_dashboards)
        setShownDashboards(reporting_dashboards)
    }, [reporting_dashboards])

    useEffect(() => {
        search(searchText)
    }, [filteredDashboards, searchText])

    useEffect(() => {
        filterDashboards(reporting_dashboards)
    }, [planFilter, userFilter])

    const onClose = () => {
        setEditDashboardEmail(null)
        setAdd(false);
    }

    const onEdit = (dashboard: Dashboard) => {
        setEditDashboardEmail(dashboard);
        setAdd(true)
    }

    const onDelete = (id: number) => {
        del(`reporting/${serverId}/dashboard/${id}`).then(res => {
            update()
        }).catch(err => console.log(err))
    }

    const onDuplicate = (d: Dashboard) => {
        d.id = 0
        post(`reporting/${serverId}/dashboard`, d).then(res => {
            update()
        }).catch(err => console.log(err))
    }

    const onToggleActive =(d: Dashboard) => {
        d.active = !d.active
        put(`reporting/${serverId}/dashboard/${d.id}`, d).then(res => {
            update();
        }).catch(err => console.log(err))
    }

    const onSendMail = (d: Dashboard, test: boolean) => {
        sendmail(d.plan_id?? 0, d.email, test)
    }

    function filterDashboards(dashboards : Dashboard[]){
        if(dashboards.length === 0) return;
        let filtered = [...dashboards]
        switch(planFilter){
            case "D":
            case "W":
            case "M":
                filtered = filtered.filter(d => getPlanInterval(d.plan_id ?? -1, plans).startsWith(planFilter))
                setFilteredDashboards(filtered)
                break;
            case "A":
            default:
                // do nothing
        }
        if(userFilter && shownCloudUser){
            filtered = filtered.filter(d => d.email === shownCloudUser.email)
        }
        setFilteredDashboards(filtered)
        if(!searchText) setShownDashboards(filtered)
    }

    const search = (query:string) => {
        if (!query || query.length < 1) {
            setShownDashboards(filteredDashboards)
            return
        }

        const filtered = filteredDashboards.filter(searchFilterFunc)
        setShownDashboards(filtered)
    }

    const searchFilterFunc = (d: Dashboard) => {
        if(!cloudDashboardsObj) return
        const title = cloudDashboardsObj[d.dashboard_id]?.title ?? ""
        const titleMatch = title?.toLowerCase().includes(searchText.toLowerCase())
        let recipientMatch = (d.firstname + " " + d.lastname).toLowerCase().includes(searchText.toLowerCase())
            
        return titleMatch || recipientMatch
    }

    return <div>
        <ReportingFilterBar
            items={reporting_dashboards}
            stats={getReportingStats(reporting_dashboards, plans)}
            shownItems={shownDashboards}
            users={users}
            searchText={searchText}
            setSearchText={setSearchText}
            selectedPlan={planFilter}
            setSelectedPlan={setPlanFilter}
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            setShowAddModal={setAdd}
            buttonColor="dashboard"
        />
        <ReportingTable 
            items={shownDashboards} 
            plans={plans} 
            cloudObj={cloudDashboardsObj ?? {}} 
            color="dashboard" onEdit={onEdit} 
            onDelete={onDelete} 
            onDuplicate={onDuplicate} 
            onToggleActive={onToggleActive} 
            onSendMail={onSendMail}
        />
        <AddEmail 
            server={server} 
            orgId={parseInt(orgId ?? "-1")} 
            users={users} 
            cloudItems={cloudDashboards} 
            open={add} route="dashboard" 
            onClose={onClose} 
            getEmailItems={update} 
            edittedEmailItem={editDashboardEmail} 
            plans={plans} 
        />

    </div>
}



export default ReportingDashboards