import { Menu, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"
import { BsFileEarmark } from "react-icons/bs"
import { FaEllipsisH } from "react-icons/fa"
import { HiChevronDown, HiChevronRight, HiOutlinePencil, HiOutlinePlus, HiOutlineTrash } from "react-icons/hi"
import { Link } from "react-router-dom"
import { ActionTemplateGroup } from "../../types/template_types"

interface ActionTemplateGroupListProps {
    group: ActionTemplateGroup,
    className?: string,
    onNewTemplate: (id: number) => void,
    onDeleteGroup: (id: number) => void,
    onEditName: (id: number) => void
    onDeleteTemplate: (reportId: number) => void
    onCloneTemplate: (reportId: number) => void
}

const ActionTemplateGroupList = ({className = "", group, ...props}: ActionTemplateGroupListProps) => {
    const [show, setShow] = useState(group.action_templates.length > 0)

    const getChevron = () => {
        return show ? <HiChevronDown className="w-[18px] h-[18px]" /> : <HiChevronRight className="w-[18px] h-[18px]" /> 
    }

    const onClone = (reportId: number) => {
        props.onCloneTemplate(reportId)
    }

    const onDelete = (reportId: number) => {
        props.onDeleteTemplate(reportId)
    }

    return (
        <div className={`flex flex-col gap-2 ${className}`}>
            <div
                onClick={() => setShow((s) => !s)}
                className="cursor-pointer flex items-center justify-between w-[300px] border-b-2 border-b-black "
            >
                <h2 className="truncate">
                    {group.action_templates.length} | {group.name}
                </h2>
                <div className="flex gap-1">
                    <button
                        className=" w-[18px] h-[18px] flex justify-center items-center hover:bg-dark-forest-500 text-white  bg-dark-forest-600 text-sm rounded"
                        title="Edit name"
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onEditName(group.id);
                        }}
                    >
                        <HiOutlinePencil />
                    </button>
                    <button
                        className=" w-[18px] h-[18px] flex justify-center items-center hover:bg-green-500 text-white  bg-green-600 text-sm rounded"
                        title="New template"
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onNewTemplate(group.id);
                        }}
                    >
                        <HiOutlinePlus />
                    </button>
                    <button
                        className=" w-[18px] h-[18px] flex justify-center items-center hover:bg-red-500 text-white  bg-red-600 text-sm rounded"
                        title="Delete group"
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onDeleteGroup(group.id);
                        }}
                    >
                        <HiOutlineTrash />
                    </button>
                    {getChevron()}
                </div>
            </div>
            {show ? (
                <div className="flex flex-col gap-2">
                    {group.action_templates.map((r, i) => {
                        return (
                            <div key={i} className="flex rounded-md shadow-md p-3 bg-white hover:bg-neutral-50 justify-between items-center w-[400px]">
                                <Link key={i} to={`${r.id}`} style={{ width: "100%" }}>
                                    <div className="flex items-center">
                                        <BsFileEarmark className="w-[18px] h-[18px] mr-2" />{" "}
                                        <span className="truncate">{r.name}</span>
                                    </div>
                                </Link>
                                    <Menu  as="div" className="relative inline-block text-left mx-4">
                                        <div>
                                            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-transparent px-4 py-2 text-sm font-medium text-gray-500 hover:text-black">
                                                <FaEllipsisH />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items style={{zIndex:99999}} className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <div className="px-1 py-1 ">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className={`${
                                                                    active
                                                                        ? "bg-orange-100 text-black"
                                                                        : "text-gray-900"
                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                onClick={() => onClone(r.id)}
                                                            >
                                                                Clone
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                                <div className="px-1 py-1 ">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                className={`${
                                                                    active ? "bg-red-600 text-white" : "text-gray-900"
                                                                } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                                                onClick={(e) => {e.stopPropagation(); onDelete(r.id)}}
                                                            >
                                                                Delete
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}

export default ActionTemplateGroupList
