import { useState } from 'react'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, Field, Label } from '@headlessui/react'
import { BiCheck, BiChevronDown } from 'react-icons/bi'
import { AnchorProps } from '@headlessui/react/dist/internal/floating';

export interface IComboboxOption {
    id: number | string;
    value: string | number;
    label: string;
    disabled?: boolean;

}


interface IProps {
    options: IComboboxOption[];
    selectedOption?: IComboboxOption;
    setSelectedOption: React.Dispatch<any>;
    label?: string;
    disabled?: boolean;
    showValue?: boolean;
    placeholder?: string;
    anchor?: AnchorProps;

}

function CustomCombobox(props: IProps) {
    const [query, setQuery] = useState('')

    const filteredOptions =
        query === ''
            ? props.options
            : props.options.filter((option) => {
                return option.label.toLowerCase().includes(query.toLowerCase())
            })
    return (

        <Field className="relative w-full">
            {!!props.label && <Label className="text-xs text-gray-600">{props.label}</Label>}


            <Combobox disabled={props.disabled} value={!!props.showValue ? props.selectedOption : null} onChange={(option) => { if (!!option) props.setSelectedOption(option) }} >
                <div className="relative mt-1 overflow-auto ">
                    <div className={`relative w-full cursor-default border border-gray-300  rounded-md bg-white text-left focus:outline-none sm:text-sm focus-within:border-tangerine-900 focus-within:border-2 ${props.disabled ? "opacity-20" : ""}`}>

                        <ComboboxInput
                            onChange={(event) => setQuery(event.target.value)}
                            displayValue={(option: IComboboxOption) => option?.label}
                            className={`w-full border-none py-1 pl-3 my-[2px] pr-10 text-sm leading-5 text-gray-900 focus:ring-0 ${props.disabled ? "bg-gray-300 my-0 py-2" : ""}`}
                        />
                    </div>
                    <ComboboxButton className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <BiChevronDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </ComboboxButton>
                </div>
                <div className="absolute mt-[2px] w-full z-10  rounded-md  bg-white text-base  focus:outline-none sm:text-sm">

                    <ComboboxOptions anchor={props.anchor} className="overflow-auto scroll-mr-1 max-h-60 " >
                        {!filteredOptions || filteredOptions.length === 0 ? (
                            <div className="relative cursor-default select-none py-2 px-2 text-gray-700">
                                Nothing found.
                            </div>
                        ) :
                            filteredOptions.map((option) => (
                                <ComboboxOption key={option.id} value={option}
                                    className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-1 pr-1 ${active ? 'bg-dark-forest-700 text-white' : 'text-gray-900'
                                        }`
                                    }

                                >
                                    {({ selected, active }) => (
                                        <div className="flex">
                                            {selected ? (
                                                <span
                                                    className={` mr-1 flex items-center ${active ? 'text-white' : 'text-dark-forest-900'
                                                        }`}
                                                >
                                                    <BiCheck className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : <span className="h-5 w-5 mr-1"></span>}
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                    }`}
                                            >
                                                {option.label}
                                            </span>
                                        </div>
                                    )}
                                </ComboboxOption>
                            ))}
                    </ComboboxOptions>
                </div>
            </Combobox>

        </Field>

    )
}

export default CustomCombobox