import { PropsWithChildren } from "react"

type TableRowProps = {
    marked?: boolean,
    error?: boolean,
    warning?: boolean,
    useHover?: boolean,
    className?: string,
    onClick?: () => void
}
const TableRow = ({ marked, children, useHover, error, warning, className, onClick }: PropsWithChildren<TableRowProps>) => {
    return <tr onClick={onClick} className={`${className} ${!error && !warning && !marked ? 'even:bg-gray-50' : ''} ${useHover ? 'hover:bg-gray-100' : ''} ${error ? 'bg-red-300 hover:bg-red-400' : ''} ${marked ? 'bg-blue-300 hover:bg-blue-400' : ''} ${warning && (!error) ? 'bg-yellow-100 hover:bg-yellow-400' : ''}`}>{children}</tr>
}

export default TableRow