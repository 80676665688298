import { useEffect, useState } from "react"
import ServerAndBucketsErrorsCard from "../components/Server/ServerAndBucketsErrorsCard"
import { get } from "../helpers/Requests"
import { ServerData} from "../types/types"

const CloudServers = () => {
    const [servers, setServers] = useState<ServerData[]>([])

    
    const getData = () => {
        get("servers/cloud")
        .then(setServers)
        .catch(err => console.log(err));
    }


    useEffect(() => {
        getData()
    }, [])

    return <div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 className="text-gray-500 text-lg font-semibold">Cloud Servers</h1></div>
        </div>
        <div className="mx-4 flex flex-row gap-4">
            <div className="flex-1 bg-white rounded-md shadow-md">
                {servers.map((s, id) => <ServerAndBucketsErrorsCard key={id} server={s} /> )}
            </div>
            <div className="flex-1"></div>
        </div>
    </div>
}

export default CloudServers