import { del, get, post, put } from "../helpers/Requests"
import { ActionTemplateGroup, ReportTemplateGroup } from "../types/template_types"

export const SetReportTemplatesEnabled = (value: boolean, serverId: number, orgId: number): Promise<boolean> => {
    return  put(`cloud/${serverId}/organizations/${orgId}/templates/report`, value)
}

export const GetAllReportTemplateGroups = (serverId: number): Promise<ReportTemplateGroup[]> => {
    return get(`cloud/${serverId}/templates/reportGroup`)
}

export const GetEnabledReportGroups = (serverId: number, orgId: number): Promise<Omit<ReportTemplateGroup[], "report_templates">> => {
    return get(`cloud/${serverId}/organizations/${orgId}/templates/reportGroups`)
}

export const EnableReportGroup = (serverId: number, orgId: number, groupId: number): Promise<null> => {
    return post(`cloud/${serverId}/organizations/${orgId}/templates/reportGroups/${groupId}`)
}

export const DisableReportGroup = (serverId: number, orgId: number, groupId: number): Promise<null> => {
    return del(`cloud/${serverId}/organizations/${orgId}/templates/reportGroups/${groupId}`)
}

export const GetHiddenReportTemplates = (serverId: number, orgId: number): Promise<{id: number}[]> => {
    return get(`cloud/${serverId}/organizations/${orgId}/templates/report/hide`)
}

export const HideReportTemplate = (serverId: number, orgId: number, templateId: number): Promise<number[]> => {
    return post(`cloud/${serverId}/organizations/${orgId}/templates/report/hide/${templateId}`)
}

export const UnhideReportTemplate = (serverId: number, orgId: number, templateId: number): Promise<number[]> => {
    return del(`cloud/${serverId}/organizations/${orgId}/templates/report/hide/${templateId}`)
}

export const GetHiddenReportTemplateGroups = (serverId: number, orgId: number): Promise<{id: number}[]> => {
    return get(`cloud/${serverId}/organizations/${orgId}/templates/reportGroups/hide`)
}

export const HideReportTemplateGroup = (serverId: number, orgId: number, groupId: number): Promise<number[]> => {
    return post(`cloud/${serverId}/organizations/${orgId}/templates/reportGroups/hide/${groupId}`)
}

export const UnhideReportTemplateGroup = (serverId: number, orgId: number, groupId: number): Promise<number[]> => {
    return del(`cloud/${serverId}/organizations/${orgId}/templates/reportGroups/hide/${groupId}`)
}

export const SetActionTemplatesEnabled = (value: boolean, serverId: number, orgId: number): Promise<boolean> => {
    return  put(`cloud/${serverId}/organizations/${orgId}/templates/action`, value)
}

export const GetAllActionTemplateGroups = (serverId: number): Promise<ActionTemplateGroup[]> => {
    return get(`cloud/${serverId}/templates/actionGroup`)
}

export const GetEnabledActionGroups = (serverId: number, orgId: number): Promise<Omit<ActionTemplateGroup[], "action_templates">> => {
    return get(`cloud/${serverId}/organizations/${orgId}/templates/actionGroups`)
}

export const EnableActionGroup = (serverId: number, orgId: number, groupId: number): Promise<null> => {
    return post(`cloud/${serverId}/organizations/${orgId}/templates/actionGroups/${groupId}`)
}

export const DisableActionGroup = (serverId: number, orgId: number, groupId: number): Promise<null> => {
    return del(`cloud/${serverId}/organizations/${orgId}/templates/actionGroups/${groupId}`)
}

export const GetHiddenActionTemplates = (serverId: number, orgId: number): Promise<{id: number}[]> => {
    return get(`cloud/${serverId}/organizations/${orgId}/templates/action/hide`)
}

export const HideActionTemplate = (serverId: number, orgId: number, templateId: number): Promise<number[]> => {
    return post(`cloud/${serverId}/organizations/${orgId}/templates/action/hide/${templateId}`)
}

export const UnhideActionTemplate = (serverId: number, orgId: number, templateId: number): Promise<number[]> => {
    return del(`cloud/${serverId}/organizations/${orgId}/templates/action/hide/${templateId}`)
}

export const GetHiddenActionTemplateGroups = (serverId: number, orgId: number): Promise<{id: number}[]> => {
    return get(`cloud/${serverId}/organizations/${orgId}/templates/actionGroups/hide`)
}

export const HideActionTemplateGroup = (serverId: number, orgId: number, groupId: number): Promise<number[]> => {
    return post(`cloud/${serverId}/organizations/${orgId}/templates/actionGroups/hide/${groupId}`)
}

export const UnhideActionTemplateGroup = (serverId: number, orgId: number, groupId: number): Promise<number[]> => {
    return del(`cloud/${serverId}/organizations/${orgId}/templates/actionGroups/hide/${groupId}`)
}
