import React, { useEffect } from "react";
import CustomCheckbox from "../components/Buttons/CustomCheckbox";
import { useState } from "react";
import { FaArrowLeft, FaChevronLeft, FaPlus, FaTrash } from "react-icons/fa";
import {
    CreateCxmSetup,
    DeleteCxmSetup,
    getCxmSetups,
    SaveCxmSetup,
    SetCxmEnabled,
    UpdateCxmData,
} from "../api/cxm.api";
import { getBuckets } from "../api/organizations.api";
import Button from "../components/Button";
import EditCxmSetup from "../components/Cxm/EditCxmSetup";
import {
    Table,
    TableBody,
    TableBodyCell,
    TableBodyRow,
    TableHead,
    TableHeadCell,
    TableHeadRow,
} from "../components/Table";
import { get } from "../helpers/Requests";
import { Bucket } from "../types/bucket_types";
import { CloudOrgInfo } from "../types/cloud_types";
import { CxmSetup } from "../types/cxm";
import { useDispatch } from "../contexts/StoreContext";
import useConfirmDialog from "../hooks/useConfirmDialog";
import { Modal } from "../components/Modals";
import CustomInput from "../components/CustomInput";
import { shortUUID } from "../helpers/general_helpers";
import ShowIf from "../components/ShowIf";
import { HiOutlineExclamation } from "react-icons/hi";

interface props {
    org: CloudOrgInfo;
}

const OrganizationCxmSetup = ({ org, ...props }: props) => {
    const [setups, setSetups] = useState<Map<string, CxmSetup>>(new Map());
    const [buckets, setBuckets] = useState<Map<string, Bucket>>(new Map());

    const [setupToEdit, setSetupToEdit] = useState<CxmSetup>();
    const dispatch = useDispatch();

    const [showCreateSetup, setShowCreateSetup] = useState(false);
    const [tmpName, setTmpName] = useState("");

    const { confirm, ConfirmDialog } = useConfirmDialog();

    useEffect(() => {
        if (org.cxm_enabled) {
            getSetupsFromAPI();
        }
        getBucketsFromAPI();
    }, []);

    const getSetupsFromAPI = () => {
        if (org.server == undefined) return;
        getCxmSetups(org.server.id, org.id)
            .then((result) => {
                setSetups(new Map(result.map((r) => [r.id, r])));
            })
            .catch((e) => console.error(e))
    };

    const getBucketsFromAPI = () => {
        if (org.server === undefined) return;
        getBuckets(org.server.id, org.id)
            .then((b) => {
                const m = new Map(
                    b.filter((bucket) => Boolean(bucket.info?.model?.columns)).map((bucket) => [bucket.id, bucket]),
                );
                setBuckets(m);
            })
            .catch((e) => console.error(e))
    };

    const setEnabled = async (value: boolean) => {
        if (org.server == undefined) return;

        if (!value) {
            if (!(await confirm("Are you sure? This will delete all computed data"))) {
                return;
            }
        }
        SetCxmEnabled(org.server.id, org.id, value)
            .then(async (val) => {
                let tmpOrg = structuredClone(org);
                tmpOrg.cxm_enabled = val;
                dispatch({ type: "change_org", id: tmpOrg.id, org: tmpOrg });
                // If we turn it on, the data is missing
                if (val) {
                    getSetupsFromAPI()
                    if (
                        await confirm("The CxM does not contain any data, do you want to refresh the data now?", {
                            title: "Not enough data",
                        })
                    ) {
                        updateCxmData();
                    }
                    return
                }
            })
            .catch((e) => console.error(e))
    };

    const onNewSetup = () => {
        setShowCreateSetup(true);
        setTmpName("");
    };

    const createSetup = () => {
        if (tmpName == "" || org.server == undefined) return;
        if (org.server == undefined) return;
        const tmpSetup: CxmSetup = {
            id: shortUUID(),
            name: tmpName,
            columns: [],
        };
        CreateCxmSetup(org.server.id, org.id, tmpSetup)
            .then((s) => {
                let tmp = new Map(setups);
                tmp.set(s.id, s);
                setSetups(tmp);
            })
            .catch((e) => console.error(e));
        setShowCreateSetup(false);
    };

    const deleteSetup = async (setup: CxmSetup) => {
        if (
            org.server == undefined ||
            !(await confirm(
                "Are you sure? This will also remove all End2End setups depending on this. It can not be undone!",
                { title: "Delete CxM setup permanently" },
            ))
        ) {
            return;
        }

        DeleteCxmSetup(org.server.id, org.id, setup.id)
            .then(() => {
                let tmp = new Map(setups);
                tmp.delete(setup.id);
                setSetups(tmp);
            })
            .catch((e) => console.error(e));
    };

    const saveSetup = async (setup: CxmSetup) => {
        if (org.server == undefined || !(await confirm("Save changes?"))) return;
        SaveCxmSetup(org.server.id, org.id, setup).then(async (s) => {
            setSetupToEdit(s.setup);
            let tmp = new Map(setups);
            tmp.set(s.setup.id, s.setup);
            setSetups(tmp);
            if (
                await confirm(
                    "The CxM does not contain data to support the setup, do you want to refresh the data now?",
                    { title: "Not enough data" },
                )
            ) {
                updateCxmData();
            }
        });
    };

    const updateCxmData = async () => {
        if (org.server == undefined) return;
        UpdateCxmData(org.server.id, org.id)
            .then(() => {})
            .catch((e) => console.error(e));
    };

    return (
        <>
            <div>
                {setupToEdit == undefined ? (
                    <>
                        <div className="flex justify-between items-center border-b border-slate-200 mb-2">
                            <h1 className="flex items-center text-gray-500 text-lg gap-2">End2End Setups</h1>
                            <div className="flex gap-2 p-1">
                                <Button
                                    disabled={!org.cxm_enabled}
                                    onClick={onNewSetup}
                                    icon={<FaPlus />}
                                    text="New setup"
                                    color="primary"
                                    className="w-fit"
                                />
                            </div>
                        </div>
                        <CustomCheckbox
                            value={org.cxm_enabled}
                            onChange={(val) => setEnabled(val)}
                            text="Enable End2End"
                        />
                        <ShowIf if={org.cxm_enabled}>
                            <Table className="table-auto">
                                <TableHead className="text-gray-700 bg-gray-50">
                                    <TableHeadRow>
                                        <TableHeadCell>ID</TableHeadCell>
                                        <TableHeadCell>Name</TableHeadCell>
                                        <TableHeadCell>Columns</TableHeadCell>
                                        <TableHeadCell />
                                    </TableHeadRow>
                                </TableHead>
                                <TableBody>
                                    {Array.from(setups.values()).map((s, i) => {
                                        return (
                                            <TableBodyRow
                                                onClick={() => setSetupToEdit(s)}
                                                className="bg-white hover:bg-neutral-50 cursor-pointer"
                                                key={s.id}
                                            >
                                                <TableBodyCell>{s.id}</TableBodyCell>
                                                <TableBodyCell>{s.name}</TableBodyCell>
                                                <TableBodyCell className="flex items-center gap-2">
                                                    {s.columns.length}
                                                    <ShowIf if={s.columns.length == 0}>
                                                        <HiOutlineExclamation
                                                            title="No columns in setup"
                                                            className="text-red-600"
                                                        />
                                                    </ShowIf>
                                                </TableBodyCell>
                                                <TableBodyCell>
                                                    <button
                                                        title="Delete"
                                                        className="text-black hover:text-red-600 transition"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteSetup(s);
                                                        }}
                                                    >
                                                        <FaTrash />
                                                    </button>
                                                </TableBodyCell>
                                            </TableBodyRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </ShowIf>
                        <ShowIf if={!org.cxm_enabled}>
                            <div>
                                <h1>End2End is disabled, enable it to see setups</h1>
                            </div>
                        </ShowIf>
                    </>
                ) : (
                    <>
                        <EditCxmSetup
                            org={org}
                            setup={setupToEdit}
                            buckets={buckets}
                            back={() => setSetupToEdit(undefined)}
                            saveSetup={(s) => saveSetup(s)}
                        />
                    </>
                )}
            </div>
            <ConfirmDialog />
            <Modal
                isOpen={showCreateSetup}
                onClose={() => setShowCreateSetup(false)}
                onAction={createSetup}
                title="New setup"
                actionText="Create"
                closeText="Cancel"
                size="small"
                disableAction={tmpName == ""}
            >
                <form
                    className="mt-3"
                    onSubmit={(e) => {
                        e.preventDefault();
                        createSetup();
                    }}
                >
                    <CustomInput
                        id="name-input"
                        value={tmpName}
                        label="Setup name"
                        onChange={(e) => setTmpName(e.target.value)}
                        placeholder="Insert name..."
                    />
                </form>
            </Modal>
        </>
    );
};

export default OrganizationCxmSetup;
