import { sortableKeyboardCoordinates, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FC, ReactNode } from "react";
import { closestCenter, DndContext, DndContextProps, KeyboardSensor, MouseSensor, PointerSensor, UniqueIdentifier, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, SortingStrategy } from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { FaEllipsisH, FaLock } from "react-icons/fa";

export interface SortableItemProps {
    id: UniqueIdentifier;
    children?: ReactNode;
    name?: string | ReactNode;
    icon?: ReactNode;
    onClick?: () => void;
    selected?: boolean;
    disabled?: boolean;
    className?: string;
}
export const SortableItem = (props: SortableItemProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transition,
        transform,
        isDragging,
        active,
    } = useSortable({
        id: props.id,
        disabled: props.disabled,
        resizeObserverConfig: undefined
    });

    const isActiveItem = isDragging && active?.id === props.id;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        boxShadow: isDragging ? "5px 5px 10px 0px grey" : "none",
        backgroundColor: isActiveItem ? "lightgrey" : "initial",
        color: props.disabled ? "grey" : "initial",
        cursor: isDragging ? "grabbing" : "grab",
        zIndex: isDragging ? 999 : "initial"
    };

    return (
        <div className={`${props.className ?? ""} relative`} ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {props.children}
        </div>
    );
};

export const SortableItemWithDragHandle = (props: SortableItemProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transition,
        transform,
        isDragging,
        active,
    } = useSortable({
        id: props.id,
        disabled: props.disabled,
        resizeObserverConfig: undefined
    });

    const isActiveItem = isDragging && active?.id === props.id;
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        boxShadow: isDragging ? "5px 5px 10px 0px grey" : "none",
        backgroundColor: isActiveItem ? "lightgrey" : "initial",
        color: props.disabled ? "grey" : "initial",
        zIndex: isDragging ? 999 : "initial"
    };

    return (
        <div className={`${props.className ?? ""} relative`} ref={setNodeRef} style={style} >
            {!props.disabled && <FaEllipsisH style={{cursor: isDragging ? "grabbing" : "grab"}} className="text-gray-400 inline-block outline-none" {...attributes} {...listeners} />}
            {props.disabled && <FaLock className="text-gray-400 inline-block outline-none" />}
            {props.children}
        </div>
    );
};

interface SortableListProps extends DndContextProps {
    items: SortableItemProps[];
    containerClassName?: string
    strategy?: SortingStrategy
    dragHandle?: boolean
    itemClassName?: string
}
  
export const SortableList: FC<SortableListProps> = (props) => {
    const mouseSensor = useSensor(MouseSensor);
    const keyboardSensor = useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
    });
    const pointerSensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 8, // Enable item onClick function
        },
    });
    const sensors = useSensors(keyboardSensor, pointerSensor, mouseSensor);
    return (
        <DndContext
            sensors={sensors}
            onDragEnd={props.onDragEnd}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            {...props}
        >   
            <SortableContext items={props.items} strategy={verticalListSortingStrategy}>
                <div className={props.containerClassName}>
                        {props.items.map((item) => {
                            
                            if(!!props.dragHandle){
                                return (
                                    <SortableItemWithDragHandle 
                                        className={props.itemClassName} 
                                        key={item.id} 
                                        id={item.id} 
                                        disabled={item.disabled}
                                    >
                                        {item.children}
                                    </SortableItemWithDragHandle>
                                ) 
                            }
                            return (
                                <SortableItem 
                                    key={item.id} 
                                    id={item.id} 
                                    disabled={item.disabled}
                                >
                                    {item.children}
                                </SortableItem>
                            );
                        })}
                </div>
            </SortableContext>
        </DndContext>
    );
};
  
export default SortableList