/** Recursively compares the two objects. Returns true if they are equal, otherwise false.
*/
export const compareObjects = (obj1: any, obj2: any, key?: string, debug = false) => {
    if (typeof obj1 != typeof obj2){
        if (debug) {
            console.log(`Wrong types ${key}`)
            console.log(obj1)
            console.log(obj2)
        }
        return false
    } 
    
    if (typeof obj1 != "object" || obj1 == null || obj2 == null) {
        let res = obj1 === obj2
        if (!res && debug) {
            console.log(`Not eq ${key}`)
            console.log(obj1)
            console.log(obj2)
        }
        return res
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        if (debug){
            console.log(`Not same keys ${key}`)
            console.log(obj1)
            console.log(obj2)
        }
        return false
    }
    // Quickly return false if obj1 and obj2 does not contain the same keys instead of searching recursively
    for (let key in obj1) {
        if (obj2[key] === undefined) {
            if (debug){
                console.log(`Not same keys ${key}`)
                console.log(obj1)
                console.log(obj2)
            }
            return false
        }
    }

    // Recursively run through the objects
    for (let key in obj1) {
        let result = compareObjects(obj1[key], obj2[key], key)
        if (!result) {
            if (debug) {
                console.log(`False ${key}`)
            }
            return false
        }
    }
    return true
}

