import { Transition } from "@headlessui/react"
import { Fragment, useCallback, useState, useEffect, } from "react"
import { post } from "../../helpers/Requests"
import Button from "../Button"
import CustomInput from "../CustomInput"
import CustomCombobox, { IComboboxOption } from "../CustomCombobox"
import { useStore, useDispatch } from "../../contexts/StoreContext"
import { getOrganizations } from "../../api/organizations.api"


const AddAccount: React.FC<{
    serverId: string,
    open: boolean,
    onClose(): any,
}> = ({ serverId, open, onClose }) => {

    const [name, setName] = useState<string>("")
    const [options, setOptions] = useState<IComboboxOption[] | []>([])
    const [selectedOption, setSelectedOption] = useState<IComboboxOption>()
    let { servers, orgs } = useStore()
    const dispatch = useDispatch()


    useEffect(() => {

        if (!!orgs && orgs.length === 0) {
            getOrganizations(servers).then(res => {
                dispatch(({
                    type: 'set_orgs',
                    orgs: res
                }))

            })
        }
    }, [orgs])


    useEffect(() => {
        if (!!orgs && orgs.length > 0 && options.length <= 1) {

            const opts = orgs.map(o => {
                return {
                    id: o.id,
                    value: o.id,
                    label: o.name,
                    disabled: false
                }
            })

            const options = [{ value: 0, id: 0, label: "No Organization" }]
            const newOptions = options.concat(opts)
            setOptions(newOptions)
            setSelectedOption({ id: 0, value: 0, label: "No Organization" })
        }

    }, [orgs, options])

    const onCloseLocal = useCallback(() => {
        onClose();
        setName('')
        setTimeout(() => {
        }, 300) // wait for aninmation to finish
    }, [onClose])

    const doCreateAccount = useCallback(() => {
        post(`apa/${serverId}/accounts`, { name: name, organization_id: !!selectedOption ? selectedOption.id : 0 })
            .then(res => {
                onCloseLocal()
            })
            .catch(err => alert(err))
    }, [name, serverId, selectedOption, onCloseLocal])

    function selectedOptionHandler(value: any) {
        setSelectedOption(value)
    }

    return <Transition
        as={Fragment}
        show={open}
        enter="transform transition duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95 "
    >
        <div className="fixed right-4 top-[220px] z-40 text-sm rounded-lg shadow-2xl border bg-white border-gray-300">
            <div className="w-[400px]">
                <div className="p-4 border-b border-gray-200">
                    <div className="font-semibold text-base">Add Account</div>
                </div>
                <div className="flex flex-col p-4 gap-2">
                    <div>
                        <CustomInput name="name" label="Name" value={name} onChange={e => setName(e.target.value)} />
                    </div>
                    <div className="mb-3 z-10 max-w-xs">
                        <CustomCombobox placeholder="Bla" options={options} showValue={true} selectedOption={!!selectedOption ? selectedOption : { id: 0, value: 0, label: "No Organization" }} setSelectedOption={value => selectedOptionHandler(value)}></CustomCombobox>
                    </div>

                </div>
                <div className="bg-gray-50 border-t border-gray-300 rounded-br-lg rounded-bl-lg">
                    <div className="p-4 flex flex-row-reverse gap-2">
                        <div><Button disabled={name === ""} text="Save" color="primary" onClick={() => { doCreateAccount() }} /></div>
                        <div><Button text="Cancel" color="secondary" onClick={onCloseLocal} /></div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
}

export default AddAccount