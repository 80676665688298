import moment from "moment"
import { useCallback, useEffect, useRef, useState } from "react"
import { BsCloudDownload } from "react-icons/bs"
import { GrPlayFill, GrResume } from "react-icons/gr"
import { HiDotsCircleHorizontal, HiTrash } from "react-icons/hi"
import { MdDangerous } from "react-icons/md"
import { useParams, useLocation } from "react-router-dom"
import AddSchedule from "../components/APA/AddSchedule"
import AddSetup from "../components/APA/AddSetup"
import Button from "../components/Button"
import MenuDropdown from "../components/MenuDropdown"
import MenuDropdownItem from "../components/MenuDropdownItem"
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow } from "../components/Table"
import { del, get, getRaw, post, postRaw, put } from "../helpers/Requests"
import { APAAccount, APARun, APASchedule, APAScheduleRun, APASetup } from "../types/types"
import { BsBugFill } from 'react-icons/bs'
import { Dialog, DialogTitle } from "@headlessui/react"
import CustomInput from "../components/CustomInput"
import { useDispatch } from "../contexts/StoreContext"
import { FaCog, FaExclamationTriangle } from "react-icons/fa"
import { APALinkOrg } from "../components/APA/APALinkOrg"

type APAOrganizationProps = {
	serverId?: string
	accountID?: string
}
const APAOrganization = (props: APAOrganizationProps) => {
	let { serverId, accountID } = useParams()
	const [account, setAccount] = useState<APAAccount | null>(null)
	const [setups, setSetups] = useState<APASetup[]>([])
	const [schedules, setSchedules] = useState<APASchedule[]>([])
	const [scheduledRuns, setScheduledRuns] = useState<APAScheduleRun[]>([])
	const [logs, setLogs] = useState<APARun[]>([])
	const [addAccountOpen, setAddAccountOpen] = useState<boolean>(false)
	const [markedSetup, setMarkedSetup] = useState<number | null>(null)
	const [addSetupOpen, setAddSetupOpen] = useState<boolean>(false)
	const [openedError, setOpenedError] = useState<number>(-1)
	const [popupError, setPopupError] = useState<string>("")
	const location = useLocation()
	const logsRef = useRef<HTMLDivElement>(null)
	const [name, setName] = useState<string | undefined>(undefined)
	const [editMode, setEditMode] = useState<boolean>(false)
	const [selectedOrg, setSelectedOrg] = useState<number | undefined>()
	const dispatch = useDispatch()


	const accountName = name !== undefined ? name : account?.name;

	const MAX_LOGS = 25

	useEffect(() => {
		if (!!account && selectedOrg === undefined) {
			setSelectedOrg(account?.organization_id)
		}
	}, [selectedOrg, account])

	useEffect(() => {
		if (!serverId) {
			serverId = props.serverId;
		}

		if (!accountID) {
			accountID = props.accountID;
		}
	}, [serverId, accountID])

	useEffect(() => {
		if (location.hash === "#logs" && !!logsRef.current && logs) {
			logsRef.current.scrollIntoView()
		}
	}, [location, logsRef, logs])

	const refreshData = useCallback(() => {
		get(`apa/${serverId}/setups/account/${accountID}`)
			.then(setups => setSetups(setups))
			.catch(e => console.log(e))

		get(`apa/${serverId}/schedules/account/${accountID}`)
			.then(schedules => setSchedules(schedules))
			.catch(e => console.log(e))

		get(`apa/${serverId}/schedules/runs/account/${accountID}`)
			.then(scheduledRuns => setScheduledRuns(scheduledRuns))
			.catch(e => console.log(e))

		get(`apa/${serverId}/logs/account/${accountID}`)
			.then(logs => setLogs(logs))
			.catch(e => console.log(e))

		setMarkedSetup(null)
	}, [accountID, serverId])

	const downloadFile = useCallback((s: APASetup) => {
		postRaw(`apa/${serverId}/setups/${s.id}/download`, null)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'application/zip' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `${s.account_id}_setup_${s.id}.aut`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setMarkedSetup(null)
			})
			.catch(e => alert(`Failed to download file: ${e}`))
	}, [serverId])

	const doShowLog = useCallback((id: number) => {
		getRaw(`apa/${serverId}/logs/${id}/log`)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'text/plain' })
				blob.text()
					.then(text => {
						setPopupError(text)
						setOpenedError(id)
					})
					.catch(e => alert(`Failed to show log: ${e}`))
			})
			.catch(e => alert(`Failed to show log: ${e}`))
	}, [serverId])

	const doDownloadLog = useCallback((id: number) => {
		getRaw(`apa/${serverId}/logs/${id}/log`)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'text/plain' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `log_${id}.txt`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setMarkedSetup(null)
			})
			.catch(e => alert(`Failed to download log: ${e}`))
	}, [serverId])

	const doDownloadError = useCallback((id: number) => {
		getRaw(`apa/${serverId}/logs/${id}/error`)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'text/plain' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `error_${id}.txt`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setMarkedSetup(null)
			})
			.catch(e => alert(`Failed to download error: ${e}`))
	}, [serverId])

	const doDownloadStats = useCallback((id: number) => {
		getRaw(`apa/${serverId}/logs/${id}/stats`)
			.then(res => res.blob())
			.then(data => {
				const blob = new Blob([data], { type: 'text/plain' })
				const link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = `stats_${id}.txt`
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				setMarkedSetup(null)
			})
			.catch(e => alert(`Failed to download stats: ${e}`))
	}, [serverId])

	const quarantineSetup = useCallback((s: APASetup) => {
		put(`apa/${serverId}/setups/${s.id}/quarantine`, null)
			.then(() => refreshData())
			.catch(e => console.log(e))
	}, [serverId, refreshData])

	const unquarantineSetup = useCallback((s: APASetup) => {
		put(`apa/${serverId}/setups/${s.id}/unquarantine`, null)
			.then(() => refreshData())
			.catch(e => console.log(e))
	}, [serverId, refreshData])

	const enqueueSetup = useCallback((s: APASetup) => {
		post(`apa/${serverId}/setups/${s.id}/run`, null)
			.then(() => refreshData())
			.catch(e => console.log(e))
	}, [serverId, refreshData])

	const enqueueSpecialSetup = useCallback((s: APASetup) => {
		post(`apa/${serverId}/setups/${s.id}/runskipmonitor`, null)
			.then(() => refreshData())
			.catch(e => console.log(e))
	}, [serverId, refreshData])

	const deleteSetup = (s: APASetup) => {
		if (window.confirm("Are you sure you want to delete this setup? (this will also delete all associated schedules, runs and logs!!!)")) {
			del(`apa/${serverId}/setups/${s.id}`)
				.then(() => refreshData())
				.catch(e => console.log(e))
		}
	}

	const doDeleteSchedule = (id: number) => {
		if (window.confirm("Are you sure you want to delete this schedule?")) {
			del(`apa/${serverId}/schedules/${id}`)
				.then(() => {
					refreshData()
				})
				.catch(e => console.log(e))
		}
	}

	function updateAccount() {
		put(`apa/${serverId}/accounts/${accountID}`, { name: accountName, organization_id: selectedOrg })
			.then(res => {
				res.serverId = serverId
				dispatch(({
					type: "change_account",
					id: parseInt(res.id, 10),
					account: res
				}))

				return res

			}).then(res => {
				setAccount(res);
				setEditMode(false);
			}).catch(err => alert(err))
	}

	const formatDuration = (d: moment.Duration): string => {
		let hours = Math.floor(d.asHours())
		let minutes = d.asMinutes() % 60
		if (minutes === 0) return (`${hours} hour` + (hours === 1 ? '' : 's'))
		return (`${hours} hour` + (hours === 1 ? '' : 's')) + ` ${minutes} minute${minutes === 1 ? '' : 's'}`
	}

	const showDays = (days: string): string => {
		if (days === "") //backwards compability
			return "Mo Tu We Th Fr Sa Su"

		let isoNames = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

		let dayArray = JSON.parse(days)
		let result = ""
		for (let i = 0; i < dayArray.length; i++) {
			if (dayArray[i])
				result += isoNames[i] + " "
			else
				result += "   "
		}

		return result.slice(0, -1);
	}

	useEffect(() => {
		get(`apa/${serverId}/accounts/${accountID}`)
			.then(a => setAccount(a))
			.catch(e => console.log(e))
		refreshData()

	}, [accountID, refreshData, serverId])


	function editClickHandler() {
		setEditMode(!editMode)
	}

	function cancelEditHandler() {
		setName(account?.name)
		const accountOrg = !!account?.organization_id ? account.organization_id : 0
		setSelectedOrg(accountOrg)
		setEditMode(false)
	}


	return <div className="bg-gray-100 h-full">
		<div className="bg-white p-8 border-b border-gray-200">
			<div className="flex justify-between items-center mb-4 pt-2 px-4">
				<div className="flex align-center">
					<h1 className="font-bold text-3xl mb-10 mr-1 text-dark-forest-900">{account?.name} </h1>
				</div>
				<div className="flex flex-col items-end">
					<div className="flex justify-end align-center gap-2 h-[40px]">
						{!!accountID && selectedOrg !== undefined ?
							<div className="flex flex-row-reverse align-center gap-2">
								<button className="h-2"><FaCog size={18} onClick={editClickHandler} className="text-dark-forest-900"></FaCog></button>
								{
									editMode &&

									<div className="flex align-center justify-center gap-1 ">


										<CustomInput className="my-1" name="name" label="" value={accountName} onChange={e => setName(e.target.value)} />
										<APALinkOrg selectedOrg={selectedOrg} setSelectedOrg={(v: number) => setSelectedOrg(v)} accountID={accountID} ></APALinkOrg>
										<div className="ml-3"><Button className="py-1.5 mb-0" disabled={name === ""} text="Save" color="primary" onClick={() => { updateAccount() }} /></div>
										<div className="ml-3"><Button className="py-1.5 mb-0" text="Cancel" color="secondary" onClick={() => { cancelEditHandler() }} /></div>
									</div>

								}
								{!account?.organization_id &&
									<div className="flex gap-2 items-center"><FaExclamationTriangle className="text-yellow-500" ></FaExclamationTriangle> <span>Account not linked to any organization</span> </div>
								}
							</div>
							:
							<div role="status">
								<svg aria-hidden="true" className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-tangerine-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
									<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
								</svg>
								<span className="sr-only">Loading...</span>
							</div>}
						<Button text='Refresh' color="default" onClick={_ => { refreshData() }} />
					</div>
				</div>

			</div>

			<div className="flex justify-between items-end mb-4">
				<h2 className="font-bold text-2xl mb-10">Setups</h2>

				<Button text="Add Setup" color="primary" onClick={_ => setAddSetupOpen(true)} />
				<AddSetup serverId={serverId === undefined ? "" : serverId} setups={setups} account={Number(accountID)} open={addSetupOpen} onClose={() => { setAddSetupOpen(false); refreshData() }} />
			</div>

			<div>
				<Table>
					<TableHead className="bg-black bg-opacity-50 text-white">
						<TableHeadRow>
							<TableHeadCell>ID</TableHeadCell>
							<TableHeadCell>Description</TableHeadCell>
							<TableHeadCell>Quarantined</TableHeadCell>
							<TableHeadCell>Creation Time</TableHeadCell>
							<TableHeadCell>Schedules</TableHeadCell>
							<TableHeadCell>Runs</TableHeadCell>
							<TableHeadCell></TableHeadCell>
						</TableHeadRow>
					</TableHead>
					<TableBody>
						{setups.map((s, i) => <TableBodyRow onClick={() => setMarkedSetup(markedSetup && markedSetup === s.id ? null : s.id)} marked={markedSetup === s.id} key={i}>
							<TableBodyCell>{s.id}</TableBodyCell>
							<TableBodyCell>{s.description}</TableBodyCell>
							<TableBodyCell>{s.quarantined ? <MdDangerous className="w-5 h-5 mr-2 text-red-700 opacity-80" /> : "No"}</TableBodyCell>
							<TableBodyCell><span title={moment.unix(s.created_at).toLocaleString()}>{moment.unix(s.created_at).fromNow()}</span></TableBodyCell>
							<TableBodyCell>{schedules.filter(sc => sc.setup_id === s.id).length}</TableBodyCell>
							<TableBodyCell>{scheduledRuns.filter(sr => sr.setup_id === s.id).length}</TableBodyCell>
							<TableBodyCell>
								<MenuDropdown color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-5 h-5 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
									<div className="px-1 py-1 ">
										<MenuDropdownItem onClick={() => { downloadFile(s) }} text={"Download"} icon={<BsCloudDownload className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => { if (s.quarantined) { unquarantineSetup(s) } else { quarantineSetup(s) } }} text={s.quarantined ? 'Un-quarantine' : 'Quarantine'} icon={s.quarantined ? <GrResume className="w-5 h-5 mr-2 opacity-80" /> : <MdDangerous className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
										<MenuDropdownItem onClick={() => { enqueueSetup(s) }} text={"Run now"} icon={<GrPlayFill className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => { enqueueSpecialSetup(s) }} text={"Run now (ignore monitors)"} icon={<GrPlayFill className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => { deleteSetup(s) }} text={"Delete"} icon={<HiTrash className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
									</div>
								</MenuDropdown>

							</TableBodyCell>
						</TableBodyRow>)}
					</TableBody>
				</Table>
			</div>

			<div className="flex justify-between items-end mb-4">
				<h2 className="font-bold text-2xl my-10">Schedules</h2>

				<Button text="Add Schedule" color="primary" onClick={_ => setAddAccountOpen(true)} />
				<AddSchedule serverId={serverId === undefined ? "" : serverId} setups={setups} open={addAccountOpen} onClose={() => { setAddAccountOpen(false); refreshData() }} />
			</div>

			<div>
				<Table>
					<TableHead className="bg-black bg-opacity-50 text-white">
						<TableHeadRow>
							<TableHeadCell>ID</TableHeadCell>
							<TableHeadCell>Setup ID</TableHeadCell>
							<TableHeadCell>Trigger Time</TableHeadCell>
							<TableHeadCell>Max Duration</TableHeadCell>
							<TableHeadCell>Days</TableHeadCell>
							<TableHeadCell></TableHeadCell>
						</TableHeadRow>
					</TableHead>
					<TableBody>
						{schedules.map((s, i) => <TableBodyRow marked={markedSetup === s.setup_id} onClick={() => setMarkedSetup(markedSetup && markedSetup === s.setup_id ? null : s.setup_id)} key={i}>
							<TableBodyCell>{s.id}</TableBodyCell>
							<TableBodyCell>{s.setup_id}</TableBodyCell>
							<TableBodyCell>{s.hour}:{s.minute < 10 ? `0${s.minute}` : s.minute}</TableBodyCell>
							<TableBodyCell>{formatDuration(moment.duration(s.max_duration, 'seconds'))}</TableBodyCell>
							<TableBodyCell>{showDays(s.days)}</TableBodyCell>
							<TableBodyCell>
								<MenuDropdown color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-5 h-5 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
									<div className="px-1 py-1 ">
										<MenuDropdownItem onClick={() => doDeleteSchedule(s.id)} text={"Delete"} icon={<HiTrash className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
									</div>
								</MenuDropdown>
							</TableBodyCell>
						</TableBodyRow>)}
					</TableBody>
				</Table>
			</div>


			<h2 className="font-bold text-2xl my-10">Runs</h2>

			<div>
				<Table>
					<TableHead className="bg-black bg-opacity-50 text-white">
						<TableHeadRow>
							<TableHeadCell>ID</TableHeadCell>
							<TableHeadCell>Schedule ID</TableHeadCell>
							<TableHeadCell>Setup ID</TableHeadCell>
							<TableHeadCell>Trigger Time</TableHeadCell>
							<TableHeadCell>Deadline</TableHeadCell>
							<TableHeadCell>Running</TableHeadCell>
						</TableHeadRow>
					</TableHead>
					<TableBody>
						{scheduledRuns.map((s, i) => <TableBodyRow onClick={() => setMarkedSetup(markedSetup && markedSetup === s.setup_id ? null : s.setup_id)} marked={markedSetup === s.schedule_id} key={i}>
							<TableBodyCell>{s.id}</TableBodyCell>
							<TableBodyCell>{s.schedule_id}</TableBodyCell>
							<TableBodyCell>{s.setup_id}</TableBodyCell>
							<TableBodyCell><span title={moment.unix(s.trigger_time).toLocaleString()}>{moment.unix(s.trigger_time).fromNow()}</span></TableBodyCell>
							<TableBodyCell><span title={moment.unix(s.deadline).toLocaleString()}>{moment.unix(s.deadline).fromNow()}</span></TableBodyCell>
							<TableBodyCell>{s.running ? "Yes" : "No"}</TableBodyCell>
						</TableBodyRow>)}
					</TableBody>
				</Table>
			</div>

			<h2 id="logs" ref={logsRef} className="font-bold text-2xl my-10">Logs</h2>

			<div >
				<Table>
					<TableHead className="bg-black bg-opacity-50 text-white">
						<TableHeadRow>
							<TableHeadCell>ID</TableHeadCell>
							<TableHeadCell>Setup ID</TableHeadCell>
							<TableHeadCell>Scheduled</TableHeadCell>
							<TableHeadCell>Error</TableHeadCell>
							<TableHeadCell>Start Time</TableHeadCell>
							<TableHeadCell>End Time</TableHeadCell>
							<TableHeadCell>Deadline</TableHeadCell>
							<TableHeadCell></TableHeadCell>
						</TableHeadRow>
					</TableHead>
					<TableBody>
						{logs.sort((a, b) => b.end_time - a.end_time).slice(0, MAX_LOGS).map((l, i) => <TableBodyRow onClick={() => setMarkedSetup(markedSetup && markedSetup === l.setup_id ? null : l.setup_id)} marked={markedSetup === l.setup_id} key={i}>
							<TableBodyCell>{l.id}</TableBodyCell>
							<TableBodyCell>{l.setup_id}</TableBodyCell>
							<TableBodyCell>{l.scheduled ? "Yes" : "No"}</TableBodyCell>
							<TableBodyCell>
								{l.has_error ?
									<span onClick={() => doShowLog(l.id)}><BsBugFill className={`h-5 w-5 text-red-700 cursor-pointer`} /></span>
									: "No"}
							</TableBodyCell>
							<TableBodyCell><span title={moment.unix(l.start_time).toLocaleString()}>{moment.unix(l.start_time).fromNow()}</span></TableBodyCell>
							<TableBodyCell><span title={moment.unix(l.end_time).toLocaleString()}>{moment.unix(l.end_time).fromNow()}</span></TableBodyCell>
							<TableBodyCell><span title={moment.unix(l.deadline).toLocaleString()}>{moment.unix(l.deadline).fromNow()}</span></TableBodyCell>
							<TableBodyCell>
								<MenuDropdown color={"icon"} expand="left" icon={<HiDotsCircleHorizontal className="w-5 h-5 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
									<div className="px-1 py-1 ">
										<MenuDropdownItem onClick={() => doDownloadLog(l.id)} text={"Log"} icon={<BsCloudDownload className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => doDownloadError(l.id)} text={"Error"} icon={<BsCloudDownload className="w-5 h-5 mr-2 opacity-80" />} />
										<MenuDropdownItem onClick={() => doDownloadStats(l.id)} text={"Stats"} icon={<BsCloudDownload className="w-5 h-5 mr-2 opacity-80" />} />
									</div>
								</MenuDropdown>
							</TableBodyCell>
						</TableBodyRow>)}
					</TableBody>
				</Table>
			</div>

		</div>

		<Dialog className="fixed z-10 inset-0 overflow-y-auto"
			open={openedError > -1}
			onClose={() => setOpenedError(-1)}>
			<div className="flex items-center justify-center min-h-screen">
				<div className="relative bg-white rounded mx-auto p-5 drop-shadow-lg">
					<DialogTitle className="text-2xl">
						Error details
					</DialogTitle>
					<br />
					{
						popupError ?
							<div id="errorPopup" style={{ whiteSpace: "pre-wrap", fontFamily: "courier" }}>
								{popupError}
							</div>
							: null
					}
					<br />
					<button className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded disabled:bg-green-300 disabled:hover:bg-green-300 mr-2"
						onClick={() => setOpenedError(-1)}>
						OK
					</button>
				</div>
			</div>
		</Dialog>
	</div >
}

export default APAOrganization