import { del, get, post, put } from "../helpers/Requests"
import { CxmSetup } from "../types/cxm"

export const getCxmSetups = (serverId: number, organization: number): Promise<CxmSetup[]> => {
        return get(`cloud/${serverId}/e2e/${organization}/setups`)
}

export const SetCxmEnabled = (serverId: number, organization: number, value: boolean): Promise<boolean> => {
    return put(`cloud/${serverId}/e2e/${organization}`, value)
}

export const CreateCxmSetup = (serverId: number, organization: number, setup: CxmSetup): Promise<CxmSetup> => {
    return post(`cloud/${serverId}/e2e/${organization}/cxm`, setup)
}

export const SaveCxmSetup = (serverId: number, organization: number, setup: CxmSetup): Promise<{setup: CxmSetup, hasData: boolean}> => {
    return put(`cloud/${serverId}/e2e/${organization}/cxm`, setup)
}

export const DeleteCxmSetup = (serverId: number, organization: number, setupId: string): Promise<null> => {
    return del(`cloud/${serverId}/e2e/${organization}/cxm/${setupId}`)
}

export const UpdateCxmData = (serverId: number, organization: number): Promise<null> => {
    return get(`cloud/${serverId}/e2e/${organization}/cxm/updateData`)
}
