import { useEffect, useState } from "react";
import { DisableReportGroup, EnableReportGroup, GetAllReportTemplateGroups, GetEnabledReportGroups, GetHiddenReportTemplates, UnhideReportTemplate, HideReportTemplate, SetReportTemplatesEnabled, GetHiddenReportTemplateGroups, HideReportTemplateGroup, UnhideReportTemplateGroup, GetEnabledActionGroups, GetAllActionTemplateGroups, EnableActionGroup, DisableActionGroup, SetActionTemplatesEnabled, HideActionTemplate, UnhideActionTemplate, GetHiddenActionTemplateGroups, HideActionTemplateGroup, GetHiddenActionTemplates, UnhideActionTemplateGroup } from "../api/templates.api";
import CustomCheckbox from "../components/Buttons/CustomCheckbox";
import { Table, TableBody, TableHead, TableHeadCell, TableHeadRow } from "../components/Table";
import OrganizationReportTemplateGroup from "../components/Template/OrganizationReportTemplateGroup";
import { useDispatch } from "../contexts/StoreContext";
import { CloudOrgInfo } from "../types/cloud_types";
import { ActionTemplateGroup, ReportTemplateGroup } from "../types/template_types";
import TabBar from "../components/TabBar";
import OrganizationActionTemplateGroup from "../components/Template/OrganizationActionTemplateGroup";

interface OrganizationTemplateAccessProps {
    org: CloudOrgInfo,
}

const OrganizationTemplateAccess = ({org}: OrganizationTemplateAccessProps) => {

    const [tab, setTab] = useState<number>(0)
    const [loading, setLoading] = useState(0)
    const addLoading = () => setLoading(v => v + 1)
    const doneLoading = () => setLoading(v => Math.max(v - 1, 0))
    const dispatch = useDispatch()
    const [reportGroups, setReportGroups] = useState<ReportTemplateGroup[]>([])
    const [enabledReportGroups, setEnabledReportGroups] = useState<Omit<ReportTemplateGroup, "report_templates">[]>([])
    const [hiddenReports, setHiddenReports] = useState<number[]>([])
    const [hiddenReportGroups, setHiddenReportGroups] = useState<number[]>([])
    
    const [actionGroups, setActionGroups] = useState<ActionTemplateGroup[]>([])
    const [enabledActionGroups, setEnabledActionGroups] = useState<Omit<ActionTemplateGroup, "action_templates">[]>([])
    const [hiddenActions, setHiddenActions] = useState<number[]>([])
    const [hiddenActionGroups, setHiddenActionGroups] = useState<number[]>([])
    
    useEffect(() => {
        getGroups()
        getEnabledGroups()
        getHiddenReports()
        getHiddenGroups()
        getHiddenActions()
    }, [])

    const getEnabledGroups = () => {
        if (org.server === undefined) return
        addLoading()
        GetEnabledReportGroups(org.server.id, org.id)
            .then((result) => {
                setEnabledReportGroups(result)
            })
            .catch(err => console.log(err))
            .finally(doneLoading)
        addLoading()
        GetEnabledActionGroups(org.server.id, org.id)
            .then(result => {
                setEnabledActionGroups(result)
            })
            .catch(err => console.log(err))
            .finally(doneLoading)
    }

    const getGroups = () => {
        if (org.server === undefined) return
        addLoading()
        GetAllReportTemplateGroups(org.server.id)
            .then((result) => {
                setReportGroups(result)
            })
            .catch(err => console.log(err))
            .finally(doneLoading)
        addLoading()
        GetAllActionTemplateGroups(org.server.id)
            .then((result) => {
                setActionGroups(result)
            })
            .catch(err => console.log(err))
            .finally(doneLoading)
    }

    const enableGroup = (groupId: number, type: "action" | "report") => {
        if (org.server === undefined) return
        addLoading()
        if(type === "report"){
            EnableReportGroup(org.server.id, org.id, groupId)
                .then(() => {
                    getEnabledGroups()
                })
                .catch(err => console.log(err))
                .finally(doneLoading)
        } else {
            EnableActionGroup(org.server.id, org.id, groupId)
                .then(() => {
                    getEnabledGroups()
                })
                .catch(err => console.log(err))
                .finally(doneLoading)
        }
    }

    const disableGroup = (groupId: number, type: "action" | "report") => {
        if (org.server === undefined) return
        addLoading()
        if(type === "report"){
            DisableReportGroup(org.server.id, org.id, groupId)
                .then(() => {
                    getEnabledGroups()
                })
                .catch(err => console.log(err))
                .finally(doneLoading)
        } else {
            DisableActionGroup(org.server.id, org.id, groupId)
                .then(() => {
                    getEnabledGroups()
                })
                .catch(err => console.log(err))
                .finally(doneLoading)
        }
    }


    const setEnabled = (value: boolean, type: "report" | "action") => {
        if (org === undefined || org.server === undefined) return
        addLoading()
        if(type === "report"){
            SetReportTemplatesEnabled(value, org.server.id, org.id)
                .then((val) => {
                    let tmpOrg = structuredClone(org)
                    tmpOrg.enable_report_templates = val
                    dispatch({type: "change_org", id: tmpOrg.id, org: tmpOrg})
                }).catch((e) => {
                    console.error(e)
                })
                .finally(doneLoading)
        } else {
            SetActionTemplatesEnabled(value, org.server.id, org.id)
                .then((val) => {
                    let tmpOrg = structuredClone(org)
                    tmpOrg.enable_action_templates = val
                    dispatch({type: "change_org", id: tmpOrg.id, org: tmpOrg})
                }).catch((e) => {
                    console.error(e)
                })
                .finally(doneLoading)
        }
    }

    const getHiddenReports = () => {
        if (org.server === undefined) return
        addLoading()
        GetHiddenReportTemplates(org.server.id, org.id)
            .then(values => {
                setHiddenReports(values.map(v => v.id))
            })
            .catch(e => console.log(e))
            .finally(doneLoading)
    }
    const getHiddenActions = () => {
        if (org.server === undefined) return
        addLoading()
        GetHiddenActionTemplates(org.server.id, org.id)
            .then(values => {
                setHiddenActions(values.map(v => v.id))
            })
            .catch(e => console.log(e))
            .finally(doneLoading)
    }

    const hideReport = (reportId: number) => {
        if (org.server === undefined) return
        addLoading()
        HideReportTemplate(org.server.id, org.id, reportId)
            .then(() => {
                getHiddenReports()
            })
            .catch(e => console.log(e))
            .finally(doneLoading)
    }
    const hideAction = (actionId: number) => {
        if (org.server === undefined) return
        addLoading()
        HideActionTemplate(org.server.id, org.id, actionId)
            .then(() => {
                getHiddenActions()
            })
            .catch(e => console.log(e))
            .finally(doneLoading)
    }

    const unhideReport = (reportId: number) => {
        if (org.server === undefined) return
        addLoading()
        UnhideReportTemplate(org.server.id, org.id, reportId)
            .then(() => {
                getHiddenReports()
            })
            .catch(e => console.log(e))
            .finally(doneLoading)
    }
    const unhideAction = (actionId: number) => {
        if (org.server === undefined) return
        addLoading()
        UnhideActionTemplate(org.server.id, org.id, actionId)
            .then(() => {
                getHiddenActions()
            })
            .catch(e => console.log(e))
            .finally(doneLoading)
    }

    const getHiddenGroups = () => {
        if (org.server === undefined) return
        addLoading()
        GetHiddenReportTemplateGroups(org.server.id, org.id)
            .then(values => {
                setHiddenReportGroups(values.map(v => v.id))
            })
            .catch(e => console.log(e))
            .finally(doneLoading)
        addLoading()
        GetHiddenActionTemplateGroups(org.server.id, org.id)
            .then(values => {
                console.log(values);
                setHiddenActionGroups(values.map(v => v.id))
            })
            .catch(e => console.log(e))
            .finally(doneLoading)
    }

    const hideGroup = (groupId: number, type: "report" | "action") => {
        if (org.server === undefined) return
        addLoading()
        if(type === "report"){
            HideReportTemplateGroup(org.server.id, org.id, groupId)
                .then(() => {
                    getHiddenGroups()
                })
                .catch(e => console.log(e))
                .finally(doneLoading)
        } else {
            HideActionTemplateGroup(org.server.id, org.id, groupId)
                .then(() => {
                    getHiddenGroups()
                })
                .catch(e => console.log(e))
                .finally(doneLoading)
        }
    }

    const unhideGroup = (groupId: number, type: "report" | "action") => {
        if (org.server === undefined) return
        addLoading()
        if(type === "report"){
            UnhideReportTemplateGroup(org.server.id, org.id, groupId)
                .then(() => {
                    getHiddenGroups()
                })
                .catch(e => console.log(e))
                .finally(doneLoading)
        } else {
            UnhideActionTemplateGroup(org.server.id, org.id, groupId)
                .then(() => {
                    getHiddenGroups()
                })
                .catch(e => console.log(e))
                .finally(doneLoading)
        }
    }

    return <>
        <div className={`${loading > 0 && 'pointer-events-none animate-pulse'}`}>
            <TabBar className="mb-8" setTab={i => setTab(i)} tabs={["Report templates", "Action templates"]} selected={tab} />
            {tab === 0 && <>
                <CustomCheckbox value={org.enable_report_templates} onChange={(val) => {setEnabled(val, "report")}} text="Enable Report Templates" />
                <div className={`${!org.enable_report_templates && 'pointer-events-none opacity-50'} flex flex-col ml-5 gap-5`}>
                    <Table >
                        <TableHead className="text-gray-700 bg-gray-50">
                            <TableHeadRow>
                                <TableHeadCell className="w-[100px]">Enabled</TableHeadCell>
                                <TableHeadCell>Group</TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {
                                reportGroups.map(g => {
                                    return <OrganizationReportTemplateGroup 
                                        key={g.id}
                                        group={g}
                                        enabled={enabledReportGroups.some(g2 => g.id === g2.id)}
                                        onChange={(value) => value ? enableGroup(g.id, "report") : disableGroup(g.id, "report")}
                                        hideReport={(r, v) => v ? hideReport(r) : unhideReport(r)}
                                        hiddenReports={hiddenReports}
                                        hidden={hiddenReportGroups.some(g2 => g2 === g.id)}
                                        hideGroup={(g, v) => v ? hideGroup(g, "report") : unhideGroup(g, "report")}
                                        
                                    />
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
            </>}
            {tab === 1 && <> 
                <CustomCheckbox value={org.enable_action_templates} onChange={(val) => {setEnabled(val, "action")}} text="Enable Action Templates" />
                <div className={`${!org.enable_action_templates && 'pointer-events-none opacity-50'} flex flex-col ml-5 gap-5`}>
                    <Table >
                        <TableHead className="text-gray-700 bg-gray-50">
                            <TableHeadRow>
                                <TableHeadCell className="w-[100px]">Enabled</TableHeadCell>
                                <TableHeadCell>Group</TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {
                                actionGroups.map(g => {
                                    return <OrganizationActionTemplateGroup
                                        key={g.id}
                                        group={g}
                                        enabled={enabledActionGroups.some(g2 => g.id === g2.id)}
                                        onChange={(value) => value ? enableGroup(g.id, "action") : disableGroup(g.id, "action")}
                                        hideAction={(r, v) => v ? hideAction(r) : unhideAction(r)}
                                        hiddenActions={hiddenActions}
                                        hidden={hiddenActionGroups.some(g2 => g2 === g.id)}
                                        hideGroup={(g, v) => v ? hideGroup(g, "action") : unhideGroup(g, "action")}
                                        
                                    />
                                })
                            }
                        </TableBody>
                    </Table>
                </div>
            </>}
        </div>
    </>
}

export default OrganizationTemplateAccess
