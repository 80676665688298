import { Transition } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { post, put, get } from "../../helpers/Requests"
import { CloudItem, CloudUser } from "../../types/cloud_types"
import { newReportingItem, ReportingItem, Plan } from "../../types/reporting_types"
import Button from "../Button"
import CustomInput from "../CustomInput"
import CustomSwitch from "../CustomSwitch"
import Select from "../Select"
import { ServerData} from "../../types/types"

const AddEmail: React.FC<{
    open: boolean,
    onClose(): any,
    getEmailItems(): any,
    edittedEmailItem: ReportingItem | null,
    cloudItems: CloudItem[],
    route: "dashboard" | "dashtable" | "trendview" | "report",
    users: CloudUser[],
    orgId: number,
    plans: Plan[],
    server: ServerData | undefined
}> = ({ open, onClose, getEmailItems, edittedEmailItem, route, users, orgId, cloudItems, plans, server }) => {
    const { serverId } = useParams()
    const [newEmail, setNewEmail] = useState<ReportingItem>(newReportingItem(orgId));
    const [manual, setManual] = useState(false)

    useEffect(() => {
        if (edittedEmailItem) {
            setNewEmail(edittedEmailItem);
        }
    }, [edittedEmailItem]);

    const setNewEmailState = (key: string, value: any) => {
        setNewEmail({ ...newEmail, [key]: value })
    }

    const selectUser = (email: string) => { // assuming emails are unique
        const u = users.find(u => u.email === email)
        if (!u) return;
        setNewEmail({ ...newEmail, email: u.email, firstname: u.firstname, lastname: u.lastname })
    }

    const addNewEmail = (id?: number) => {
        if (edittedEmailItem) {
            put(`reporting/${serverId}/${route}/${id}`, newEmail).then(res => {
                onCloseLocal();
                getEmailItems();
            }).catch(err => console.log(err))
        } else {
            post(`reporting/${serverId}/${route}`, newEmail).then(res => {
                onCloseLocal();
                getEmailItems();
            }).catch(err => console.log(err))
        }
    }

    const validateSettingsHash = (hash?: string) => {
        if (hash) {
            get(`cloud/${server?.cloud}/reports/validateSettings/${hash}`).catch(err => {
                alert("setting " + hash + " was not found")
            })  
        }
    }

    const onCloseLocal = () => {
        onClose();
        setTimeout(() => {
            setNewEmail(newReportingItem(orgId));
        }, 300) // wait for aninmation to finish
    }

    return <Transition
        as={Fragment}
        show={open}
        enter="transform transition duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95 "
    >
        <div className="fixed right-4 top-[220px] z-40 text-sm rounded-lg shadow-2xl border bg-white border-gray-300">
            <div className="w-[400px]">
                <div className="p-4 border-b border-gray-200">
                    <div className="font-semibold text-base">Add {route} email</div>
                </div>
                <div className="flex flex-col p-4 gap-2">
                    <div>
                        <label className="block">
                            <span className="text-gray-600 text-xs">Active</span>
                            <div className="mt-1"></div>
                            <CustomSwitch enabled={newEmail.active} onChange={bool => setNewEmailState("active", bool)} />
                        </label>
                    </div>
                    {(route === "dashboard" || route === "dashtable") && 
                        <div>
                            <Select onChange={e => setNewEmailState("dashboard_id", parseInt(e.target.value))} value={!newEmail.dashboard_id ? "" : newEmail.dashboard_id} label="Dashboard">
                                <option value="" className="">Select dashboard</option>
                                {cloudItems.map(item => <option key={item.id} value={item.id}>{item.title}</option>)}
                            </Select>
                        </div>
                    }
                    {route === "dashboard" &&
                        <div>
                            <label className="block">
                                <span className="text-gray-600 text-xs">Include table</span>
                                <div className="mt-1"></div>
                                <CustomSwitch enabled={newEmail.include_table} onChange={bool => setNewEmailState("include_table", bool)} />
                            </label>
                        </div>
                     }
                    {(route === "report" || route === "trendview") && <div>
                        <Select onChange={e => setNewEmailState("report_id", parseInt(e.target.value))} value={!newEmail.report_id ? "" : newEmail.report_id} label="Report">
                            <option value="" className="">Select report</option>
                            {cloudItems.map(item => <option key={item.report_id} value={item.report_id}>{item.name}</option>)}
                        </Select>
                    </div>}
                    {route === "trendview" && <div>
                        <CustomInput onChange={e => { validateSettingsHash(e.target.value); setNewEmailState("setting_hash", e.target.value); } } value={newEmail.setting_hash} label="Settings hash" />
                    </div>}
                    {!manual ? <>
                        <div>
                            <div className="flex flex-row justify-between items-center text-xs mb-1">
                                <div className="text-gray-600">Email</div>
                                <div onClick={_ => setManual(true)} className="text-indigo-600 cursor-pointer">Manual?</div>
                            </div>
                            <Select onChange={e => selectUser(e.target.value)} value={newEmail.email} >
                                <option value="" className="">None email</option>
                                {users.map(user => <option key={user.id} value={user.email}>{user.email}</option>)}
                            </Select>
                        </div>
                        <div>
                            <Select label="User" onChange={e => selectUser(e.target.value)} value={newEmail.email} >
                                <option value="" className="">None name</option>
                                {users.map(user => <option key={user.id} value={user.email}>{user.firstname} {user.lastname}</option>)}
                            </Select>
                        </div>
                    </>

                        : <>
                            <div>
                                <div className="flex flex-row justify-between items-center text-xs mb-1">
                                    <div className="text-gray-600">Email</div>
                                    <div onClick={_ => setManual(false)} className="text-indigo-600 cursor-pointer">Select user?</div>
                                </div>
                                <CustomInput onChange={e => setNewEmailState("email", e.target.value)} value={newEmail.email} />
                            </div>
                            <div><CustomInput onChange={e => setNewEmailState("firstname", e.target.value)} value={newEmail.firstname} label="Firstname" /></div>
                            <div><CustomInput onChange={e => setNewEmailState("lastname", e.target.value)} value={newEmail.lastname} label="Lastname" /></div>
                        </>
                    }
                    {/* <div><CustomInput onChange={e => setNewEmailState("email_text", e.target.value)} value={newEmail.email_text} label="Email text" /></div>
                    <div><CustomInput onChange={e => setNewEmailState("email_title", e.target.value)} value={newEmail.email_title} label="Email title" /></div> */}
                    <div>
                        <Select label="Plan" value={!newEmail.plan_id ? "" : newEmail.plan_id} onChange={e => setNewEmailState("plan_id",  parseInt(e.target.value))} >
                        <option value="" className="">Select plan</option>
                        {
                            plans.map((c, i) => <option key={i} value={c.id}>{c.description}</option>)
                        }
                        </Select>
                    </div>
                    <div>
                        <label className="block">
                            <span className="text-gray-600 text-xs">Link</span>
                            <div className="mt-1"></div>
                            <CustomSwitch enabled={newEmail.link} onChange={bool => setNewEmailState("link", bool)} />
                        </label>
                    </div>
                   
                </div>
                <div className="bg-gray-50 border-t border-gray-300 rounded-br-lg rounded-bl-lg">
                    <div className="p-4 flex flex-row-reverse gap-2">
                        {(route === "dashboard" || route === "dashtable") && <>
                            <div><Button disabled={!newEmail.dashboard_id || newEmail.email === ""} text="Save" color="primary" onClick={_ => addNewEmail(edittedEmailItem ? edittedEmailItem.id : undefined)} /></div>
                        </>}
                        {route === "report" && <>
                            <div><Button disabled={!newEmail.org_id || !newEmail.report_id || newEmail.email === ""} text="Save" color="primary" onClick={_ => addNewEmail(edittedEmailItem ? edittedEmailItem.id : undefined)} /></div>
                        </>}
                        {route === "trendview" && <>
                            <div><Button disabled={!newEmail.org_id || !newEmail.report_id || newEmail.email === "" || !newEmail.setting_hash} text="Save" color="primary" onClick={_ => addNewEmail(edittedEmailItem ? edittedEmailItem.id : undefined)} /></div>
                        </>}

                        <div><Button text="Cancel" color="secondary" onClick={onCloseLocal} /></div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
}

export default AddEmail