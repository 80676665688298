import { useEffect, useState, useCallback } from "react"
import { get } from "../../helpers/Requests"
import { CloudError } from "../../types/cloud_types"
import { BsBugFill} from 'react-icons/bs'


const ErrorsCard: React.FC<{ serverId: string, onClick(): any }> = ({ serverId, onClick }) => {
    const [errors, setErrors] = useState<CloudError[]>([])
    const getErrors = useCallback(() => {
        Promise.all([
            get("cloud/" + serverId + "/errors"),
        ]).then(([errors]) => {
            setErrors(errors)
        }).catch(err => console.log(err))
    }, [serverId])

    useEffect(() => {
        getErrors()
    }, [getErrors])

   
    return <>{ 
        errors.length > 0 ?
        <div className="bg-neutral-100 h-full">
            <div className="p-8 bg-white border-b border-gray-200 cursor-pointer" onClick={onClick}>
                <BsBugFill />
            </div>
        </div >
        : null
    }</>
    
    
}

export default ErrorsCard