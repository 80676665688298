import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { BsChevronBarExpand } from "react-icons/bs";
import { HiCheck } from "react-icons/hi";
import { SelectOption } from "../types/template_types";
import CustomInput from "./CustomInput";

interface SearchableSelectProps<T> {
    options: SelectOption<T>[],
    value: T,
    onChange: (value: T) => void,
    className?: string
    direction?: "up" | "down",
    disabled?: boolean,
    placeholder?: string
}

const SearchableSelect = <T,>({value, onChange, className = "", direction = "down", disabled = false, placeholder = "Select value", ...props}: SearchableSelectProps<T>) => {

    const [searchString, setSearchString] = useState("")
    const chosenOption = props.options.find(x => x.value === value)
    return <Listbox value={value} onChange={onChange} disabled={disabled}>
        <div className={`relative mt-1 ${className}`}>
            <Listbox.Button className={`${disabled ? "opacity-50" : ""} relative w-full border border-gray-300 cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-orange-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm`}>
                <span className={`block truncate ${chosenOption === undefined ? "text-gray-400" : ""}`}>{chosenOption?.label ?? placeholder}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <BsChevronBarExpand
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </span>
            </Listbox.Button>
            <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <Listbox.Options className={`z-50 absolute ${direction === "up" ? "bottom-0" : ""} mt-1 w-full  rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}>
                    <div className="p-2 border-b-gray-200 border-b">
                        <CustomInput label="Search" type={"text"} value={searchString} onChange={e => setSearchString(e.target.value ?? "")} />
                    </div>
                    <div className="flex flex-col overflow-y-scroll max-h-[300px] scroll-shadows">
                        {props.options.filter(o => o.label.toLowerCase().includes(searchString.toLowerCase())).map((o, i) => (
                            <Listbox.Option
                                key={i}
                                className={({ active }) =>
                                    `relative cursor-default flex items-center gap-2 select-none py-2 pl-10 pr-4 ${active ? 'bg-orange-100 text-black' : 'text-gray-900'}`
                                }
                                value={o.value}
                            >
                                {({ selected }) => (
                                    <>
                                        <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                        >
                                            {o.label}
                                        </span>
                                        {o.icon}
                                        {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-orange-600">
                                                <HiCheck className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </div>
                </Listbox.Options>
            </Transition>
        </div>
    </Listbox>
}

export default SearchableSelect
