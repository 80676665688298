import React from "react";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import SortableList from "../SortableList";
import { arrayMove } from "@dnd-kit/sortable";
import { DragEndEvent } from "@dnd-kit/core";

function TemplateColumnShowHide<T extends {columns: string[], shown_columns: string[]}>({tmpReport, setTmpReport}: {tmpReport: T, setTmpReport: React.Dispatch<React.SetStateAction<T | undefined>>}) {

    const hideColumn = (column: string) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.shown_columns = tmp.shown_columns.filter(v => v !== column)
        setTmpReport(tmp)
    }

    const showColumn = (column: string) => {
        if (tmpReport === undefined) return
        let tmp = structuredClone(tmpReport)
        tmp.shown_columns.push(column)
        setTmpReport(tmp)
    }

    const moveColumn = (oldIndex: number, newIndex: number) => {
        if (tmpReport === undefined) return
        setTmpReport({...tmpReport, shown_columns: arrayMove(tmpReport.shown_columns, oldIndex, newIndex)})
    }

    const onDragEnd = (e: DragEndEvent) => {
        const {active, over} = e
        if(active.id !== over?.id && !!over) {
            const oldIndex = tmpReport.shown_columns.findIndex(c => active.id === c)
            const newIndex = tmpReport.shown_columns.findIndex(c => over.id === c)
            moveColumn(oldIndex, newIndex)
        }
    }

    return <div className='flex gap-3'>
        <div className='flex flex-col'>
            <span className='text-gray-600 text-xs'>Hidden columns</span>
            <div className='flex flex-col rounded bg-white p-5 w-[400px] h-[350px] overflow-y-auto border'>
                {tmpReport.columns.filter(c => !tmpReport.shown_columns.some(x => c === x)).map((c, i) => {
                    return <div key={i} className='flex justify-between border p-1 text-sm items-center'>
                        <span>{c}</span>
                        <HiOutlineEye onClick={() => showColumn(c)} className='w-5 h-5 stroke-gray-400 hover:stroke-black cursor-pointer' title='Show column' />
                    </div>
                })}
            </div>
        </div>
        <div className='flex flex-col'>
            <span className='text-gray-600 text-xs'>Shown columns</span>
            <div className='flex flex-col rounded bg-white p-5 w-[400px] h-[350px] overflow-y-auto border'>
                <SortableList
                    items={tmpReport.shown_columns.map(c => ({
                        id: c,
                        children: (<>
                            <div className='flex items-center gap-2 mr-auto'>
                                <span>{c}</span>
                            </div>
                            <HiOutlineEyeOff onClick={() => hideColumn(c)} className='w-5 h-5 stroke-gray-400 hover:stroke-black cursor-pointer' title='Hide column' />
                        </>) 
                    }))}
                    onDragEnd={onDragEnd}
                    dragHandle={true}
                    itemClassName="flex gap-2 border p-1 text-sm items-center bg-white"
                />
            </div>
        </div>
    </div>
}

export default TemplateColumnShowHide
