import React from "react";
import { ReactNode, useCallback, useRef, useState } from "react";
import { WarningModal } from "../components/Modals";

interface DialogProps {
    title?: string;
    confirmText?: string;
    declineText?: string;
}

const useConfirmDialog = () => {
    const [show, setShow] = useState(false);
    const [text, setText] = useState<ReactNode>();
    const resolve = useRef<(value: boolean | PromiseLike<boolean>) => void>();

    const [tmpSettings, setTmpSettings] = useState<DialogProps>()

    const onAccept = () => {
        setShow(false);
        setTmpSettings(undefined)
        if (resolve.current != undefined) {
            resolve.current(true);
            resolve.current = undefined;
        }
    };

    const onDecline = () => {
        setShow(false);
        setTmpSettings(undefined)
        if (resolve.current != undefined) {
            resolve.current(false);
            resolve.current = undefined;
        }
    };

    const confirm = useCallback((message: ReactNode, settings?: DialogProps): Promise<boolean> => {
        return new Promise((newResolve) => {
            resolve.current = newResolve;
            setText(message);
            setShow(true);
            setTmpSettings(settings)
        });
    }, []);

    const ConfirmDialog = ({
        title = "Confirm",
        confirmText = "Confirm",
        declineText = "Decline",
        ...props
    }: DialogProps) => {
        return (
            <WarningModal
                isOpen={show}
                title={tmpSettings?.title ?? title}
                actionText={tmpSettings?.confirmText ?? confirmText}
                closeText={tmpSettings?.declineText ?? declineText}
                onClose={onDecline}
                onAction={onAccept}
            >
                {text}
            </WarningModal>
        );
    };

    return { confirm, ConfirmDialog };
};

export default useConfirmDialog
