import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import Login from "./views/Login"
import SideBar, { SideBarMenuItem } from "./components/Sidebar/SideBar"
import Frontpage from "./views/Frontpage"
import Organizations from "./views/Organizations"
import Organization from './views/Organization'
import { HiOutlineMail, HiOutlinePuzzle } from 'react-icons/hi'
import { BsCloud } from 'react-icons/bs'
import { MdDashboard } from 'react-icons/md'
import { RiOrganizationChart } from 'react-icons/ri'
import { VscServerProcess } from 'react-icons/vsc'
import { createContext, useContext, useEffect, useState } from 'react'
import Dashboard from './views/Dashboard'
import Reporting from './views/Reporting'
import ReportingServers from './views/ReportingServers'
import Servers from './views/Servers'
import Server from './views/Server'
import AppHeader from './components/AppHeader'
import ReportingOrganization from './views/ReportingOrganization'
import APA from './views/APA'
import APAServers from './views/APAServers'
import APAAccount from './views/APAAccount'
import CloudBuckets from './views/CloudBuckets'
import CloudServers from './views/CloudServers'
import CloudErrors from './views/CloudErrors'
import { ServerData, User } from './types/types'
import UserView from './views/User'
import { SideBarMenuContext } from './components/Sidebar/SideBarContext'
import TemplateSetup from './views/TemplateSetup'
import TemplateBucketOverview from './views/TemplateBucketOverview'
import TemplateCloudOverview from './views/TemplateCloudOverview'
import TemplateBucketEdit from './views/TemplateBucketEdit'
import TemplateReportOverview from './views/TemplateReportOverview'
import TemplateReportEdit from './views/TemplateReportEdit'
import OrganizationsOverview from './views/OrganizationsOverview'
import { get } from './helpers/Requests'
import { OrganizationBucketSetupEditorWrapper } from './views/OrganizationBucketSetupEditor'
import { StoreProvider } from './contexts/StoreContext'
import TemplateActionEdit from './views/TemplateActionEdit'
import TemplateActionOverview from './views/TemplateActionOverview'
import ActionErrors from './views/ActionErrors'

export type AppStore = {
	user: User | null,
	servers: ServerData[]
}

export const AppContext = createContext<AppStore>({
	user: null,
	servers: [] as ServerData[],
})

export const useAppContext = () => useContext(AppContext)

const App = () => {
	const [items, setItems] = useState<SideBarMenuItem[]>([])
	const [up, setUp] = useState<string | null>(null)
	const [user, setUser] = useState<User | null>(null)
	const [servers, setServers] = useState<ServerData[]>([])


	const getData = () => {
		get("servers")
			.then(ss => {
				setServers(ss);
			})
			.catch(err => console.log(err));
	}


	useEffect(() => {
		setItems([
			{
				icon: <MdDashboard size="1.4em" />,
				text: "Home",
				link: ""
			},
			{
				icon: <RiOrganizationChart size="1.4em" />,
				text: "Organizations",
				link: "organizations"
			},
			{
				icon: <VscServerProcess size="1.4em" />,
				text: "APA",
				link: "apa"
			},
			{
				icon: <HiOutlineMail size="1.4em" />,
				text: "Reporting",
				link: "reporting"
			},
			{
				icon: <BsCloud size="1.4em" />,
				text: "Cloud",
				link: "cloud"
			},
            {
                icon: <HiOutlinePuzzle className='w-5 h-5' />,
                text: "Templates",
                link: "templates"
            }
		])



	}, [])

	useEffect(() => {
		if (!!user && servers.length === 0) {
			getData()
		}
	}, [user])

	return (
		<Router>
			<AppContext.Provider value={{ user, servers }}>
				<SideBarMenuContext.Provider value={{
					items: items,
					setItems: setItems,
					up: up,
					setUp: setUp,
				}}>
					<div className="flex flex-row min-h-screen bg-gray-100">
						<Login setUser={u => setUser(u)}>
							<SideBar />
							<div className="flex-1  ml-[5rem] max-h-screen max-w-[calc(100vw-5rem)]">
								<div className='flex flex-col h-full '>
									<AppHeader />
									{servers.length > 0 ?
										<StoreProvider data={{ servers }}>
											<div className='h-full overflow-y-auto p-2'>
												<Routes>
													<Route path="servers" element={<Servers />} />
													<Route path="servers/:id" element={<Server />} />
													<Route path="organizations" element={<Organizations />}>
														<Route path="" element={<OrganizationsOverview />} />
                                                        <Route path=":cloudId/:orgId/*" element={<Organization servers={servers} />} />
													</Route>
													<Route path="reporting" element={<ReportingServers />} />
													<Route path="reporting/:serverId/:orgId" element={<ReportingOrganization />} />
													<Route path="reporting/:serverId" element={<Reporting />} />
													<Route path="cloud" element={<CloudServers />} />
													<Route path="cloud/:serverId" element={<CloudBuckets />} />
													<Route path="cloud/:serverId/errors" element={<CloudErrors />} />
													<Route path="cloud/:serverId/actionerrors" element={<ActionErrors />} />
													<Route path="test" element={<Frontpage />} />
													<Route path="apa" element={<APAServers tab={0} />} />
													<Route path="apa/errors" element={<APAServers tab={2} />} />
													<Route path="apa/:serverId" element={<APA />} />
													<Route path="apa/:serverId/:accountID" element={<APAAccount />} />
													<Route path="user" element={<UserView />} />
                                                    <Route path="bucket/:cloudId/:orgId/:bucketId" element={<OrganizationBucketSetupEditorWrapper />} />
													<Route path="templates/:cloudId/buckets/:bucketId" element={<TemplateBucketEdit />} />
													<Route path="templates/:cloudId/buckets" element={<TemplateBucketOverview />} />
													<Route path="templates/:cloudId/reports/:reportId" element={<TemplateReportEdit />} />
													<Route path="templates/:cloudId/reports" element={<TemplateReportOverview />} />
													<Route path="templates/:cloudId/actions/:actionId" element={<TemplateActionEdit />} />
													<Route path="templates/:cloudId/actions" element={<TemplateActionOverview />} />
													<Route path="templates/:cloudId" element={<TemplateCloudOverview />} />
													<Route path="templates" element={<TemplateSetup />} />
													<Route path="/" element={<Dashboard />} />
												</Routes>
											</div>
										</StoreProvider>
										: <p>Loading...</p>
									}
								</div>
							</div>
						</Login>
					</div>
				</SideBarMenuContext.Provider>
			</AppContext.Provider>
		</Router>
	)
}

export default App
