import moment from "moment"
import { useMemo, useState } from "react"
import { HiArrowSmDown, HiArrowSmUp, HiExclamation } from "react-icons/hi"
import { CloudTrackedBucket } from "../../types/cloud_types"
import { Table, TableHead, TableHeadRow, TableHeadCell, TableBody, TableBodyRow, TableBodyCell } from "../Table"
import { WarningModal } from "../Modals"

const BucketsTable: React.FC<{
    buckets: CloudTrackedBucket[],
    action: (bucket: CloudTrackedBucket) => void,
    buttonText: string,
    isTracked?: boolean,
    onRowClick?: (bucket: CloudTrackedBucket) => void
}> = ({ buckets, action, buttonText, isTracked, onRowClick }) => {
    const [open, setOpen] = useState(false)
    const [bucket, setBucket] = useState<CloudTrackedBucket>({} as any)
    const [sortedProp, setSortedProp] = useState("last_data_time");
    const [direction, setDirection] = useState(false); // false = asc, true = desc

    const sortedBuckets = useMemo(() => buckets.slice().sort((a, b) => {
        if (typeof a[sortedProp] === "string") {
            const x = a[sortedProp].toString().toLowerCase();
            const y = b[sortedProp].toString().toLowerCase();
            if (!direction) {
                return x > y ? 1 : x < y ? -1 : 0
            } else {
                return x < y ? 1 : x > y ? -1 : 0;
            }
        } else {
            if (!direction) {
                return a[sortedProp] > b[sortedProp] ? 1 : a[sortedProp] < b[sortedProp] ? -1 : 0
            } else {
                return a[sortedProp] < b[sortedProp] ? 1 : a[sortedProp] > b[sortedProp] ? -1 : 0

            }
        }
    }), [buckets, sortedProp, direction]);

    const setSorting = (prop: string) => {
        if (sortedProp === prop && direction) {
            setSorting("")
        } else if (sortedProp === prop) {
            setDirection(!direction)
        } else {
            setSortedProp(prop)
            setDirection(false)
        }
    }

    const handleOpen = (bucket: CloudTrackedBucket): (e: any) => void => {
        return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.stopPropagation()
            if (isTracked) {
                setBucket(bucket)
                setOpen(true)
            } else {
                action(bucket)
            }
        }
    }

    const handleAction = (bucket: CloudTrackedBucket) => {
        return (e: any) => {
            action(bucket)
            setOpen(false)
        }
    }

    const onRowClickHandler = (bucket: CloudTrackedBucket) => {
        if (onRowClick !== undefined) onRowClick(bucket)
    }

    const isClickable = onRowClick !== undefined

    return <>
        <Table>
            <TableHead className="rounded-table bg-slate-200">
                <TableHeadRow className="text-gray-600">
                    {[{ header: "Organization", propname: "organization_name" }, { header: "Name", propname: "name" }, { header: "ID", propname: "id" }, { header: "Last data time", propname: "last_data_time" }]
                        .map((h, i) => <TableHeadCell key={i} onClick={() => setSorting(h.propname)} className="select-none cursor-pointer hover:text-gray-900">
                            <div className="flex flex-row items-center">
                                <div className="mr-1">{h.header}</div>
                                <div className="w-5 h-5">
                                    {h.propname === sortedProp && direction && <HiArrowSmUp className="h-5 w-5 text-gray-700" />}
                                    {h.propname === sortedProp && !direction && <HiArrowSmDown className="h-5 w-5 text-gray-700" />}
                                </div>
                            </div>
                        </TableHeadCell>)}

                    <TableHeadCell className="select-none text-right w-[75px]">Action</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody className="rounded-table text-sm">
                {sortedBuckets.map((b, i) => {
                    const dataTimeWarning = moment.unix(b.last_data_time).isBefore(moment().subtract(1, 'd'))
                    return <TableBodyRow className={isClickable ? 'cursor-pointer' : ''} key={i} onClick={() =>  onRowClickHandler(b)} useHover={isClickable}>
                        <TableBodyCell>{b.organization_name}</TableBodyCell>
                        <TableBodyCell>{b.name}</TableBodyCell>
                        <TableBodyCell>{b.id}</TableBodyCell>
                        <TableBodyCell className={`${dataTimeWarning ? "text-red-600" : ""}`}>
                            <div className="flex flex-row gap-4 items-center" title={moment.unix(b.last_data_time).format('LLLL')}>
                                <div>
                                    <span className="">
                                        {b.last_data_time ? moment.unix(b.last_data_time).fromNow() : "N/A"}
                                    </span>
                                </div>
                                {dataTimeWarning && <div className="flex flex-row border border-red-600 rounded-full">
                                    <div className="text-xs  text-red-600 py-0.5 pl-2 rounded-full">OBS</div>
                                    <div className="px-1"><HiExclamation className="text-red-600 w-5 h-5" /></div>
                                </div>}
                            </div>

                        </TableBodyCell>
                        <TableBodyCell className="text-right" >
                            <div onClick={handleOpen(b)} className="text-indigo-700 font-medium text-sm cursor-pointer hover:text-indigo-900">{buttonText}</div>
                        </TableBodyCell>
                    </TableBodyRow>
                })}
            </TableBody>
        </Table>
        <WarningModal isOpen={open} onClose={() => setOpen(false)} onAction={handleAction(bucket)} title="Untrack" actionText="Untrack" closeText="Cancel" >
            <div className="mt-2">Are you sure you want to untrack bucket '{bucket.name}'?</div>
        </WarningModal>
    </>
}

export default BucketsTable
