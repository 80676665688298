import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ServerCard from "./ServerCard"
import {  ServerData } from "../../types/types"
import { CloudOrgWithReporting, Dashboard, DashboardTable, Report} from "../../types/reporting_types";
import OrganizationListItem from "../Reporting/OrganizationListItem";
import { get } from "../../helpers/Requests"

const ServerAndReportingCard: React.FC<{ server: ServerData}> = ({ server }) => {
    const [cloudOrganizations, setCloudOrganizations] = useState<CloudOrgWithReporting[]>([]);
   
    const navigate = useNavigate()


 useEffect(() => {
               Promise.all([get("reporting/" + server.id + "/dashboard"), get("reporting/" + server.id + "/dashtable"), get("reporting/" + server.id + "/trendview"), get("reporting/" + server.id + "/report"), get("cloud/" + server?.cloud + "/organizations")])
            .then(([dashboards, dashboardtables, trendviews, reports, cloudOrgs]) => {
                const sorted: CloudOrgWithReporting[] = cloudOrgs.filter((x:CloudOrgWithReporting) => !x.deleted).sort((a: CloudOrgWithReporting, b: CloudOrgWithReporting) => a.name.toLocaleLowerCase().localeCompare(b.name))
                sorted.forEach(cd => {
                    cd.dashboards = dashboards.filter((d: Dashboard) => cd.id === d.org_id)
                    cd.reports = reports.filter((r: Report) => cd.id === r.org_id)
                    cd.dashboardtables = dashboardtables.filter((d: DashboardTable) => cd.id === d.org_id)
                    cd.trendviews = trendviews.filter((d: DashboardTable) => cd.id === d.org_id)
                })

                setCloudOrganizations(sorted);
            }).catch(err => console.log(err))
 }, [server]);

    return <div>
    {
            <><ServerCard key={server.id} server={server} onClick={() => navigate(`/reporting/${server.id}`)} />

             {cloudOrganizations.map((org, id) => <OrganizationListItem key={id} org={org} onClick={() => navigate(`/reporting/${server.id}/${org.id}`)} />)}

            
            </>
    }
    </div>

}

export default ServerAndReportingCard