import { ReactNode, useEffect, useState } from "react";
import { MdClose, MdOutlineExpandLess, MdOutlineExpandMore } from "react-icons/md";
import { useOutsideClick } from "../helpers/useOutsideClick";

interface MultiSelectProps{
    onOptionSelect : (option:SelectOption) => void;
    onOptionDeselect : (option:SelectOption) => void;
    options : SelectOption[];
    selectedOptions : SelectOption[];
    placeholder: string;
}

interface SelectOption {
    value: any;
    label: string;
    icon?: ReactNode;
}



function MultiSelect(props:MultiSelectProps){
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = useState<SelectOption[]>(props.options.sort((a,b) => a.label.localeCompare(b.label)))
    const [searchText, setSearchText] = useState<string>("");
    const ref = useOutsideClick(() => setShowOptions(false))

    useEffect(() => {
        setFilteredOptions(props.options.filter(o => o.label.toLowerCase().includes(searchText.toLowerCase())).sort((a,b) => a.label.localeCompare(b.label)))
    }, [searchText])

    const isSelected = (o:SelectOption) => {
        return props.selectedOptions.find(s => s.value === o.value) ?? false
    }

    return (
        <div className="relative" ref={ref}>
            <div className={`${showOptions ? "border-t border-x rounded-t-md" : "border rounded-md cursor-pointer"} flex justify-between items-center text-sm w-full mt-1 border-gray-300 shadow-sm h-9`}>
                <input 
                    type="text" 
                    name="search" 
                    placeholder={props.placeholder} 
                    className="p-2 m-0 w-full outline-none border-none rounded-md focus:outline-none focus:ring-transparent flex justify-between text-sm h-full" 
                    onChange={e => setSearchText(e.target.value)} 
                    onFocus={() => setShowOptions(true)}
                    value={searchText}
                />
                <div 
                    className={`${showOptions ? "rounded-tr-md" : "rounded-r-md"} hover:bg-gray-100 p-2 cursor-pointer h-full`}
                    onClick={() => setShowOptions(!showOptions)}
                >
                    {!showOptions && <MdOutlineExpandMore/>}
                    {showOptions && <MdOutlineExpandLess/>} 
                </div>
            </div>
            {showOptions && <div className="border-x border-b border-gray-300 overflow-y-auto absolute bg-white w-full z-50 max-h-96 rounded-b">
                {filteredOptions.map(o => {
                    return (
                        <div 
                            key={o.value}
                            onClick={() => { isSelected(o) ? props.onOptionDeselect(o) : props.onOptionSelect(o)}}
                            className={`${isSelected(o)? "bg-dark-forest-800 text-white" : "bg-white"} hover:bg-dark-forest-300 p-1.5 z-50 border-t border-gray-50 flex items-center justify-between  cursor-pointer  focus:bg-gray-300`}
                        >
                            <span className="flex items-center gap-1">
                                <span>{o.label}</span>
                                {o.icon}
                            </span>
                            {isSelected(o) && <MdClose className="text-white" />}
                        </div>
                    )
                })}
            </div>}
        </div>
    );
}

export default MultiSelect;