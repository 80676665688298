import React from 'react'
import { HiOutlineDatabase } from 'react-icons/hi'

export interface BucketTemplateCardProps {
    name: string,
    onClick?: () => void,
    style?: React.CSSProperties,
    className?: string,
}

const BucketTemplateCard = ({style = {}, className = '', ...props}: BucketTemplateCardProps) => {

    return <div style={style} 
                className={`${className} flex flex-col items-center justify-center bg-white rounded-md shadow-md p-2 w-[100px] h-[100px]`} 
                onClick={() => {if(props.onClick !== undefined) props.onClick()}}>
        <HiOutlineDatabase className='w-[24px] h-[24px]' />
        <span title={props.name} className='truncate max-w-full'>{props.name}</span>
    </div>
}

export default BucketTemplateCard
