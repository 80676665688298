import { HiChevronRight } from "react-icons/hi"
import { MdOutgoingMail } from "react-icons/md";
import { CloudOrgWithReporting } from "../../types/reporting_types"

const OrganizationListItem: React.FC<{ org: CloudOrgWithReporting, onClick(): any }> = ({ org, onClick }) => {
    return <div onClick={onClick} className="flex flex-row justify-between items-center cursor-pointer first:rounded-t-md last:rounded-b-md hover:bg-neutral-50 py-3 px-6 last:border-0 border-b border-gray-300">
        <div className="flex flex-col gap-1 text-sm">
            <div className="font-semibold text-base text-indigo-500">{org.name}</div>
            <div className="flex flex-row gap-4 items-center">
                <div><MdOutgoingMail className="w-5 h-5 text-gray-400" /></div>
                <div className="font-semibold text-gray-600">{org.dashboards.length + org.dashboardtables.length + org.trendviews.length + org.reports.length}</div>
            </div>
        </div>
        <div className="flex flex-row-reverse items-center gap-8">
            <div><HiChevronRight className="h-5 w-5 text-gray-400" /></div>
            <div className="">
                <div className="flex flex-row items-center text-gray-500 gap-4 w-28">
                    <div className="font-semibold text-blue-500 border-r border-gray-300 pr-4">{org.dashboards.length}</div>
                    <div className="font-semibold text-yellow-500 border-r border-gray-300 pr-4">{org.dashboardtables.length}</div>
                    <div className="font-semibold text-red-500 border-r border-gray-300 pr-4">{org.trendviews.length}</div>
                    <div className="font-semibold text-green-500">{org.reports.length}</div>
                </div>
            </div>
        </div>
    </div>
}

export default OrganizationListItem