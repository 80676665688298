import { RadioGroup } from '@headlessui/react'
import React from 'react'

import '../../css/CustomRadioGroup.css'

interface CustomRadioOptionProps {
    value: any
    text: string
    children?: React.ReactNode //Will show if option is selected
    style?: React.CSSProperties
    title?: string
    disabled?: boolean
}

export default function CustomRadioOption({value, text, children, style, title, disabled}: CustomRadioOptionProps){

    return (
        <RadioGroup.Option value={value} disabled={disabled}>
            {
                ({active, checked}) => (
                    <div style={style} className={`radio-option ${disabled ? 'radio-option-disabled' : ''}`} title={title}>
                        <div className='radio-option-content'>
                            <div className={`radio-option-circle ${checked ? 'radio-option-circle-checked' : ''}`} >
                                <div />
                            </div>
                            <h2>{text}</h2>
                        </div>
                        {children ? 
                            <div className={`radio-drawer ${checked ? 'radio-drawer-open' : ''}`}>
                                <div style={{marginTop: 10}} />
                                {children}
                            </div>
                        : null}
                    </div>
                )
            }
        </RadioGroup.Option>
    )
}