import React from "react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { HiOutlineMinus } from "react-icons/hi";
import { AggregationType, BucketTemplate, getPossibleAggTypes, ReportTemplate } from "../../types/template_types";
import Button from "../Button";
import Select from "../Select";
import CustomCheckbox from "../Buttons/CustomCheckbox";
import ShowIf from "../ShowIf";
import { GoAlert } from "react-icons/go";

interface ReportTemplateKpiConfigProps {
    tmpReport: ReportTemplate,
    setTmpReport: React.Dispatch<React.SetStateAction<ReportTemplate | undefined>>,
    bucket: BucketTemplate,
}

const ReportTemplateKpiConfig = ({tmpReport, setTmpReport, bucket, ...props}: ReportTemplateKpiConfigProps) => {

    const makeSureRowsHave3Elements = (tmp: ReportTemplate) => {
        while (tmp.kpi_config.length % 3 !== 0) {
            tmp.kpi_config.push({shown: false})
        }
    }
    const addRow = () => {
        let tmp = structuredClone(tmpReport)
        for (let i = 0; i < 3; i++) {
            tmp.kpi_config.push({shown: false})
        }
        makeSureRowsHave3Elements(tmp)
        setTmpReport(tmp)
    }

    const removeRow = () => {
        let tmp = structuredClone(tmpReport)
        tmp.kpi_config.splice(-3)
        makeSureRowsHave3Elements(tmp)
        setTmpReport(tmp)
    }

    const onKpiRemove = (index: number) => {
        if (tmpReport.kpi_config.length <= index) return
        let tmp = structuredClone(tmpReport)
        tmp.kpi_config[index] = {shown: false}
        setTmpReport(tmp)
    }

    const onKpiColumnChange = (index: number, column: string) => {
        let tmp = structuredClone(tmpReport)
        const oldCol = bucket.columns.find(x => x.name === tmp.kpi_config[index].column)
        const newCol = bucket.columns.find(x => x.name === column)
        tmp.kpi_config[index].column = column
        if (oldCol?.type !== newCol?.type) {
            tmp.kpi_config[index].aggregation = getPossibleAggTypes(column, bucket)[0].value
        }
        setTmpReport(tmp)
    }

    const onKpiAggregationChange = (index: number, aggtype: AggregationType) => {
        let tmp = structuredClone(tmpReport)
        tmp.kpi_config[index].aggregation = aggtype
        setTmpReport(tmp)
    }

    const onKpiDecimalChange = (index: number, checked: boolean) => {
        let tmp = structuredClone(tmpReport)
        tmp.kpi_config[index].showDecimals = checked
        setTmpReport(tmp)
    }

    const onKpiPercentChange = (index: number, checked: boolean) => {
        let tmp = structuredClone(tmpReport)
        tmp.kpi_config[index].showPercentages = checked
        setTmpReport(tmp)
    }

    const addKpi = (index: number) => {
        let tmp = structuredClone(tmpReport)
        const col = tmpReport.columns.at(0)
        if (col === undefined) return
        tmp.kpi_config[index] = {shown: true, showPercentages: false, showDecimals: false, column: tmpReport.columns.at(0), aggregation: getPossibleAggTypes(col, bucket)[0].value}
        setTmpReport(tmp)
    }

    const enableKpiView = () => {
        let tmp = structuredClone(tmpReport)
        tmp.view_settings.settings.kpis = true
        setTmpReport(tmp)
    }


    return <>
        <ShowIf if={!tmpReport.view_settings.settings.kpis}>
            <div className="p-4 bg-almondine-mist-500 w-fit border border-almondine-mist-900 flex items-center gap-4 rounded">
                <GoAlert />
                <span>
                This view has not been enabled in the View setup - <Button color="default" className="text-button" onClick={enableKpiView} text="Enable" />
                </span>
            </div>
        </ShowIf>
        <div className="grid grid-cols-3 gap-4 w-fit">
            {
                tmpReport.kpi_config.map((k, i) => {
                    return <div key={i} className="w-[285px] h-[150px] bg-almondine-mist-500 flex flex-col justify-center items-center p-2 relative">
                        {
                            (k.shown && k.column !== undefined && k.aggregation !== undefined && k.showDecimals !== undefined && k.showPercentages !== undefined) ? <>

                                <HiOutlineMinus onClick={() => onKpiRemove(i)} title="Remove KPI" className="w-5 h-5 stroke-gray-400 hover:stroke-red-500 cursor-pointer absolute top-2 right-2" />
                                <Select className="w-full" value={k.column} onChange={(e) => onKpiColumnChange(i, e.target.value)}>
                                    {
                                        tmpReport.columns.map((x, i) => <option key={i} value={x}>{x}</option>)
                                    }
                                </Select>
                                <div className="flex w-full">
                                    <Select className="grow w-full" value={k.aggregation} onChange={(e) => onKpiAggregationChange(i, e.target.value as AggregationType)}>
                                        {
                                            getPossibleAggTypes(k.column, bucket).map((x, i) => <option key={i} value={x.value}>{x.label}</option>)
                                        }
                                    </Select>
                                    <CustomCheckbox text="0.0" value={k.showDecimals} onChange={(val) => onKpiDecimalChange(i, val)} />
                                    <CustomCheckbox text="%" value={k.showPercentages} onChange={(val) => onKpiPercentChange(i, val)} />
                                </div>
                            </>
                            :
                                <>
                                    <Button color="primary" text="Add KPI" onClick={() => addKpi(i)} />
                                </>
                        }
                    </div>
                })
            }
        </div>
        <div className="flex gap-2">
            <Button onClick={addRow} className="w-fit" color="primary" text="Add row" icon={<FaPlus />} />
            <Button disabled={tmpReport.kpi_config.length <= 0} onClick={removeRow} className="w-fit" color="alert" text="Remove row" icon={<FaMinus />} />
        </div>
    </>
}

export default ReportTemplateKpiConfig
