import { Transition } from "@headlessui/react"
import { Fragment, useCallback, useRef, useState } from "react"
import {  postForm, putForm } from "../../helpers/Requests"
import { APASetup } from "../../types/types"
import Button from "../Button"
import CustomInput from "../CustomInput"
import Select from "../Select"

const AddSetup: React.FC<{
    serverId: string,
    open: boolean,
    onClose(): any,
    account: number,
    setups: APASetup[]
}> = ({ serverId, open, onClose, account, setups }) => {

    const [description, setDescription] = useState<string>("")
    const [updateForcer, setUpdateForcer] = useState<boolean>(false)
    const [selectedSetup, setSelectedSetup] = useState<APASetup | null>(null)

    const onCloseLocal = useCallback(() => {
        onClose();
        setDescription('')
        setTimeout((_: any) => {
        }, 300) // wait for aninmation to finish
    }, [onClose])

    const fileRef = useRef<HTMLInputElement | null>()

    const hasFiles = (): boolean => {
        if (!fileRef.current) return false
        const setupFileInput = fileRef.current
        if (!setupFileInput.files || setupFileInput.files.length === 0) return false
        return true
    }

    function onFileChange() {
        const regex = /(?<name>.*)\..+/gm;

        setUpdateForcer(!updateForcer)
        if (!fileRef.current) return
        if(!fileRef.current.files || fileRef.current.files.length === 0) return

        let file = fileRef.current.files[0]
        let m = regex.exec(file.name)
        if(m?.groups?.["name"]){
            setDescription(m?.groups?.["name"])
        }
    }

    const doSetSelectedSetup = (id: number) => {
        if(id === -1){
            setSelectedSetup(null)
            return
        }
        const s = setups.find(s => s.id === id)
        if (!s) return;
        setSelectedSetup(s)
    }

    const doCreateSetup = useCallback(() => {
        if (!fileRef.current) return
        const setupFileInput = fileRef.current
        if (!setupFileInput.files || setupFileInput.files.length === 0) return
        const file = setupFileInput.files[0]
        const formData = new FormData()
        formData.append("setup_file", file)
        formData.append("description", description)
        if(selectedSetup != null){
            if (window.confirm("Are you sure you want to overwrite this setup? (This will keep associated schedules, runs and logs.)")) {
                formData.append("setup", String(selectedSetup.id))
                putForm(`apa/${serverId}/setups/account/${account}`, formData)
                    .then(res => {
                        onCloseLocal()
                    }).catch(err => alert(err))
            }else{
                return
            }
        }else{
            postForm(`apa/${serverId}/setups/account/${account}`, formData)
                .then(res => {
                    onCloseLocal()
                })
                .catch(err => alert(err))
        }
    }, [description, fileRef, selectedSetup, account, onCloseLocal, serverId])

    return <Transition
        as={Fragment}
        show={open}
        enter="transform transition duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95 "
    >
        <div className="fixed right-4 top-[220px] z-40 text-sm rounded-lg shadow-2xl border bg-white border-gray-300">
            <div className="w-[400px]">
                <div className="p-4 border-b border-gray-200">
                    <div className="font-semibold text-base">Add setup</div>
                </div>
                <div className="flex flex-col p-4 gap-2">
                    <form method="post" action="/api/apa/setups/account/1">
                        <div>
                            <input ref={ref => fileRef.current = ref} type="file" onChange={_ => onFileChange()} name="setup_file" />
                        </div>
                        <div>
                            <CustomInput name="description" label="Description" value={description} onChange={e => setDescription(e.target.value)} />
                        </div>
                        <div>
                            <Select label="Overwrite" onChange={e => doSetSelectedSetup(Number(e.target.value))} value={selectedSetup?.id || ''}>
                                <option value="-1">None</option>
                                {setups.map(setup => <option key={setup?.id} value={setup?.id}>{setup?.description}</option>)}
                            </Select>
                        </div>
                    </form>

                </div>
                <div className="bg-gray-50 border-t border-gray-300 rounded-br-lg rounded-bl-lg">
                    <div className="p-4 flex flex-row-reverse gap-2">
                        <div><Button disabled={description === "" || !hasFiles()} text="Save" color="primary" onClick={() => { doCreateSetup() }} /></div>
                        <div><Button text="Cancel" color="secondary" onClick={onCloseLocal} /></div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
}

export default AddSetup