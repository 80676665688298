import React, { useCallback, useEffect, useState } from "react"
import { get, getRaw, getToken, saveToken } from "../helpers/Requests"
import Forgot from "./Forgot";

interface LoginProps {
    children: any,
    setUser(u: any): any
}

const Login: React.FC<LoginProps> = ({ children, setUser }) => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [forgot, setForgot] = useState(false)

    const getUser = useCallback(() => {
        getRaw('me')
            .then(res => {
                if(res.ok){
                    return res.json();
                }
                return null;
            })
            .then(u => {
                setUser(u)
                setLoggedIn(!!u)
            })
            .catch(err => {
                console.log(err)
            })
    }, [setUser])

    const loginFn = useCallback(() => {
        fetch("/api/login", {
            method: "POST",
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error("Login failed")
                }
            })
            .then(t => {
                if (t.token && t.token !== '') {
                    saveToken(t.token)
                    setLoggedIn(true)
                    getUser()
                } else {
                    throw new Error("Login failed")
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [email, password, getUser])

    const signInDisabled = useCallback(() => {
        if (email !== '' && password !== '') {
            return false
        }
        return true
    }, [email, password])

    useEffect(() => {
        const token = getToken()
        if (token && token !== '' && !loggedIn) {
            getUser()
        }
    }, [getUser, loggedIn])

    if (!loggedIn && forgot) {
        return <Forgot setForgot={b => setForgot(b)} />
    }

    if (loggedIn) {
        return children
    }

    return <div className="flex-1 flex flex-col">
        <div className="h-[625px] flex flex-col justify-center bg-almondine-mist-900">
            <div className="flex flex-col">
                <div className="px-8 pt-8">
                </div>
            </div>
            <div className="flex flex-row justify-around content-center pt-16">
                <div className="text-4xl leading-snug font-medium text-black tracking-wide">
                    <img className=" w-[500px] py-2 px-3 rounded-md" src="./inact_manager_logo_df.svg" alt="" />
                </div>
                <div className="bg-white shadow-2xl rounded-xl p-10 w-[500px] flex flex-col justify-center gap-4">
                    <div className="">
                        <input onChange={e => setEmail(e.target.value)} className="border border-gray-300 rounded w-full py-2 px-3 text-gray-900" id="email" type="email" placeholder="E-mail" />
                    </div>
                    <div className="">
                        <input onChange={e => setPassword(e.target.value)} onKeyPress={e => e.key === "Enter" ? loginFn() : null} className="border-gray-300 border rounded w-full py-2 px-3 text-gray-900" id="password" type="password" placeholder="Password" />
                    </div>
                    {/* <div className="text-center border-b border-gray-300 pb-4 cursor-pointer">
                        <span onClick={_ => { setForgot(true) }} className="text-blue-500 hover:text-blue-600">Forgotten password?</span>
                    </div> */}
                    <div className="z-10">
                        <button
                            disabled={signInDisabled()}
                            onClick={() => loginFn()}
                            className="text-white font-bold py-2 px-4 rounded bg-dark-forest-900 hover:bg-dark-forest-700 cursor-pointer disabled:bg-gray-300 w-full"
                            type="button">
                            Sign In
                        </button>
                        {/* <a className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker" href="#">
                            Forgot Password?
                        </a> */}
                    </div>
                </div>
            </div>
        </div>
        <div className="pt-40">
            <LoginFooter />
        </div>
    </div>
}

export const LoginFooter = () => {
    return <div className="flex flex-col justify-center items-center gap-4">
        <div className="text-center text-gray-500 text-sm flex flex-row gap-4">
            <a target="_blank" rel="noreferrer" href="https://inactnow.io" className="hover:text-blue-500 cursor-pointer">Inact Now</a>
            <a target="_blank" rel="noreferrer" href="https://inact.io" className="hover:text-blue-500 cursor-pointer">inact.io</a>
        </div>
        <div className="text-gray-400 text-xs">Inact @ {new Date().getFullYear()}</div>
    </div>
}

export default Login