import { post } from "../helpers/Requests";

export interface Bucket {
    id: string;
    datasets: DatasetInfo[];
    checklists: number;
    info: BucketInfo;
    organization: number;
    reports: number;
    tracked: boolean;
    trendsets: Trendset[];
}

export interface BucketInfo {
    id: string;
    name: string;
    has_data: boolean;
    last_update: number;
    model: BucketModel;
    setup: IBucketSetup;
    trendsets: number;
}

export interface BucketModel {
    categorization: BucketCategory[];
    categorizations: {name: string, categorization: BucketCategory[]}[]
    categorization_name: string;
    columns: BucketColumn[];
    formula_columns: BucketFormulaColumn[];
}

export interface BucketFormulaColumn {
    formula: string;
    name: string;
    per_category: boolean;
    target_column: string;
    value_formulas: any; //TODO: use correct type
}

export interface BucketCategory {
    color: Color;
    name: string;
    position: Position;
}

export interface Color {
    r: number;
    g: number;
    b: number;
}

export interface Position {
    x: number;
    y: number;
}

export interface Trendset {
    label: string;
    dataset_id: string;
}

export enum BucketColumnType {
    Text = "text",
    ID = "id",
    Decimal = "decimal",
    Date = "date",
    Categorization = "categorization",
}

export interface BucketColumn {
    name: string;
    type: BucketColumnType;
}

export interface DatasetInfo {
    id: string;
    data_time: number;
    creation_time: number;
    model: BucketModel;
    movable: boolean;
}

export interface IBucketSetup {
    hidden_columns: string[];
    descriptions: { [key: string]: string };
    default_graph_column: string;
    default_item_column: string;
    future_category_column: string;
    id_description_column: string;
    categorization_colors: { [key: string]: string };
    latest_project_file: StoredFile | null;
    latest_data_file: StoredFile | null;
    download_token: string;
    csv_config: CSVSetup | null;
    shown_master_columns: MasterColumns | null;
    hidden_master_columns: MasterColumns | null;
    column_boxes: string[];
    additional_columns: string[];
    additional_columns2: string[];
    additional_columns3: string[];
    additional_columns4: string[];
    graph_type: string;
    graph_show_zero: boolean;
    drilldown_shown_columns: string[];
    template_id: number;
}

export interface StoredFile {
    name: string;
    size: number;
    type: string;
}

export interface CSVSetup {
    date_format: string;
    decimal_separator: string;
    value_seperator: string;
}

export interface MasterColumns {
    left: string[];
    right: string[];
}

export const saveBucketSetup = (bucket: BucketInfo, serverId: number, orgId: number) => {
        return post(`cloud/${serverId}/buckets/${orgId}/setup/${bucket.id}`, bucket.setup)
}

