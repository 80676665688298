import { BiStopwatch } from "react-icons/bi";
import {AiOutlineClockCircle} from "react-icons/ai";
import CustomInput from "../CustomInput"

 import '../../css/TimeSelect.css'

interface TimeSelectProps {
    hourValue: number
    minuteValue: number
    onChangeHour: any
    onChangeMinute: any
    stopwatch: boolean
}

export default function TimeSelect({hourValue, minuteValue, onChangeHour, onChangeMinute, stopwatch}: TimeSelectProps){


    let validate = (min:number, max:number, valueText:string) => {
        
        let value = parseInt(valueText)
        if(Number.isNaN(value)){
            return min;
        }
        if(value < min){
            return min
        }
        else if(value > max){
            return max
        }
        else {
            return value
        }
    }

    return (
        <div className="time-select">
            {stopwatch ? 
            <BiStopwatch className="w-5 h-5 time-icon" /> :
            <AiOutlineClockCircle className="w-5 h-5 time-icon" />
            }
            <CustomInput className={"time-input"} value={hourValue} onChange={e => onChangeHour(validate(0,23,e.target.value))} />
            :
            <CustomInput className={"time-input"} style={{textAlign:"left"}} value={minuteValue} onChange={e => onChangeMinute(validate(0,59,e.target.value))} />
        </div>
    )
}
