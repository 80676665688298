import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { HiChevronDown } from 'react-icons/hi'

type Color = "default" | "list" | "icon";
type Expand = "left" | "right"
type Direction = "up" | "down"

interface MenuDropdownProps extends React.ComponentPropsWithoutRef<"div"> {
    color?: Color;
    text?: string;
    icon?: React.ReactNode; // remember aria-hidden="true" when passing icon
    expand?: Expand
    position?: "absolute" | "fixed",
    menuClassName?: string;
    direction?: Direction
}

const colors: { [key: string]: string } = {
    default: "text-white bg-black bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75",
    list: "text-indigo-700 border border-indigo-200 bg-white",
    icon: ""
}

const MenuDropdown: React.FC<MenuDropdownProps> = ({ className, children, color = "default", text, icon, expand = "right", position = "absolute", menuClassName, direction = "down" }) => {
    let dropdownDirectionClassname = "right-0 origin-top-right"
    switch(direction){
        case "down":
            if(expand === "right"){
                dropdownDirectionClassname = "left-0 origin-top-left";
            } 
            break;
        case "up":
            if(expand === "right"){
                dropdownDirectionClassname = "left-5 bottom-5 origin-bottom-left"
            } else {
                dropdownDirectionClassname = "right-5 bottom-5 origin-bottom-right"
            }
            break;
        default:
            // do nothing
    }
    return <div className={className}>
        <Menu as="div"  className="relative inline-block text-left" >
            <div className="z-0">
                <MenuButton className={`${colors[color]} ${text ? "rounded-full inline-flex justify-center w-full px-4 py-2 text-sm font-medium" : ""}`}>
                    {
                        text && <>
                            {text}
                            <HiChevronDown
                                className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                                aria-hidden="true"
                            />
                        </>
                    }
                    {
                        icon && <>
                            {icon}
                        </>
                    }
                </MenuButton>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems className={`z-10 ${position} ${menuClassName} ${dropdownDirectionClassname}  w-56 ${text ? "mt-2" : ""}  bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
                    {children}
                </MenuItems>
            </Transition>
        </Menu>
    </div>
}

export default MenuDropdown