import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { OrganizationSubscriptionSettings, type CloudOrgInfo } from "../../types/cloud_types";
import { Modal } from "../Modals";
import { put } from "../../helpers/Requests";
import { SimpleToastProps } from "../Toasts/SimpleToast";

interface AdminPanelSettingsModalProps {
    isOpen: boolean
    onClose: any
    dispatch: any
    org: CloudOrgInfo
    orgSubscriptionSettings: OrganizationSubscriptionSettings
    setOrgSubscriptionSettings: Dispatch<SetStateAction<OrganizationSubscriptionSettings | undefined>>
    toastConfig: SimpleToastProps
    setToastConfig: Dispatch<SetStateAction<SimpleToastProps>>
}

function AdminPanelSettingsModal({isOpen, org, onClose, dispatch, toastConfig, setToastConfig, ...props}:AdminPanelSettingsModalProps){
    const [addLocationField, setAddLocationField] = useState<boolean>(org.location_field_enabled ?? false);
    const [locationFieldMandatory, setLocationFieldMandatory] = useState<boolean>(org.location_field_mandatory ?? false)
    const [selfSubscriptionEnabled, setSelfSubscriptionEnabled] = useState<boolean>(org.self_subscription_enabled)
    const [orgSubSettings, setOrgSubSettings] = useState<OrganizationSubscriptionSettings>(props.orgSubscriptionSettings)


    useEffect(() => {
        if(!addLocationField){
            setLocationFieldMandatory(false);
        }
    }, [addLocationField])
    
    const saveSettings = async () => {
        const locationFieldData = {
            location_field_enabled: addLocationField,
            location_field_mandatory: locationFieldMandatory
        }
        if(addLocationField !== org.location_field_enabled || locationFieldMandatory !== org.location_field_mandatory){

            const url = `cloud/${org.server?.id}/organizations/${org.id}/location_field`
            await put(url, locationFieldData).then(res => {
                if (!!res) {
                    org.location_field_enabled = res.location_field_enabled
                    org.location_field_mandatory = res.location_field_mandatory
                    const updatedOrg = {...org}
                    dispatch({ type: "change_org", org: updatedOrg })
                    const config = { ...toastConfig }
                    config.text = "Organization settings updated successfully"
                    config.show = true;
                    config.type = "success"
                    
                    setToastConfig(config)
                    setTimeout(() => {
                        const c = { ...config }
                        c.show = false;
                        setToastConfig(c)
                    }, 2000)
                } else {
                    const tConfig = { ...toastConfig }
                    tConfig.text = `Something went wrong updating location field settings`
                    tConfig.show = true;
                    tConfig.type = "danger"
                    
                    setToastConfig(tConfig)
                    
                    setTimeout(() => {
                        const c = { ...tConfig }
                        c.show = false;
                        setToastConfig(c)
                    }, 2000)
                }
            })
        }

        if(selfSubscriptionEnabled !== org.self_subscription_enabled){
            const url = `cloud/${org.server?.id}/organizations/${org.id}/toggle_self_subscription`
            await put(url, locationFieldData).then(res => {
                if (!!res) {
                    org.self_subscription_enabled = selfSubscriptionEnabled
                    const updatedOrg = {...org}
                    dispatch({ type: "change_org", org: updatedOrg })
                    const config = { ...toastConfig }
                    config.text = "Organization settings updated successfully"
                    config.show = true;
                    config.type = "success"
                    
                    setToastConfig(config)
                    setTimeout(() => {
                        const c = { ...config }
                        c.show = false;
                        setToastConfig(c)
                    }, 2000)
                } else {
                    const tConfig = { ...toastConfig }
                    tConfig.text = `Something went wrong updating self subscription settings`
                    tConfig.show = true;
                    tConfig.type = "danger"
                    
                    setToastConfig(tConfig)
                    
                    setTimeout(() => {
                        const c = { ...tConfig }
                        c.show = false;
                        setToastConfig(c)
                    }, 2000)
                }
            })
        }
        const url = `cloud/${org.server?.id}/organizations/${org.id}/subscription_settings`
        await put(url, orgSubSettings).then(res => {
            if(!!res){
                props.setOrgSubscriptionSettings(res)
                const config = { ...toastConfig }
                config.text = "Organization settings updated successfully"
                config.show = true;
                config.type = "success"
                
                setToastConfig(config)
                setTimeout(() => {
                    const c = { ...config }
                    c.show = false;
                    setToastConfig(c)
                }, 2000)
            } else {
                const tConfig = { ...toastConfig }
                    tConfig.text = `Something went wrong updating subscription settings`
                    tConfig.show = true;
                    tConfig.type = "danger"
                    
                    setToastConfig(tConfig)
                    
                    setTimeout(() => {
                        const c = { ...tConfig }
                        c.show = false;
                        setToastConfig(c)
                    }, 2000)
            }
        })
        onClose();
    }

    const handleSubscriptionSettingChange = (key: "enable_matrix" | "enable_kpi" | "enable_sum" | "enable_profit" | "enable_trend" | "enable_dashboard" | "enable_insight", value: boolean) => {
        const tmp = structuredClone(orgSubSettings)
        tmp[key] = value
        setOrgSubSettings(tmp)
    }
    
    return( 
        <Modal 
            isOpen={isOpen} 
            title={org.name + "- Admin Panel Settings"} 
            actionText="Save" 
            onAction={saveSettings}
            closeText="Close"
            onClose={onClose} 
        >
            <div className="p-8 flex flex-col gap-4">
                <div className="flex gap-2 items-center">
                    <label className="flex gap-2 items-center">
                        <input 
                            type="checkbox" 
                            className="rounded focus:ring-transparent" 
                            checked={addLocationField}
                            onChange={() => setAddLocationField((current) => !current)}
                            />
                        Add Location field
                    </label>
                    {addLocationField &&
                        <label className="flex gap-2 items-center">
                        <input 
                            type="checkbox" 
                            className="rounded focus:ring-transparent" 
                            checked={locationFieldMandatory}
                            onChange={() => setLocationFieldMandatory((current) => !current)}
                            />
                        Mandatory
                    </label>
                    }
                </div>
                <div>
                    <label className="flex gap-2 items-center">
                        <input 
                            type="checkbox" 
                            className="rounded focus:ring-transparent" 
                            checked={selfSubscriptionEnabled}
                            onChange={() => setSelfSubscriptionEnabled((current) => !current)}
                            />
                        Enable self subscription
                    </label>
                    {selfSubscriptionEnabled && <ul className="list-none ml-8">
                        <li>
                            <label className="flex gap-2 items-center">
                                <input 
                                    type="checkbox" 
                                    className="rounded focus:ring-transparent" 
                                    checked={orgSubSettings.enable_dashboard}
                                    onChange={(e) => handleSubscriptionSettingChange("enable_dashboard", e.target.checked)}
                                    />
                                Dashboard
                            </label>
                        </li>
                        <li>
                            <label className="flex gap-2 items-center border-b pb-1 mb-1 w-fit">
                                <input 
                                    type="checkbox" 
                                    className="rounded focus:ring-transparent" 
                                    checked={orgSubSettings.enable_insight}
                                    onChange={(e) => handleSubscriptionSettingChange("enable_insight", e.target.checked)}
                                    />
                                Insight (CSV)
                            </label>
                        </li>
                        <li>
                            <label className="flex gap-2 items-center">
                                <input 
                                    type="checkbox" 
                                    className="rounded focus:ring-transparent" 
                                    checked={orgSubSettings.enable_matrix}
                                    onChange={(e) => handleSubscriptionSettingChange("enable_matrix", e.target.checked)}
                                    />
                                Matrix View
                            </label>
                        </li>
                        <li>
                            <label className="flex gap-2 items-center">
                                <input 
                                    type="checkbox" 
                                    className="rounded focus:ring-transparent" 
                                    checked={orgSubSettings.enable_kpi}
                                    onChange={(e) => handleSubscriptionSettingChange("enable_kpi", e.target.checked)}
                                    />
                                KPI View
                            </label>
                        </li>
                        <li>
                            <label className="flex gap-2 items-center">
                                <input 
                                    type="checkbox" 
                                    className="rounded focus:ring-transparent" 
                                    checked={orgSubSettings.enable_sum}
                                    onChange={(e) => handleSubscriptionSettingChange("enable_sum", e.target.checked)}
                                    />
                                Sum View
                            </label>
                        </li>
                        <li>
                            <label className="flex gap-2 items-center">
                                <input 
                                    type="checkbox" 
                                    className="rounded focus:ring-transparent" 
                                    checked={orgSubSettings.enable_profit}
                                    onChange={(e) => handleSubscriptionSettingChange("enable_profit", e.target.checked)}
                                    />
                                Profit View
                            </label>
                        </li>
                        <li>
                            <label className="flex gap-2 items-center">
                                <input 
                                    type="checkbox" 
                                    className="rounded focus:ring-transparent" 
                                    checked={orgSubSettings.enable_trend}
                                    onChange={(e) => handleSubscriptionSettingChange("enable_trend", e.target.checked)}
                                    />
                                Trend View
                            </label>
                        </li>
                    </ul>}
                </div>
            </div>
        </Modal>
    );
}

export default AdminPanelSettingsModal;