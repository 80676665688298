import { ActionTemplateGroup } from "../../types/template_types";
import CustomCheckbox from "../Buttons/CustomCheckbox";
import Divider from "../Divider";
import ShowIf from "../ShowIf";
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow } from "../Table";

interface OrganizationActionTemplateGroupProps {
    group: ActionTemplateGroup
    onChange: (value: boolean) => void
    enabled: boolean
    hideAction: (actionId: number, value: boolean) => void
    hiddenActions: number[]
    hidden: boolean
    hideGroup: (groupId: number, value: boolean) => void
}

const OrganizationActionTemplateGroup = ({group, onChange, enabled, hideAction, hiddenActions, hidden, hideGroup, ...props}: OrganizationActionTemplateGroupProps) => {

    return <tr className="bg-white hover:bg-neutral-50 ">
        <TableBodyCell className="flex w-[100px]">
            <div className="flex">
                <CustomCheckbox value={enabled} onChange={onChange} />
            </div>
        </TableBodyCell>
        <TableBodyCell>
            <div className="flex flex-col">
                <div className="flex items-center justify-between">
                    <h3 className="text-lg">{group.name}</h3>
                    <ShowIf if={!enabled}>
                        <CustomCheckbox value={hidden} onChange={v => hideGroup(group.id, v)} text="Hide from view" />
                    </ShowIf>
                </div>
                <ShowIf if={enabled}>
                    <Divider className="my-2" />
                    <Table>
                        <TableHead className="text-gray-700 bg-gray-50">
                            <TableHeadRow>
                                <TableHeadCell>Name</TableHeadCell>
                                <TableHeadCell className="w-[100px]">Hidden</TableHeadCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            {
                                group.action_templates.map((r, i) => {
                                    return <TableBodyRow key={r.id}>
                                        <TableBodyCell>{r.name}</TableBodyCell>
                                        <TableBodyCell className="w-[100px]"><CustomCheckbox value={hiddenActions.some(id => r.id === id)} onChange={(v) =>  hideAction(r.id, v)}/></TableBodyCell>
                                    </TableBodyRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </ShowIf>
            </div>
        </TableBodyCell>
    </tr>
}

export default OrganizationActionTemplateGroup
