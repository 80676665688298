import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { del, get, post } from "../helpers/Requests"
import { CloudTrackedBucket } from "../types/cloud_types"
import TabBar from "../components/TabBar"
import BucketsTable from "../components/Tracked/BucketsTable"

const CloudBuckets = () => {
    const { serverId } = useParams()
    const [tracked, setTracked] = useState<CloudTrackedBucket[]>([])
    const [untracked, setUntracked] = useState<CloudTrackedBucket[]>([])
    const [tab, setTab] = useState(0)

    const getBuckets =  useCallback(() => {
        Promise.all([
            get("cloud/" + serverId + "/tracked"),
            get("cloud/" + serverId + "/untracked")
        ]).then(([tracked, untracked]) => {
            setTracked(tracked)
            setUntracked(untracked)
        }).catch(err => console.log(err))

    }, [serverId])

    useEffect(() => {
        getBuckets()
    }, [getBuckets])

    

    const untrack = (b: CloudTrackedBucket) => {
        del(`cloud/${serverId}/organization/${b.organization_id}/bucket/${b.id}/untrack`).then(_ => {
            getBuckets()
        }).catch(err => console.log(err))
    }

    const track = (b: CloudTrackedBucket) => {
        post(`cloud/${serverId}/organization/${b.organization_id}/bucket/${b.id}/track`, {}).then(_ => {
            getBuckets()
        }).catch(err => console.log(err))
    }

    return <div className="bg-neutral-100 h-full">
        <div className="p-8 bg-white border-b border-gray-200">
            <h1 className="font-bold text-3xl mb-10">Buckets</h1>
            <TabBar selected={tab} tabs={["Tracked", "Untracked"]} setTab={setTab} labels={[tracked.length, untracked.length]} />
            <div>
                {tab === 0 && <BucketsTable isTracked buttonText="Untrack" buckets={tracked} action={untrack} />}
                {tab === 1 && <BucketsTable buttonText="Track" buckets={untracked} action={track} />}
            </div>

        </div>
    </div >
}

export default CloudBuckets
