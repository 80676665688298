import { ReactNode } from "react"

interface SelectProps {
    value: string | number;
    onChange(e: React.ChangeEvent<HTMLSelectElement>): any;
    label?: ReactNode;
    className?: string
    selectClassName?: string
    children?: ReactNode;
    disabled?: boolean
}

const Select: React.FC<SelectProps> = ({ value, onChange, label, children, className = '', selectClassName = '', disabled = false}) => {
    return (
        <label className={`block ${className} ${disabled ? 'opacity-50' : ''}`}>
            <span className="text-gray-600 text-xs">{label}</span>
            <select disabled={disabled} value={value} onChange={onChange} className={`${!value ? "italic text-gray-500" : ""} block text-sm w-full mt-1 py-1.5 rounded-md border-gray-300 shadow-sm focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50 ${selectClassName}`}>
                {children}
            </select>
        </label>
    )
}

export default Select
