import { HiChevronRight } from "react-icons/hi"
import {  ServerData } from "../../types/types"

const ServerCard: React.FC<{ server: ServerData, onClick(): any }> = ({ server, onClick }) => {

    return <div onClick={onClick} className="flex flex-row justify-between items-center cursor-pointer first:rounded-t-md last:rounded-b-md hover:bg-neutral-50 py-3 px-6 last:border-0 border-b border-gray-300">
        <div className="flex flex-col gap-1 text-sm">
            <div className="font-semibold text-base text-indigo-500">{server.short_name}</div>
        </div>
        <div className="flex flex-row-reverse items-center gap-8">
            <div><HiChevronRight className="h-5 w-5 text-gray-400" /></div>
        </div>
    </div>
}

export default ServerCard