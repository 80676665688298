import { useEffect, useState } from "react"
import { HiChevronRight } from "react-icons/hi"
import { useNavigate } from "react-router-dom"
import { get } from "../../helpers/Requests"
import { APAAccount, APASetup, APASchedule } from "../../types/types"
import { Table, TableBody, TableBodyCell, TableBodyRow, TableHead, TableHeadCell, TableHeadRow } from "../../components/Table"
import moment from "moment"
import { MdDangerous } from 'react-icons/md'
import { useStore } from "../../contexts/StoreContext"

const APASchedules = () => {
    const { accounts } = useStore()

    const [schedules, setSchedules] = useState<APASchedule[]>([])

    const navigate = useNavigate()
    useEffect(() => {
        async function fetch() {

            const getSchedules = async (serverId: number | undefined, accountId: number): Promise<APASchedule[]> => {
                try {
                    const [result] = await Promise.all([get(`apa/${serverId}/schedules/account/${accountId}`)])
                    return result;
                } catch (e) {
                    console.log(e)
                    return []
                }
            }
            const getSetups = async (serverId: number | undefined, accountId: number): Promise<APASetup[]> => {
                try {
                    const [result] = await Promise.all([get(`apa/${serverId}/setups/account/${accountId}`)])
                    return result;
                } catch (e) {
                    console.log(e)
                    return []
                }
            }

            const getAllSchedules = async (accounts: APAAccount[]): Promise<APASchedule[]> => {
                let result: APASchedule[] = []
                for (let j = 0; j < accounts.length; j++) {

                    const [schedules, setups] = await Promise.all([getSchedules(accounts[j].serverId, accounts[j].id), getSetups(accounts[j].serverId, accounts[j].id)])
                    for (let i = 0; i < schedules.length; i++) {
                        schedules[i].serverId = accounts[j].serverId
                        schedules[i].accountId = accounts[j].id
                        let setup = setups.find(setup => setup.id === schedules[i].setup_id)
                        if (setup !== undefined) {
                            schedules[i].quarantined = setup.quarantined
                        }
                    }
                    result = result.concat(schedules)
                }
                return result;
            }

            const schedules = await getAllSchedules(accounts)

            let schedulesSorted = schedules.sort((a, b) => {
                if (a.hour === b.hour) {
                    return a.minute > b.minute ? 1 : -1
                }
                else {
                    return a.hour > b.hour ? 1 : -1
                }

            })
            setSchedules(schedulesSorted)
        }
        fetch();
    }, [accounts])


    const formatDuration = (d: moment.Duration): string => {
        let hours = Math.floor(d.asHours())
        let minutes = d.asMinutes() % 60
        if (minutes === 0) return (`${hours} hour` + (hours === 1 ? '' : 's'))
        return (`${hours} hour` + (hours === 1 ? '' : 's')) + ` ${minutes} minute${minutes === 1 ? '' : 's'}`
    }

    return <Table>
        <TableHead className="bg-black bg-opacity-50 text-white">
            <TableHeadRow>
                <TableHeadCell>Account</TableHeadCell>
                <TableHeadCell>Trigger Time</TableHeadCell>
                <TableHeadCell>Max Duration</TableHeadCell>
                <TableHeadCell></TableHeadCell>
            </TableHeadRow>
        </TableHead>
        <TableBody>
            {schedules.map((s, i) => <TableBodyRow key={i} onClick={() => navigate(`/apa/${s.serverId}/${s.accountId}`)} className="cursor-pointer">
                <TableBodyCell>{accounts.find(x => x.id === s.accountId)?.name}</TableBodyCell>
                <TableBodyCell>{s.hour}:{s.minute < 10 ? `0${s.minute}` : s.minute}</TableBodyCell>
                <TableBodyCell>{formatDuration(moment.duration(s.max_duration, 'seconds'))}</TableBodyCell>
                <TableBodyCell>{s.quarantined ? <MdDangerous className={`h-5 w-5 text-red-700`} /> : <HiChevronRight className={`h-5 w-5 text-gray-400`} />} </TableBodyCell>
            </TableBodyRow>)}
        </TableBody>
    </Table>
}

export default APASchedules