import { useCallback, useEffect, useState } from "react"
import { get, post } from "../helpers/Requests"
import { Dialog, DialogTitle } from "@headlessui/react"
import { useNavigate } from "react-router-dom"
import { ServerData, NewServerProps } from "../types/types"
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext"

function NewServer({ isOpen, setIsOpen }: NewServerProps) {
    const [type, setType] = useState('')
    const [url, setUrl] = useState('')
    const [name, setName] = useState('')
    const [key, setKey] = useState('')
    const [cloud, setCloud] = useState('')
    const { setUp } = useSideBarMenuContext()

    useEffect(() => {
        setUp(null)
    }, [setUp])

    const createServer = useCallback(() => {
        post('servers', { type, short_name: name, url, key, cloud: "" + cloud })
            .then(() => {
                setType('')
                setName('')
                setUrl('')
                setKey('')
                setCloud('')
                setIsOpen(false)
            })
            .catch(err => {
                alert(err)
                console.log(err)
            })
    }, [type, url, key, name, cloud, setIsOpen])

    const [servers, setServers] = useState<ServerData[]>([])
    useEffect(() => {
        get('servers')
            .then(s => {
                setServers(s)
            })
    }, [createServer])


    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">

                <div className="relative bg-white rounded max-w-sm mx-auto p-5 drop-shadow-lg">
                    <DialogTitle className="text-2xl">Add Server</DialogTitle>

                    <label className="block text-gray-900 text-sm font-bold mb-2">Server Type</label>
                    <select onChange={e => setType(e.target.value)} className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" >
                        <option value="">Pick one</option>
                        <option value="cloud">Cloud</option>
                        <option value="apa">APA</option>
                        <option value="reporting">Reporting</option>
                    </select>


                    <label className="block text-gray-900 text-sm font-bold mb-2">Server Name</label>
                    <input onChange={e => setName(e.target.value)} type="text" placeholder="Server Name" className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" />

                    <label className="block text-gray-900 text-sm font-bold mb-2">Server URL</label>
                    <input onChange={e => setUrl(e.target.value)} type="text" placeholder="Server URL" className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" />

                    <label className="block text-gray-900 text-sm font-bold mb-2">Server Key</label>
                    <input onChange={e => setKey(e.target.value)} type="text" placeholder="Server Key" className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" />

                    {/* <label className="block text-gray-900 text-sm font-bold mb-2">Server Deleted</label>
                    <input onChange={e => setDeleted(e.target.checked)} type="checkbox" placeholder="Server Key" className="block shadow appearance-none border rounded py-2 px-3 text-gray-900 mb-5" /> */}

                    {(type === "apa" || type === "reporting") &&
                        <span>
                            <label className="block text-gray-900 text-sm font-bold mb-2">Cloud Server</label>
                            <select onChange={e => setCloud("" + e.target.value)} className="block shadow appearance-none border rounded w-full py-2 px-3 text-gray-900 mb-5" >
                                <option value="">Pick one</option>
                                {servers.filter(item => item.type === "cloud").map(item => <option key={item.id} value={item.id}>{item.short_name}</option>)}
                            </select>
                        </span>
                    }

                    <button
                        onClick={createServer}
                        disabled={url.length === 0 || type.length === 0 || key.length === 0 || name.length === 0}
                        className="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded disabled:bg-green-300 disabled:hover:bg-green-300 mr-2">
                        Add
                    </button>

                    <button
                        onClick={() => setIsOpen(false)}
                        className="bg-gray-700 hover:bg-gray-900 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300 disabled:hover:bg-gray-300">
                        Cancel
                    </button>
                </div>
            </div>
        </Dialog>
    )
}

const Servers = () => {
    const navigate = useNavigate()
    const [addingServer, setAddingServer] = useState(false)
    const [servers, setServers] = useState<ServerData[]>([])

    useEffect(() => {
        get('servers')
            .then(s => {
                setServers(s)
            })
    }, [addingServer])



    return (
        <div className="p-4">
            <h1 className="text-4xl">Servers</h1>

            <hr className="m-8" />

            <NewServer isOpen={addingServer} setIsOpen={setAddingServer} />

            <button
                onClick={() => setAddingServer(true)}
                className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded disabled:bg-blue-300 disabled:hover:bg-blue-300">
                Add server
            </button>


            <table className="table-auto mt-5">
                <thead>
                    <tr>
                        <th className="px-4 py-2">ID</th>
                        <th className="px-4 py-2">Type</th>
                        <th className="px-4 py-2">Name</th>
                        <th className="px-4 py-2">Cloud</th>
                        {/* <th className="px-4 py-2">Key</th> */}
                        <th className="px-4 py-2">Version/Active</th>
                    </tr>
                </thead>
                <tbody>
                    {servers.sort((a, b) => a.id - b.id).map(o => (
                        <tr key={o.id} className="border-t hover:cursor-pointer" onClick={() => navigate(`/servers/${o.id}`)}>
                            <td className="px-4 py-2 text-right">{o.id}</td>
                            <td className="px-4 py-2">{o.type}</td>
                            <td className="px-4 py-2">{o.short_name}</td>
                            <td className="px-4 py-2">{servers.find(x => x.id === o.cloud)?.short_name}</td>
                            {/* <td className="px-4 py-2">{o.key}</td> */}
                            <td className="px-4 py-2 text-center">{o.active}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    )
}

export default Servers