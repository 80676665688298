import React from "react"
import { BsBucket, BsCardChecklist, BsFileEarmark } from "react-icons/bs"
import { Link } from "react-router-dom"

const TemplateCloudOverview = () => {

    
    return (<div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 className="text-gray-500 text-lg font-semibold">Cloud Setup</h1></div>
        </div>
        <div className="mx-4 flex flex-col gap-4">

            <Link to={`buckets`} style={{width: "fit-content"}}>
                <div className="flex items-center bg-white rounded-md shadow-md p-3 w-[400px] hover:bg-neutral-50">
                    <BsBucket className='w-[18px] h-[18px] mr-2' /> Buckets
                </div>
            </Link>
            <Link to={`reports`} style={{width: "fit-content"}}>
                <div className="flex items-center bg-white rounded-md shadow-md p-3 w-[400px] hover:bg-neutral-50">
                    <BsFileEarmark className='w-[18px] h-[18px] mr-2' /> Insights
                </div>
            </Link>
            <Link to={`actions`} className="w-fit">
                <div className="flex items-center bg-white rounded-md shadow-md p-3 w-[400px] hover:bg-neutral-50">
                    <BsCardChecklist className='w-[18px] h-[18px] mr-2' /> Actions
                </div>
            </Link>
        </div>
    </div>
    )
}

export default TemplateCloudOverview
