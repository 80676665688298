import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { get, post, put } from '../../helpers/Requests'
import { CloudOrgInfo, CloudUser, CloudUserSession } from '../../types/cloud_types'
import { Modal } from '../Modals'
import TabBar from '../TabBar'
import '../../css/EditUserModal.css'
import EditPermissions from './EditPermissions'
import EditHomePageSetup from './EditHomePageSetup'
import EditAccessControl from './EditAccessControl'
import UserLog from './UserLog'
import { AlertBannerProps } from '../Alerts/AlertBanner'
import AlertBanner from '../Alerts/AlertBanner'
import EditUserInfo from './EditUserInfo'
import { Dashboard, Report } from '../../types/reporting_types'
import { useDispatch } from '../../contexts/StoreContext'
import { BucketInfo } from '../../types/bucket_types'

interface EditUserModalProps {
    isOpen: boolean
    user: CloudUser | null
    onClose: any
    server: string
    org: CloudOrgInfo
    selectableItems?: any
    locationFieldSettings: {location_field_mandatory:boolean, location_field_enabled: boolean},
    locations: string[],
    setLocations: Dispatch<SetStateAction<string[]>>
}

export default function EditUserModal({ user, onClose, server, org, selectableItems, locationFieldSettings, locations, setLocations }: EditUserModalProps) {
    const [updatedUserInfo, setUpdatedUserInfo] = useState(user)
    const [userHomeInfo, setUserHomeInfo] = useState<any>(null)
    const [updatedHomeInfo, setUpdatedHomeInfo] = useState(userHomeInfo)
    const [homePageLoading, setHomePageLoading] = useState(true);
    const [updatedFavouriteReports, setUpdatedFavouriteReports] = useState(!!userHomeInfo && !!userHomeInfo.favourite_reports ? userHomeInfo.favourite_reports.map((fr: any) => { return { label: fr.report_name, value: fr.report_id } }) : [])
    const [alertConfig, setAlertConfig] = useState<AlertBannerProps>({
        type: "warning",
        title: null,
        text: null,
        onAction: () => { },
        onClose: () => { },
        actionText: "Send Anyway",
        closeText: "Cancel",

    })

    const [showAlert, setShowAlert] = useState<Boolean>(false)
    const [infoValid, setInfoValid] = useState<Boolean>(false)
    const [saved, setSaved] = useState(false)
    const [tab, setTab] = useState(0)
    const [logs, setLogs] = useState<CloudUserSession[]>([])
    const [userHasChanged, setUserHasChanged] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {

        if (user && !user.deleted) {
            get(`/cloud/${server}/logs/${user?.id}`).then((res) => {
                setLogs(res)
            })
        }

    }, [user])

    useEffect(() => {
        if (user && userHomeInfo === null && homePageLoading) {
            get(`/cloud/${server}/users/${user.id}/info`).then((res) => {
                if (!res.favourite_reports) {
                    res.favourite_reports = []
                }

                setUserHomeInfo(res)
                setUpdatedHomeInfo(res)
                setUpdatedFavouriteReports(res.favourite_reports ? res.favourite_reports.map((fr: any) => { return { label: fr.report_name, value: fr.report_id } }) : [])
                setHomePageLoading(false)

            })
        }
    }, [user])

    const informationChanged = useMemo(() => hasInformationChanged(), [updatedUserInfo, user]);
    const permissionsChanged = useMemo(() => unsavedPermissions(), [updatedUserInfo, user]);
    const homeDashboardChanged = useMemo(() => unsavedHomeDashboard(), [updatedHomeInfo, userHomeInfo]);
    const favouriteReportsChanged = useMemo(() => unsavedFavouriteReports(), [updatedFavouriteReports, userHomeInfo]);
    const accessControlChanged = useMemo(() => unsavedAccessControl(), [updatedHomeInfo, userHomeInfo]);

    useEffect(() => {
        if (!user?.deleted) {
            
            if (informationChanged || permissionsChanged || homeDashboardChanged || accessControlChanged || favouriteReportsChanged) {
                setUserHasChanged(true)
                if (informationChanged) {
                    validateFields()
                }
            } else {
                setUserHasChanged(false)
            }
        }

    }, [updatedUserInfo, updatedHomeInfo, updatedFavouriteReports])

    useEffect(() => {
        validateFields()
    }, [])

    function homepageSetupChangeHandler(values: any, changedField: string) {
        if (changedField === "dashboard") {
            const user = { ...updatedHomeInfo }
            user.home_dashboard = !!values ? values.value : 0;
            setUpdatedHomeInfo(user)
        }

        if (changedField === "favouriteReports") {
            setUpdatedFavouriteReports(values)
        }
    }

    function hasInformationChanged(): boolean {

        if (updatedUserInfo?.firstname !== user?.firstname) return true
        if (updatedUserInfo?.lastname !== user?.lastname) return true
        if (updatedUserInfo?.email !== user?.email) return true
        if (updatedUserInfo?.phone_number !== user?.phone_number) return true
        if (updatedUserInfo?.job_title !== user?.job_title) return true
        if (updatedUserInfo?.department !== user?.department) return true
        if (updatedUserInfo?.language !== user?.language) return true
        if (updatedUserInfo?.location !== user?.location) return true

        return false
    }

    useEffect(() => {

        if (!!userHomeInfo && userHomeInfo.favourite_reports) {

            let favouriteReports = userHomeInfo.favourite_reports.length > 0
                ? userHomeInfo.favourite_reports.map((fr: any) => {
                    return { label: fr.report_name, value: fr.report_id }
                })
                : []
            setUpdatedFavouriteReports(favouriteReports)
        }

    }, [user])

    useEffect(() => {

        if (userHasChanged && showAlert && infoValid) {
            const config: AlertBannerProps = {
                type: "warning",
                title: "Unsaved Changes",
                text: "Are you sure you want to exit?",
                onAction: () => { saveUser() },
                onClose: () => { cancelAlertPrompt() },
                actionText: "Save",
                closeText: "Cancel",
                customButtons: [
                    {
                        text: "Exit without saving",
                        onClick: exitWithoutSavingHandler,
                        cssClass: "bg-red-500"
                    }
                ]
            }
            setAlertConfig(config)
        } else if (!userHasChanged && showAlert) {
            setShowAlert(false)
        }

    }, [userHasChanged])

    function permissionsChangedHandler(perms: string[]) {

        if (!user) {
            return
        }

        let u = !!updatedUserInfo ? { ...updatedUserInfo } : { ...user }
        u.permissions = perms
        setUpdatedUserInfo(u as CloudUser)
    }

    function bucketChangedHandler(buckets: any) {
        const updated = { ...updatedHomeInfo }
        updated.bucket_access = buckets.selected.map((b: BucketInfo) => b.id);
        const bucketIDs = buckets.selected.map((b: BucketInfo) => b.id)
        const reports = selectableItems?.reports.filter((r: Report) =>
            bucketIDs.includes(r.bucket))
        const dashboards = (!!selectableItems.dashboards && selectableItems.dashboards !== null) ? selectableItems.dashboards.filter((d: Dashboard) => {
            let accepted = true;
            for (const db of d.buckets) { if (!bucketIDs.includes(db)) accepted = false }
            return accepted
        }) : []

        const favs = updatedFavouriteReports.map((ufr: Report) => ufr.value)
        let favourite_reports = reports.filter((fr: Report) => favs.includes(fr.report_id))
        favourite_reports = favourite_reports.filter((r: Report) => bucketIDs.includes(r.bucket))
        updated.reports = reports;
        updated.dashboards = dashboards;
        updated.favourite_reports = favourite_reports

        setUpdatedFavouriteReports(favourite_reports.map((fr: Report) => { return { label: fr.name, value: fr.report_id } }))
        setUpdatedHomeInfo(updated)
    }

    function unsavedAccessControl() {

        if (!!userHomeInfo && !!updatedHomeInfo) {

            let old = userHomeInfo.bucket_access ?? []
            let updated = updatedHomeInfo.bucket_access ?? [];

            let isSame = arraysHaveSameValues(old, updated)

            return !isSame;
        }
    }

    async function saveUser() {
        let informationChangedLocal = informationChanged
        let permissionsChangedLocal = permissionsChanged
        let homeDashboardChangedLocal = homeDashboardChanged
        let favouriteReportsChangedLocal = favouriteReportsChanged
        let accessControlChangedLocal = accessControlChanged

        let newUser = updatedHomeInfo;

        if (informationChangedLocal && user && updatedUserInfo) {
            if(!!updatedUserInfo.location && !locations.includes(updatedUserInfo.location)){
                const newLocation = updatedUserInfo.location
                setLocations((prev) => [...prev, newLocation])
            }
            let payload = updatedUserInfo;
            const body: any = { ...payload }
            body.disabled = false;
            await put(`/cloud/${server}/users/${user.id}`, body).then((res) => {
                if (!!res) {
                    informationChangedLocal = false;
                    newUser = Object.assign(newUser, res);
                }
            })
        }

        if (permissionsChangedLocal && user) {
            let payload: any

            payload = updatedUserInfo?.permissions

            await put(`/cloud/${server}/users/${user.id}/permissions`, payload).then((res) => {
                if (!!res) {
                    permissionsChangedLocal = false;
                    newUser = Object.assign(newUser, res);
                }
            })
        }

        if (accessControlChangedLocal && user) {
            let payload: any

            payload = {
                "buckets": updatedHomeInfo.bucket_access,
                "organization_id": updatedHomeInfo.organization_id
            };

            await put(`/cloud/${server}/users/bucket_access/${updatedUserInfo?.id}`, payload).then((res) => {
                if (!!res) {
                    accessControlChangedLocal = false;
                    newUser.bucket_access = res.map((r: any) => r.bucket_id)

                }

            })
        }

        if (favouriteReportsChangedLocal && user) {
            let payload: any

            payload = {
                favourites: createFavouriteItems()
            };

            await put(`/cloud/${server}/favourites/update/${updatedUserInfo?.id}`, payload).then((res) => {
                if (!!res) {
                    favouriteReportsChangedLocal = false;
                    newUser.favourite_reports = res.favourite_reports;
                }

            })
        }

        if (homeDashboardChangedLocal && user) {
            let payload: any

            payload = {
                "dashboard_id": updatedHomeInfo.home_dashboard,
            };

            await put(`/cloud/${server}/home/dashboard/${updatedUserInfo?.id}`, payload).then((res) => {
                if (!!res) {
                    homeDashboardChangedLocal = false;
                    newUser.home_dashboard = res.home_dashboard;
                }

            })
        }

        if (!informationChangedLocal && !permissionsChangedLocal && !homeDashboardChangedLocal && !favouriteReportsChangedLocal && !accessControlChangedLocal) {
            setSaved(true)
            setShowAlert(false)
            onClose(true, newUser)

            if (newUser) {
                const users = !!org.users ? [...org.users] : [];
                const index = users.findIndex(u => newUser?.id === u.id)

                users.splice(index, 1, newUser)
                const o = { ...org }
                o.users = users;

                dispatch({ type: "change_org", id: o.id, org: o })

            }
        } else {
            setSaved(false)
        }

    }

    function createFavouriteItems() {
        let cur_user = userHomeInfo;
        const old = cur_user.favourite_reports
        const updated = updatedFavouriteReports.map((fr: any) => fr.value).sort()

        for (const fr of updatedFavouriteReports) {
            const indexO = old?.findIndex((o: Report) => o.report_id === fr.value)
            const indexU = updated.findIndex((u: any) => u === fr.value)

            if (indexO > -1) {
                old.splice(indexO, 1)
                updated.splice(indexU, 1)
            }
        }

        const deleted = old?.map((o: Report) => {
            const favouriteItem = {
                id: o.id,
                item_id: o.report_id,
                item_type: "report",
                user_id: o.user_id,
            }

            return favouriteItem
        })

        const added = updated.map((u: any) => {
            const favouriteItem = {
                id: -1,
                item_id: u,
                item_type: "report",
                user_id: user?.id,
            }

            return favouriteItem
        })

        return added.concat(deleted);
    }

    function closeEditModalHandler() {

        const hasChanged = informationChanged || permissionsChanged || accessControlChanged || homeDashboardChanged || favouriteReportsChanged

        const config: AlertBannerProps = {
            type: "warning",
            title: "Unsaved Changes",
            text: "Are you sure you want to exit?",
            onAction: () => { saveUser() },
            onClose: () => { cancelAlertPrompt() },
            actionText: "Save",
            closeText: "Cancel",
            customButtons: [
                {
                    text: "Exit without saving",
                    onClick: exitWithoutSavingHandler,
                    cssClass: "bg-red-500"
                }
            ]
        }

        if (hasChanged && !saved) {
            setAlertConfig(config)
            setShowAlert(true)
        } else {
            setShowAlert(false)
            exitWithoutSavingHandler()
        }
    }

    function exitWithoutSavingHandler() {
        setUpdatedUserInfo(null)
        setShowAlert(false)
        setUserHasChanged(false)
        onClose()
    }

    function cancelAlertPrompt() {
        setShowAlert(false)
    }

    function unsavedFavouriteReports() {
        if (!!userHomeInfo && !!updatedHomeInfo) {
            let cur_user = userHomeInfo;
            let old = cur_user.favourite_reports.map((fr: Report) => fr.report_id).sort()
            let updated = updatedFavouriteReports.map((fr: any) => fr.value).sort()
            let isSame = false

            if (!old) {
                old = []
            }

            if (!updated) {
                updated = []
            }

            if (old.length === 0 && updated.length === 0) {
                isSame = true
                return !isSame
            }

            if (old) {
                if (old.length === updated.length) {
                    old.forEach((element: Report, index: number) => {
                        if (element === updated[index]) {
                            isSame = true
                        } else {
                            isSame = false
                        }
                    })
                }
            }

            return !isSame
        }
    }

    function unsavedPermissions() {
        let unsaved = false
        if (!updatedUserInfo?.permissions) {
            return false
        }

        let updatedPermissions = [...updatedUserInfo.permissions]

        if (user?.permissions) {
            const permissionsAreTheSame = arraysHaveSameValues(updatedPermissions, user.permissions)

            if (!permissionsAreTheSame) {
                unsaved = true
            }
        }
        else {
            return true
        }

        return unsaved
    }
    

    function unsavedHomeDashboard() {
        if(!updatedHomeInfo){
            return false
        }
        if (!!userHomeInfo && userHomeInfo.home_dashboard !== updatedHomeInfo.home_dashboard) {
            return true
        } else return false
    }

    function validateFields() {
        let cur_user = user;

        const userInfo = !!updatedUserInfo ? { ...updatedUserInfo } : { ...cur_user }
        const u = {
            id: userInfo.id,
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            email: userInfo.email,
            job_title: userInfo.job_title,
            department: !!userInfo.department ? userInfo.department : "Other",
            language: userInfo.language,
            organization_id: userInfo.organization_id,
            deleted: userInfo.deleted,
            location: userInfo.location
        }

        const keys = Object.keys(u)
        let canBeSaved = true
        for (const k of keys) {
            if (u[k as keyof typeof u] === "" || u[k as keyof typeof u] === null || u[k as keyof typeof u] === undefined) {
                if(k === "location" && !locationFieldSettings.location_field_mandatory){
                    canBeSaved = true
                } else {
                    canBeSaved = false
                }
            }
        }
        
        const phoneReg = /^\+?\d{1,3}[\s-]?\d{3,14}$/u

        if (!!userInfo.phone_number && !phoneReg.test(userInfo.phone_number)) {
            canBeSaved = false
        }

        if (!userInfo.phone_number) {
            userInfo.phone_number = "";
        }

        if (!canBeSaved) {

            const config: AlertBannerProps = {
                type: "danger",
                title: "Missing field(s)",
                text: "Please provide the missing field(s)",
                onAction: () => { },

                onClose: () => { cancelAlertPrompt() },
                closeText: "Cancel",
            }

            setAlertConfig(config)
            setShowAlert(true)

        } else {
            setShowAlert(false)
        }

        setInfoValid(canBeSaved)


        return canBeSaved
    }

    function arraysHaveSameValues(array1: any[], array2: any[]) {
        const arr1 = [...array1].sort()
        const arr2 = [...array2].sort()

        if (arr1.length === arr2.length) {
            return arr1.every((element, index) => {
                if (element === arr2[index]) {
                    return true
                }
                return false
            })
        }

        return false
    }

    function cancelAlertHandler() {
        setShowAlert(false)
    }

    function infoChangedHandler(c: CloudUser) {

        setUpdatedUserInfo({...c})
    }

    function alertHandler(config: AlertBannerProps) {
        setAlertConfig(config)
        setShowAlert(true)
    }

    function additionalHeader() {
        return <>
            <h2 style={{ marginTop: 10 }}>{user?.email} - #{user?.id}</h2>
            <TabBar style={{ marginBottom: 10 }} selected={tab} tabs={org.use_access_control ? ["Information", "Permissions", "Access control", "Homepage setup", "Logs"] : ["Information", "Permissions", "Homepage setup", "Logs"]} setTab={setTab} />
        </>
    }

    async function sendWelcomeMail() {

        const url = `cloud/${org.server?.id}/users/${user?.id}/mail/welcome`
        await post(url, null).then((res) => {
            if (!!res) {
                const config: AlertBannerProps = {
                    type: "success",
                    title: "Mail sent successfully",
                    text: "",
                }

                setAlertConfig(config);
                setShowAlert(true)

                setTimeout(() => { setShowAlert(false) }, 3000)

                let newUser = { ...updatedHomeInfo };

                const mail = {

                    id: -1,
                    user_id: updatedUserInfo?.id,
                    mail_type: "welcome_mail",
                    send_time: Date.now()
                }

                newUser.last_welcome_mail = mail;

                const users = !!org.users ? [...org.users] : [];
                const index = users.findIndex(u => newUser?.id === u.id)

                users.splice(index, 1, newUser)
                const o = { ...org }
                o.users = users;

                dispatch({ type: "change_org", id: o.id, org: o })
            }

        })
    }

    function disableUserHandler() {
        const config: AlertBannerProps = {
            type: "danger",
            title: "Disable user",
            text: "Are you sure you want to disable this user?",
            actionText: "Disable",
            closeText: "Cancel",
            onAction: () => disableUser(),
            onClose: () => { setShowAlert(false) },

        }

        setAlertConfig(config)
        setShowAlert(true)
    }

    async function disableUser() {
        let payload = { ...updatedUserInfo };
        payload.deleted = true;
        const body: any = { ...payload }
        body.disabled = false;
        await put(`/cloud/${server}/users/${updatedUserInfo?.id}`, body).then((res) => {
            if (!!res) {
                const newUser = Object.assign(updatedHomeInfo, res);

                const users = !!org.users ? [...org.users] : [];
                const index = users.findIndex(u => newUser?.id === u.id)

                users.splice(index, 1, newUser)
                const o = { ...org }
                o.users = users;

                dispatch({ type: "change_org", id: o.id, org: o })
                onClose()

            }
        })
    }
    
    return (

        <Modal size='xx-large' disableAction={!infoValid || !userHasChanged} title="Edit User" additionalHeader={additionalHeader()} customFooter={showAlert && <footer className="p-2"><AlertBanner {...alertConfig} /></footer>} isOpen={true} actionText="Save" onClose={() => { closeEditModalHandler() }} closeText="Close" onAction={() => saveUser()}>
            <div className="mx-auto py-4 px-1">
                {tab === 0 && !!user &&
                    <EditUserInfo 
                        user={!!updatedUserInfo ? updatedUserInfo : user} 
                        userHomeInfo={userHomeInfo} 
                        changeHandler={infoChangedHandler} 
                        cancelAlertHandler={cancelAlertHandler} 
                        sendWelcomeMail={sendWelcomeMail} 
                        disableUser={disableUserHandler} 
                        alertHandler={alertHandler}
                        locations={locations}
                        locationFieldSettings={locationFieldSettings} 
                    />   

                }
                {tab === 1 && user &&
                    <EditPermissions org={org} multiEdit={false} user={!!updatedUserInfo ? updatedUserInfo : user} permissionsChangedHandler={permissionsChangedHandler} defaultRole={''} ></EditPermissions>

                }

                {org.use_access_control ?

                    <>

                        {tab === 2 &&

                            <div className="overflow-auto">
                                <EditAccessControl bucket_access={!!updatedHomeInfo ? updatedHomeInfo.bucket_access : userHomeInfo.bucket_access} items={selectableItems} bucketChangedHandler={bucketChangedHandler}></EditAccessControl>
                            </div>

                        }
                        {tab === 3 &&
                            <>
                                {homePageLoading ?
                                    <div> <svg aria-hidden="true" className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-tangerine-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                                        <span className="sr-only">Loading...</span></div>
                                    :
                                    <EditHomePageSetup user={!!updatedHomeInfo ? updatedHomeInfo : userHomeInfo} permissions={!!updatedUserInfo ? updatedUserInfo.permissions : user?.permissions} homeDashboard={updatedHomeInfo.home_dashboard} favReports={updatedFavouriteReports} homepageSetupChangeHandler={homepageSetupChangeHandler} mode="edit"></EditHomePageSetup>
                                }
                            </>
                        }


                        {tab === 4 &&
                            <UserLog logs={logs} user={user}></UserLog>
                        }

                    </>

                    :

                    <>

                        {tab === 2 &&

                            <>

                                {homePageLoading ?
                                    <div> <svg aria-hidden="true" className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-tangerine-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" /><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" /></svg>
                                        <span className="sr-only">Loading...</span></div>
                                    :
                                    <EditHomePageSetup user={!!updatedHomeInfo ? updatedHomeInfo : userHomeInfo} permissions={!!updatedUserInfo ? updatedUserInfo.permissions : user?.permissions} homeDashboard={updatedHomeInfo.home_dashboard} favReports={updatedFavouriteReports}
                                        homepageSetupChangeHandler={homepageSetupChangeHandler} mode="edit"></EditHomePageSetup>

                                }

                            </>
                        }

                        {tab === 3 &&
                            <UserLog logs={logs} user={user}></UserLog>
                        }

                    </>

                }

            </div>
        </Modal>
    )
}
