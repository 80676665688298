import { ReportingItem, Plan } from './../types/reporting_types';
import { ReportingStats } from "../types/reporting_types"
import { CloudUser, Consultant, MailBundle } from '../types/cloud_types';

export const getReportingStats = (items: ReportingItem[], plans: Plan[]): ReportingStats => {
    return {
        daily: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "D").length,
        weekly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "W").length,
        monthly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "M").length
    }
}

export const getMailBundleStats = (items: MailBundle[], plans: Plan[]): ReportingStats => {
    return {
        daily: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "D").length,
        weekly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "W").length,
        monthly: items.filter(d => plans.find(p => p.id === d.plan_id)?.interval[0]  === "M").length
    }
}

export const getPlanInterval = (plan_id: number, plans: Plan[]): string => {
    var plan = plans.find(x => x.id === plan_id);
    if(plan !== undefined)
        return plan.interval
    else
        return ""
}

// Filters out consultants that are also a user in the org. returns the list sorted
export const filterOrgUsersFromConsultants = (consultants: Consultant[], users: CloudUser[]) => {
    return consultants
        .filter(c => {
            const consultantUser = users.find(u => u.id === c.user_id)
            return !consultantUser || consultantUser.organization_id === -1 // Consultants that are not also a user in the org will have -1 as org id.
        })
        .sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname))
}

// maps recipient ids to consultant objects, with consultants that are also org users filtered out. returns the list sorted
export const getConsultantsFromRecipientIds = (rIds: number[], consultants: Consultant[], users: CloudUser[]) => {
    const filteredConsultants = filterOrgUsersFromConsultants(consultants, users); 
    return rIds
        .filter(id => filteredConsultants.some(c => c.user_id === id))
        .map(rId => consultants.find(c => c.user_id === rId)!)
        .sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname))
}

// maps recipient ids to cloud user objects, returns the list sorted
export const getCloudUsersFromRecipientIds = (rIds: number[], users: CloudUser[]) => {
    return rIds
        .filter(id => users.some(r => r.id === id))
        .map(rId => users.find(r => r.id === rId)!)
        .sort((a,b) => (a.firstname + a.lastname).localeCompare(b.firstname + b.lastname))
}
