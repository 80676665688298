import { useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import Button from "../components/Button"
import { APAAccount } from "../types/types"
import CustomCombobox, { IComboboxOption } from "../components/CustomCombobox"
import { useDispatch, useStore } from "../contexts/StoreContext"
import { getAllAccounts, updateAccount } from "../api/apa.api"


const APAOrgWithoutAccount = () => {
    let { orgId } = useParams()
    let { accounts, servers } = useStore()
    const [options, setOptions] = useState<IComboboxOption[] | []>([])
    const [selectedOption, setSelectedOption] = useState<IComboboxOption>()
    const dispatch = useDispatch()
    const navigate = useNavigate();

    useEffect(() => {
        if (accounts.length === 0) {
            getAllAccounts(servers).then(res => {
                dispatch(({
                    type: 'set_accounts',
                    accounts: res
                }))

            })


        }

        if (accounts.length > 0 && options.length === 0) {

            const opts = accounts.map(a => {
                return {
                    id: a.id,
                    value: a.id,
                    label: a.name,
                    disabled: false
                }
            })

            const options = [{ id: 0, value: 0, label: "No Account" }]


            const newOptions = options.concat(opts)
            setOptions(newOptions)
            setSelectedOption({ id: 0, value: 0, label: "No Account" })
        }

    }, [accounts, options])

    function selectedOptionHandler(value: any) {
        setSelectedOption(value)
    }

    function linkAccountHandler() {
        const account = accounts.find(a => a.id === selectedOption?.id)
        if (!!account) {
            const updatedAccount = { ...account }
            updatedAccount.organization_id = parseInt(orgId!, 10);
            updateAccount(account.serverId!, updatedAccount).then((updatedAcc: APAAccount | undefined) => {
                if (!!updatedAcc) {
                    updatedAcc.serverId = account.serverId!
                    dispatch(({
                        type: 'change_account',
                        account: updatedAcc,
                        id: updatedAcc.id
                    }))

                    navigate(updatedAcc.id.toString());

                }

            })

        } else {
            const accs = accounts.filter(a => a.organization_id === Number(orgId))
            for (const acc of accs) {
                const newAcc = { ...acc }
                newAcc.organization_id = 0;
                const serverID = acc.serverId!
                updateAccount(serverID, newAcc).then(newAcc => {

                    if (!!newAcc) {
                        newAcc.serverId = serverID
                        dispatch(({
                            type: 'change_account',
                            account: newAcc,
                            id: newAcc.id
                        }))
                    }

                })
            }
        }
    }


    return <div className="bg-white p-8 border-b border-gray-200 h-full">
        <div className=" mb-4 pt-2 px-4 flex flex-col">
            <p>The organization is not linked to any APA accounts. Please choose the account that belongs to the organization.</p>
            <div className="mb-3 z-10 max-w-xs">

                <CustomCombobox placeholder="" options={options} showValue={true} selectedOption={selectedOption} setSelectedOption={value => selectedOptionHandler(value)}></CustomCombobox>
            </div>
            <Button className="max-w-[150px]" onClick={() => linkAccountHandler()} color={"primary"}>Link to account</Button>
        </div>


    </div >
}

export default APAOrgWithoutAccount