import React, { createContext, useContext, useReducer } from "react";
import { APAAccount, APALatestRun, ServerData } from "../types/types";
import { CloudOrgInfo } from "../types/cloud_types";
import mainReducer from "../reducers";
import { DispatchAction } from "../reducers/actions";

export interface Store {
    orgs: CloudOrgInfo[],
    accounts: APAAccount[],
    latest_runs: APALatestRun[],
    servers: ServerData[]
}

const initialState: Store = {
    orgs: [],
    accounts: [],
    latest_runs: [],
    servers: []
}

export const StoreContext = createContext<Store>(initialState)

export const AppDispatchContext = createContext<React.Dispatch<DispatchAction>>(() => { })

export function StoreProvider(props: any) {
    initialState.servers = props.data.servers;
    const [states, dispatch] = useReducer(mainReducer, { ...initialState })
    return (

        <StoreContext.Provider value={states}>
            <AppDispatchContext.Provider value={dispatch}>
                {initialState.servers.length > 0 ?
                    props.children

                    : <p>Loading</p>
                }
            </AppDispatchContext.Provider>
        </StoreContext.Provider>

    )
}

export const useStore = () => useContext(StoreContext)
export const useDispatch = () => useContext(AppDispatchContext)
