import React, { useEffect, useState } from 'react'
import { ServerData } from '../types/types'
import { get } from '../helpers/Requests'
import { Link, useNavigate } from 'react-router-dom'
import { BsCloud } from 'react-icons/bs'

const TemplateSetup = () => {

    const [clouds, setClouds] = useState<ServerData[]>([])
    const navigate = useNavigate();

    useEffect(() => {
        get("servers").then(servers => {
            let typedServers = servers as ServerData[]
            typedServers = typedServers.filter(v => v.type === "cloud")
            setClouds(typedServers)
            if (typedServers.length === 1) {
                navigate(`/templates/${typedServers[0].id}`)
            }
        })
    }, [navigate])

    return (<div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 className="text-gray-500 text-lg font-semibold">Templates</h1></div>
        </div>
        <div className="px-4 py-2"><h2 className="text-gray-500 text-lg font-semibold">Choose a cloud to configure</h2></div>
        <div className="mx-4 flex flex-col gap-4">
            {
                clouds.map((c, i) => {
                    return <Link key={i} to={`/templates/${c.id}`} style={{width: "fit-content"}}>
                         <div className="flex items-center bg-white rounded-md shadow-md p-3 w-[400px] hover:bg-neutral-50">
                            <BsCloud className='w-[18px] h-[18px] mr-2' /> {c.short_name}
                        </div>
                    </Link>
                })
            }
        </div>
    </div>
    )
        
}

export default TemplateSetup
