import React, { useEffect, useState } from 'react'
import { HiOutlinePlus } from 'react-icons/hi'
import { Link, useParams } from 'react-router-dom'
import CustomInput from '../components/CustomInput'
import { Modal } from '../components/Modals'
import BucketTemplateCard from '../components/Template/BucketTemplateCard'
import { get, post } from '../helpers/Requests'
import { BucketTemplate } from '../types/template_types'

const TemplateBucketOverview = () => {

    const [buckets, setBuckets] = useState<BucketTemplate[]>([])
    const { cloudId } = useParams()

    const [showNewBucket, setShowNewBucket] = useState(false)
    const [tmpName, setTmpName] = useState("")

    useEffect(() => {
        getBuckets()
    }, [cloudId])

    const getBuckets = () => {
        get("cloud/" + cloudId + '/templates/buckets')
        .then(result => {
            let tmpBuckets: BucketTemplate[] = result
            setBuckets(tmpBuckets)
        }).catch(err => console.log(err))
    }

    const onClose = () => {
        setShowNewBucket(false)
        setTmpName("")
    }

    const onSave = () => {
        if (tmpName === "") return
        post("cloud/" + cloudId + '/templates/buckets', {name: tmpName}).then(result => {
            onClose()
            getBuckets()
        }).catch(err => console.log(err))
    }

    return (<div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 className="text-gray-500 text-lg font-semibold">Bucket Setups</h1></div>
        </div>
        <div className="mx-4 flex flex-row gap-4">
            {
                buckets.map((v,i) => {
                    return <Link key={i} to={`${v.id}`}><BucketTemplateCard name={v.name} className='hover:bg-neutral-50' /></Link>
                })
            }

            <div onClick={() => setShowNewBucket(true)} className='cursor-pointer hover:bg-lime-50 border-2 border-green-500 text-green-500 flex flex-col items-center justify-center bg-white rounded-md shadow-md p-2 w-[100px] h-[100px]'>
                <HiOutlinePlus className='w-[24px] h-[24px]' />
                <span className='truncate max-w-full'>New</span>
            </div>
        </div>
        <Modal isOpen={showNewBucket} 
                onClose={onClose} 
                onAction={onSave} 
                title='New Bucket Template' 
                actionText='Save' 
                closeText='Cancel'
                size='small'
                disableAction={tmpName === ""}>
                <div className='mt-3'>
                    <label htmlFor='name-input'>Name</label>
                    <CustomInput id='name-input' value={tmpName} onChange={e => setTmpName(e.target.value)} placeholder='Insert name...' />
                </div>
        </Modal>
    </div>
    )
}

export default TemplateBucketOverview
