import { get } from "../helpers/Requests";
import { CloudError} from "../types/cloud_types";
import { APALatestRun, ServerData } from "../types/types";

export const getLatestRunErrors = async function (servers: ServerData[],) {
    let error: any[] = [];
    let response: APALatestRun[] = []
    for (const server of servers) {
        if (server.type === "apa") {
            await get('apa/' + server.id + '/setups/runs/latest')
                .then((data: APALatestRun[]) => {
                    data = data.map(d => {d.server_id = server.id; return d})
                    response.push(...data)
                })
                .catch(e => {
                    error.push(e);
            })
        }
        if (error.length > 0) {
            break;
        }
    }
    return error.length > 0 ? Promise.reject(error) : Promise.resolve(response)        
}

export const getCloudErrors = async function (servers: ServerData[],) {
    let error: any[] = [];
    let response: CloudError[] = []
    for (const server of servers) {
        if (server.id && server.type === "cloud") {
            await get("cloud/" + server.id + "/errors")
                .then((errors) => {
                    response.push(...errors)
                })
                .catch(e => {
                    error.push(e);
                })
        }
        if (error.length > 0) {
            break;
        }
    }
    return error.length > 0 ? Promise.reject(error) : Promise.resolve(response)

}
