import '../../css/CustomCheckbox.css'
import { PropsWithChildren } from 'react'

interface TableButtonsProps {
    onClick: Function,
    className?: string,
    disabled?: boolean,
}


export default function TableButton({ children, onClick, className = "", disabled = false }: PropsWithChildren<TableButtonsProps>) {


    function clickHandler(e: any) {
        e.preventDefault()
        e.stopPropagation()
        onClick()
    }
    return (
        <button className={` hover:bg-dark-forest-100 px-3 py-2 rounded ${className ? className : ''} ${disabled ? "disabled" : ""}`} aria-disabled={disabled} onClick={e => clickHandler(e)} >
            {children}
        </button>
    )
}