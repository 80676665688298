import { Dialog, Transition, TransitionChild, DialogTitle } from '@headlessui/react'
import { HiX } from 'react-icons/hi'
import { Fragment, PropsWithChildren } from 'react'
import Button from '../Button'

type Size = "default" | "small" | "medium" | "large" | "x-large" | "xx-large"

type ModalProps = {
    isOpen: boolean,
    onClose: any,
    onAction?: any,
    title: string,
    actionText?: string,
    closeText: string,
    height?: string,
    size?: Size,
    disableAction?: boolean,
    disableActionButtons?: boolean,
    center?: boolean,
    customFooter?: any,
    additionalHeader?: any,
    onKeyDown?: (event: any) => void

}

const Modal = ({ isOpen, onClose, title, children, actionText, closeText, onAction, height, size = "default", disableAction, disableActionButtons, center, customFooter, additionalHeader, onKeyDown = () => { } }: PropsWithChildren<ModalProps>) => {

    const getSize = () => {
        switch (size) {
            case "small":
                return "max-w-md"
            case "medium":
                return 'max-w-xl'
            case "large":
                return "max-w-3xl"
            case "x-large":
                return "max-w-4xl"
            case "xx-large":
                return "max-w-5xl"
            default:
                return "max-w-2xl"
        }
    }

    const getHeight = () => {
        return height ? height : 'h-fit';
    }

    return <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-10 max-h-screen"
                onClose={onClose}
                onKeyDown={onKeyDown}
            >
                <div className="min-h-screen px-4 text-center">
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/30" />
                    </TransitionChild>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span
                        className={`inline-block h-screen ${center ? 'align-middle' : 'mt-28'}`}
                        aria-hidden="true"
                    >
                        &#8203;
                    </span>
                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >

                        <div className={`inline-flex flex-col justify-between ${getHeight()}  w-full ${getSize()} overflow-visible my-5 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl max-h-screen`}>

                            <DialogTitle
                                as="header"
                                className=" text-gray-900  mt-2 mx-2 px-4 pt-4  bg-white"
                            >
                                <div className="flex flex-row justify-between">
                                    <h3 className="text-xl font-medium leading-6">{title}</h3>
                                    <div><HiX onClick={onClose} className="w-5 h-5 text-gray-500 cursor-pointer" /></div>
                                </div>
                                {additionalHeader}

                            </DialogTitle>
                            <div className={`px-4 h-fit overflow-auto`}>
                                {children}
                            </div>

                            <div className='border-t border-gray-200  m-2 bg-white'>
                                {!!customFooter ?
                                    customFooter :

                                    !disableActionButtons && <div className="text-right m-4">
                                        <Button className="mr-2" onClick={onClose} text={closeText} color="secondary" />
                                        {actionText && onAction && <Button disabled={disableAction} text={actionText} onClick={onAction} color="primary" />}
                                    </div>}
                            </div>

                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>
    </>
}

export default Modal
