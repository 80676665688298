import Button from "../Button"

interface DaySelectProps {
    selectedDays: boolean[]
    onChangeSelectedDays: any
}

export default function DaySelect({selectedDays, onChangeSelectedDays}: DaySelectProps){

    let flipDay= (day:number)=>{
        /* hack for changes to be detected: make a copy */
        let newDays = [...selectedDays];
        newDays[day] = !newDays[day]
        onChangeSelectedDays(newDays)
    }


    return (
        <div className="day-select" style={{clear:"both"}}>
            <div style={{float:"left"}}>
                {selectedDays[0] ? <Button text="Mon" color="primary" onClick={_ => flipDay(0)}></Button> : null}
                {selectedDays[1] ? <Button text="Tue" color="primary" onClick={_ => flipDay(1)}></Button> : null}
                {selectedDays[2] ? <Button text="Wed" color="primary" onClick={_ => flipDay(2)}></Button> : null}
                {selectedDays[3] ? <Button text="Thu" color="primary" onClick={_ => flipDay(3)}></Button> : null}
                {selectedDays[4] ? <Button text="Fri" color="primary" onClick={_ => flipDay(4)}></Button> : null}
                {selectedDays[5] ? <Button text="Sat" color="primary" onClick={_ => flipDay(5)}></Button> : null}
                {selectedDays[6] ? <Button text="Sun" color="primary" onClick={_ => flipDay(6)}></Button> : null}
            </div>
            <div style={{float:"right"}}>
                {!selectedDays[0] ? <Button text="Mon" color="default" onClick={_ => flipDay(0)}></Button> : null}
                {!selectedDays[1] ? <Button text="Tue" color="default" onClick={_ => flipDay(1)}></Button> : null}
                {!selectedDays[2] ? <Button text="Wed" color="default" onClick={_ => flipDay(2)}></Button> : null}
                {!selectedDays[3] ? <Button text="Thu" color="default" onClick={_ => flipDay(3)}></Button> : null}
                {!selectedDays[4] ? <Button text="Fri" color="default" onClick={_ => flipDay(4)}></Button> : null}
                {!selectedDays[5] ? <Button text="Sat" color="default" onClick={_ => flipDay(5)}></Button> : null}
                {!selectedDays[6] ? <Button text="Sun" color="default" onClick={_ => flipDay(6)}></Button> : null}
            </div>
        </div>
    )
}
