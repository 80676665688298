import { Dialog, Transition, TransitionChild, DialogTitle } from '@headlessui/react'
import { HiOutlineX, HiOutlineExclamation } from 'react-icons/hi'
import { Fragment, PropsWithChildren } from 'react'
import Button from '../Button'

type Size = "default" | "small" | "medium" | "large" | "x-large"

export type WarningModalProps = {
    isOpen: boolean;
    onClose(): any;
    onAction: (e: any) => void;
    title: string;
    actionText: string;
    closeText: string;
    size?: Size;
    disableAction?: boolean;
    disableActionButtons?: boolean;
    center?: boolean;
}
const WarningModal = ({ isOpen, onClose, title, children, actionText, closeText, onAction, size = "default", disableAction, disableActionButtons, center }: PropsWithChildren<WarningModalProps>) => {

    const getSize = () => {
        switch (size) {
            case "small":
                return "max-w-md"
            case "medium":
                return 'max-w-xl'
            case "large":
                return "max-w-3xl"
            case "x-large":
                return "max-w-4xl"
            default:
                return "max-w-2xl"
        }
    }

    return <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="absolute inset-0 z-10 overflow-y-auto"
                onClose={onClose}
            >
                {/* Dialog.Overlay blurs the background. Remove or make condition to change this behaviour */}
                <div className="min-h-screen px-4 text-center">

                    <TransitionChild
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className={`inline-block w-full ${getSize()} overflow-visible my-48 text-left align-middle transition-all transform bg-white shadow-xl rounded-lg`}>
                            <div className='flex flex-row justify-between'>
                                <div className='p-6 flex flex-row gap-4'>
                                    <div>
                                        <div className='bg-red-100 rounded-full p-2'>
                                            <HiOutlineExclamation className='w-6 h-6 text-red-600' />
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div>
                                            <DialogTitle
                                                as="h3"
                                                className="text-xl font-medium leading-6 text-gray-900"
                                            >
                                                <div>{title}</div>
                                            </DialogTitle>
                                        </div>
                                        <div className='text-sm text-gray-500'>{children}</div>
                                    </div>
                                </div>

                                <div className="pt-4 pr-4">
                                    <div><HiOutlineX onClick={onClose} className="w-6 h-6 text-gray-500 cursor-pointer" /></div>
                                </div>
                            </div>

                            <div className='bg-slate-50 py-3 px-6 rounded-br-lg rounded-bl-lg'>
                                {!disableActionButtons && <div className="text-right">
                                    <Button className="mr-2" onClick={onClose} text={closeText} color="secondary" />
                                    <Button disabled={disableAction} text={actionText} onClick={onAction} color="alert" />
                                </div>}
                            </div>

                        </div>
                    </TransitionChild>
                </div>
            </Dialog>
        </Transition>
    </>
}

export default WarningModal
