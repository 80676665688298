import { useEffect, useMemo, useState } from "react";
import { useImmer } from "use-immer";
import { Modal } from "../components/Modals";
import { BucketColumnType, BucketInfo, saveBucketSetup } from "../types/bucket_types";
import CustomInput from "../components/CustomInput";
import { get, post } from "../helpers/Requests";
import { BucketTemplate } from "../types/template_types";
import Select from "../components/Select";
import Button from "../components/Button";
import { FaSave, FaUndo } from "react-icons/fa";
import { compareObjects } from "../helpers/type_helpers";
import { useParams } from "react-router-dom";
import ShowIf from "../components/ShowIf";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { PiWarningBold } from "react-icons/pi";

interface OrganizationBucketSetupEditorProps {
    chosenBucket: BucketInfo;
    orgId: number;
    cloudId: number;
    templates: BucketTemplate[];
    getBucket: () => void;
}

const OrganizationBucketSetupEditor = ({
    chosenBucket,
    orgId,
    templates,
    cloudId,
    ...props
}: OrganizationBucketSetupEditorProps) => {
    const [bucket, updateBucket] = useImmer(structuredClone(chosenBucket));
    const [loadingCounter, setLoadingCounter] = useState(0);
    const addLoading = () => setLoadingCounter((c) => c + 1);
    const doneLoading = () => setLoadingCounter((c) => Math.max(0, c - 1));
    const loading = loadingCounter > 0;

    const [showEditName, setShowEditName] = useState(false);
    const [tmpName, setTmpName] = useState("");

    const selectedTemplate = useMemo(() => {
        return templates.find(t => t.id === bucket.setup.template_id)
    }, [bucket, templates])

    const missingColumns = useMemo(() => {
        let missing : string[] = []
        const template = templates.find(x => x.id === selectedTemplate?.id)
        if(!template) return missing;
        return template.columns
            .filter(col => !bucket.model.columns.some(c => c.name === col.name))
            .map(c => c.name)
    }, [templates, bucket])
    const notRightTypeColumns = useMemo(() => {
        let notRight: {name: string, type: BucketColumnType}[] = []
        const template = templates.find(x => x.id === selectedTemplate?.id)
        if(!template) return notRight;
        return template.columns
            .filter(col => bucket.model.columns.find(c => c.name === col.name)?.type !== col.type)
            .map(c => ({name: c.name, type: c.type}))
    }, [templates, bucket])
    const hiddenColumns = useMemo(() => {
        let hidden: string[] = []
        const template = templates.find(x => x.id === selectedTemplate?.id)
        if(!template) return hidden;
        return template.columns
            .filter(col => bucket.setup.hidden_columns.includes(col.name))
            .map(c => c.name)
    }, [templates, bucket])
    const missingCategorizations = useMemo(() => {
        let missing: string[] = []
        const template = templates.find(x => x.id === selectedTemplate?.id)
        if(!template) return missing;
        return template.categorization
            .filter(cat => !bucket.model.categorization.some(c => c.name === cat.name))
            .map(c => c.name)
    }, [bucket, templates])

    const onEditName = () => {
        setTmpName(bucket.name);
        setShowEditName(true);
    };

    const hasChanges = useMemo(() => {
        return !compareObjects(bucket.setup, chosenBucket.setup);
    }, [bucket.setup, chosenBucket]);

    const onSaveName = () => {
        if (tmpName === "") return;
        const name = tmpName;
        addLoading();
        post(`cloud/${cloudId}/buckets/${orgId}/${bucket.id}`, {
            name: name,
            trendsets: bucket.trendsets,
        })
            .then(() => {
                updateBucket((b) => {
                    b.name = name;
                });
                props.getBucket();
            })
            .catch((e) => console.log(e))
            .finally(() => doneLoading());
        setShowEditName(false);
    };

    const onSave = () => {
        addLoading();
        saveBucketSetup(bucket, cloudId, orgId)
            .then(() => {
                props.getBucket();
                doneLoading();
            })
            .catch((e) => console.log(e));
    };

    const onUndo = () => {
        updateBucket(structuredClone(chosenBucket));
    };

    return (
        <>
            <div className={loading ? "pointer-events-none opacity-50" : ""}>
                <div className="flex gap-2">
                    <Button
                        onClick={onSave}
                        icon={<FaSave />}
                        text="Save"
                        color="primary"
                        className="w-fit"
                        disabled={!hasChanges}
                    />
                    <Button
                        onClick={onUndo}
                        disabled={!hasChanges}
                        icon={<FaUndo />}
                        text="Undo"
                        color="secondary"
                        className="w-fit"
                    />
                </div>
                <div className="py-2">
                    <h1
                        onClick={onEditName}
                        title="Edit name"
                        className="cursor-pointer text-gray-500 text-lg font-semibold"
                    >
                        Bucket Setup - {bucket.name}
                    </h1>
                    <h2 className="text-gray-500 opacity-50 text-sm">
                        {bucket.id}
                    </h2>
                </div>
                <div className="text-gray-500 font-semibold">
                    Select Template
                </div>
                <div className="text-sm text-gray-500">
                    This bucket should contain all columns in the selected template. It is okay if this bucket contains more columns than defined in the template.
                </div>
                <ShowIf if={!!selectedTemplate && (missingColumns.length > 0 || notRightTypeColumns.length > 0 || bucket.model.categorization_name !== selectedTemplate?.categorization_name || hiddenColumns.length > 0)}>
                    <BucketTemplateWarning
                        bucket={bucket}
                        selectedTemplate={selectedTemplate}
                        missingColumns={missingColumns}
                        missingCategorizations={missingCategorizations}
                        notRightTypeColumns={notRightTypeColumns}
                        hiddenColumns={hiddenColumns}
                    />
                </ShowIf>
                <Select
                    value={bucket.setup.template_id}
                    onChange={(v) =>
                        updateBucket((b) => {
                            b.setup.template_id = Number(v.target.value);
                        })
                    }
                >
                    <option value={0}>None</option>
                    {templates.map((t) => (
                        <option key={t.id} value={t.id}>
                            {t.name}
                        </option>
                    ))}
                </Select>
            </div>

            <Modal
                isOpen={showEditName}
                onClose={() => setShowEditName(false)}
                onAction={onSaveName}
                title="Edit name"
                actionText="Save"
                closeText="Cancel"
                size="small"
                disableAction={tmpName === ""}
            >
                <div className="mt-3">
                    <label htmlFor="name-input">Name</label>
                    <CustomInput
                        id="name-input"
                        value={tmpName}
                        onChange={(e) => setTmpName(e.target.value)}
                        placeholder="Insert name..."
                    />
                </div>
            </Modal>
        </>
    );
};

interface BucketTemplateWarningProps {
    bucket: BucketInfo;
    selectedTemplate: BucketTemplate | undefined;
    missingCategorizations: string[];
    missingColumns: string[];
    notRightTypeColumns: {name: string, type: BucketColumnType}[]
    hiddenColumns: string[];
}

function BucketTemplateWarning({bucket, missingCategorizations, selectedTemplate, missingColumns, notRightTypeColumns, hiddenColumns, ...props}:BucketTemplateWarningProps) {
    const [showDetails, setShowDetails] = useState<boolean>(false);

    return (
        <div className="text-sm text-gray-500">
            <h2 className="text-red-500 font-semibold">
                <PiWarningBold className="inline-block mr-1 text-lg mb-1"/>
                Warning! This bucket is not consistent with the selected template! 
                {showDetails 
                    ? <MdKeyboardArrowUp onClick={() => setShowDetails(false)} title="Hide warning details" className="inline-block border-2 text-xl text-gray-500 hover:bg-gray-500 hover:text-white w-6 h-6 ml-2 rounded cursor-pointer " /> 
                    : <MdKeyboardArrowDown onClick={() => setShowDetails(true)} title="Show warning details" className="inline-block border-2 text-xl text-gray-500 hover:bg-gray-500 hover:text-white w-6 h-6 ml-2 rounded cursor-pointer " />
                }
            </h2>
            <ShowIf if={showDetails}>
                <div className="flex gap-4">
                    <ShowIf if={bucket.model.categorization_name !== selectedTemplate?.categorization_name}>
                        <div>
                            <h3 className="font-semibold">Categorization is not right</h3>
                            <ul className="list-disc ml-4">
                                <li>Categorization is <i>{bucket.model.categorization_name}</i>, was expecting <i>{selectedTemplate?.categorization_name}</i></li>
                            </ul>
                        </div>
                    </ShowIf>
                    <ShowIf if={missingCategorizations.length > 0}>
                        <div>
                            <h3 className="font-semibold">Categorization is missing values:</h3>
                            <ul className="list-disc ml-4">
                                {
                                    missingCategorizations.map((v, i) => {
                                        return <li key={i}>{v}</li>
                                    })
                                }
                            </ul>
                        </div>
                    </ShowIf>
                    <ShowIf if={missingColumns.length > 0}>
                        <div>
                            <h3 className="font-semibold">Columns missing in this bucket:</h3>
                            <ul className="list-disc ml-4">
                                {
                                    missingColumns.map((v, i) => <li key={i}><i>{v}</i></li>)
                                }
                            </ul>
                        </div>
                    </ShowIf>
                    <ShowIf if={notRightTypeColumns.length > 0}>
                        <div>
                            <h3 className="font-semibold">Columns with wrong types:</h3>
                            <ul className="list-disc ml-4">
                                {
                                    notRightTypeColumns.map((v, i) => {
                                        return <li key={i}><i>{v.name}</i> is type <b>{bucket.model.columns.find(c => c.name === v.name)?.type ?? "???"}</b>, was expecting <b>{v.type}</b></li>
                                    })
                                }
                            </ul>
                        </div>
                    </ShowIf>
                    <ShowIf if={hiddenColumns.length > 0}>
                        <div>
                            <h3 className="font-semibold">Columns hidden in this bucket:</h3>
                            <ul className="list-disc ml-4">
                                {
                                    hiddenColumns.map((v, i) => <li key={i}><i>{v}</i></li>)
                                }
                            </ul>
                        </div>
                    </ShowIf>
                </div>
            </ShowIf>
        </div>
    )
}

export default OrganizationBucketSetupEditor;

export const OrganizationBucketSetupEditorWrapper = () => {
    const { cloudId, orgId, bucketId } = useParams();
    const [bucket, setBucket] = useState<BucketInfo>();
    const [templates, setTemplates] = useState<BucketTemplate[]>();
    useEffect(() => {
        getBucket();
    }, [cloudId, orgId, bucketId]);

    useEffect(() => {
        getTemplates();
    }, []);
    if (cloudId === undefined || orgId === undefined || bucketId === undefined)
        return (
            <h1>
                Something went wrong, missing parameters in URL - cloudId:{" "}
                {cloudId} - orgId: {orgId} - bucketId: {bucketId}
            </h1>
        );

    const getBucket = () => {
        get(`cloud/${cloudId}/buckets/${orgId}/${bucketId}`)
            .then((b: BucketInfo) => {
                setBucket(b);
            })
            .catch((e) => console.log(e));
    };

    const getTemplates = () => {
        get("cloud/" + cloudId + "/templates/buckets")
            .then((result) => {
                let tmpBuckets: BucketTemplate[] = result;
                setTemplates(tmpBuckets);
            })
            .catch((err) => console.log(err));
    };

    return bucket === undefined || templates === undefined ? (
        <>
            <h1>Loading...</h1>
        </>
    ) : (
        <OrganizationBucketSetupEditor
            chosenBucket={bucket}
            getBucket={getBucket}
            cloudId={Number(cloudId)}
            orgId={Number(orgId)}
            templates={templates}
        />
    );
};
