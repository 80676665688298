import { ActionCategoryColor } from "../types/template_types";

export const bsColorToTWMap : Map<ActionCategoryColor, string> = new Map([
    ["btn-primary", "bg-[#2E6CAC] hover:brightness-90"],
    ["btn-dark", "bg-[#304642] hover:brightness-90"],
    ["btn-danger", "bg-[#FF5656] hover:brightness-90"],
    ["btn-warning", "bg-[#E2BC41] hover:brightness-90"],
    ["btn-info", "bg-[#4BB6D8] hover:brightness-90"],
    ["btn-success", "bg-[#43B155] hover:brightness-90"],
    ["btn-dusty-purple", "bg-[#ccbde4] hover:brightness-90"],
    ["btn-dusty-green", "bg-[#c6cfb1] hover:brightness-90"],
    ["btn-orange", "bg-[#FF5A00] hover:brightness-90"],
    ["btn-gray", "bg-[#959595] hover:brightness-90"],
    ["btn-burgundy", "bg-[#861919] hover:brightness-90"],
    ["btn-brown", "bg-[#A77500] hover:brightness-90"],
])