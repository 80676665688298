import { APAAccount } from "../types/types";
import { Store } from "../contexts/StoreContext";
import { AccountAction } from "./actions";

export function accountReducer(state: Store, action: AccountAction) {
    switch (action.type) {
        case 'change_account': {
            const a: APAAccount[] = [...state.accounts]
            const index = a.findIndex(acc => action.id === acc.id)
            if (index > -1) {
                a.splice(index, 1, action.account)
            } else {
                a.push(action.account)
            }
            return a;
        }
        case 'set_accounts': {
            return action.accounts
        }
        default: {
            return state.accounts;
        }
    }
}
