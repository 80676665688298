import { useState, useEffect, useMemo, Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import AddEmail from "../components/Reporting/AddEmail";
import ReportingTable from "../components/Reporting/ReportingTable";
import { del, get, post, put } from "../helpers/Requests";
import { CloudReport, CloudReportMap, CloudUser } from "../types/cloud_types";
import { Report, Plan } from "../types/reporting_types";
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { ServerData} from "../types/types"
import ReportingFilterBar from "../components/Reporting/ReportingFilterBar";
import { getPlanInterval, getReportingStats } from "../helpers/reporting_helpers";

const ReportingReport: React.FC<{
    server: ServerData | undefined,
    reporting_reports: Report[],
    plans: Plan[],
    users: CloudUser[],
    update(): any,
    sendmail(id: number, email: string, test: boolean): void,
    add: boolean,
    setAdd: Dispatch<SetStateAction<boolean>>
}> = ({ server, reporting_reports, plans, users, update, sendmail, add, setAdd }) => {
    const { orgId } = useParams()
    const { setUp } = useSideBarMenuContext()
    const serverId = server?.id.toString()

    const [editReportEmail, setEditReportEmail] = useState<Report | null>(null);
    const [cloudReportsObj, setCloudReportsObj] = useState<CloudReportMap>()
    const [cloudReports, setCloudReports] = useState<CloudReport[]>([])

    const [shownReports, setShownReports] = useState<Report[]>([])

    const [searchText, setSearchText] = useState<string>("");
    const [filteredReports, setFilteredReports] = useState<Report[]>([]);

    const [planFilter, setPlanFilter] = useState<"D" | "W" | "M" | "A">("A")
    const [userFilter, setUserFilter] = useState<number|undefined>()

    const shownCloudUser = useMemo(() => users.find(u => u.id === userFilter), [userFilter, users])

    useEffect(() => {
        setUp("/reporting")
        get(`cloud/${server?.cloud}/reports/${orgId}`) // cloud reports are retrieved in order to display report name in the table
            .then((res: CloudReport[]) => {
                // making an object of all reports with id as key for faster lookup when rendering in table
                const _tmp: CloudReportMap = res.reduce((acc: CloudReportMap, cur) => { acc[cur.report_id] = cur; return acc }, {})
                setCloudReportsObj(_tmp)
                setCloudReports(res)
            }).catch(err => console.log(err))
    }, [orgId, setUp, server])

    useEffect(() => {
        setFilteredReports(reporting_reports)
        setShownReports(reporting_reports)
    }, [reporting_reports])

    useEffect(() => {
        search(searchText)
    }, [filteredReports, searchText])

    useEffect(() => {
        filterReports(reporting_reports)
    }, [planFilter, userFilter])

    const onClose = () => {
        setEditReportEmail(null)
        setAdd(false);
    }

    const onEdit = (report: Report) => {
        setEditReportEmail(report);
        setAdd(true)
    }

    const onDelete = (id: number) => {
        del(`reporting/${serverId}/report/${id}`).then(res => {
            update()
        }).catch(err => console.log(err))
    }

    const onDuplicate = (d: Report) => {
        d.id = 0
        post(`reporting/${serverId}/report`, d).then(res => {
            update()
        }).catch(err => console.log(err))
    }

    const onToggleActive =(d: Report) => {
        d.active = !d.active
        put(`reporting/${serverId}/report/${d.id}`, d).then(res => {
            update();
        }).catch(err => console.log(err))
    }

    const onSendMail = (d: Report, test:boolean) => {
        sendmail(d.plan_id?? 0, d.email, test)
    }

    function filterReports(reports : Report[]){
        if(reports.length === 0) return;
        let filtered = [...reports]
        switch(planFilter){
            case "D":
            case "W":
            case "M":
                filtered = filtered.filter(d => getPlanInterval(d.plan_id ?? -1, plans).startsWith(planFilter))
                setFilteredReports(filtered)
                break;
            case "A":
            default:
                // do nothing
        }
        if(userFilter && shownCloudUser){
            filtered = filtered.filter(d => d.email === shownCloudUser.email)
        }
        setFilteredReports(filtered)
        if(!searchText) setShownReports(filtered)
    }

    const search = (query:string) => {
        if (!query || query.length < 1) {
            setShownReports(filteredReports)
            return
        }

        const filtered = filteredReports.filter(searchFilterFunc)
        setShownReports(filtered)
    }

    const searchFilterFunc = (r: Report) => {
        if(!cloudReportsObj) return
        const name = cloudReportsObj[r.report_id].name ?? ""
        const nameMatch = name.toLowerCase().includes(searchText.toLowerCase())
        let recipientMatch = (r.firstname + " " + r.lastname).toLowerCase().includes(searchText.toLowerCase())
            
        return nameMatch || recipientMatch
    }


    return <div className="">
        <ReportingFilterBar
            items={reporting_reports}
            stats={getReportingStats(reporting_reports, plans)}
            shownItems={shownReports}
            users={users}
            searchText={searchText}
            setSearchText={setSearchText}
            selectedPlan={planFilter}
            setSelectedPlan={setPlanFilter}
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            setShowAddModal={setAdd}
            buttonColor="report"
        />
        <ReportingTable items={shownReports} plans={plans} color="report" cloudObj={cloudReportsObj ?? {}} onEdit={onEdit} onDelete={onDelete} onDuplicate={onDuplicate} onToggleActive={onToggleActive} onSendMail={onSendMail} />
        <AddEmail server={server} orgId={parseInt(orgId ?? "-1")} users={users} cloudItems={cloudReports} open={add} route="report" onClose={onClose} getEmailItems={update} edittedEmailItem={editReportEmail} plans={plans} />
    </div>
}

export default ReportingReport