import React, { useEffect, useState } from 'react'
import { HiArrowSmLeft, HiArrowSmRight } from 'react-icons/hi'
import { BucketColumnType, BucketTemplate } from '../../types/template_types'
import Button from '../Button'

interface TemplateColumnSelectProps {
    available: string[],
    selected: string[],
    selectColumns: (cols: string[]) => void,
    deselectColumns: (cols: string[]) => void,
    className?: string,
    bucket: BucketTemplate
}

const TemplateColumnSelect = ({available, selected, className = "", ...props}: TemplateColumnSelectProps) => {
    
    const [selAvailable, setSelAvailable] = useState<string[]>([])
    const [selSelected, setSelSelected] = useState<string[]>([])

    useEffect(() => {
        setSelAvailable([])
        setSelSelected([])
    }, [available, selected])

    const handleAvailChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let options = e.target.options
        let val: string[] = []
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) val.push(options[i].value)
        }
        setSelAvailable(val)
    }

    const handleSelectedChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let options = e.target.options
        let val: string[] = []
        for (let i = 0; i < options.length; i++) {
            if (options[i].selected) val.push(options[i].value)
        }
        setSelSelected(val)
    }

    const shownAvailable = available.filter(c => !selected.some(x => x === c)).sort((a, b) => a.localeCompare(b))
    const hasIdColumn = selected.some(v => props.bucket.columns.some(x => x.name === v && x.type === BucketColumnType.ID))

    return <div className='flex items-center gap-5'>
        <div className='flex flex-col'>
            <span className='text-gray-600 text-xs'>Available columns</span>
            <select multiple value={selAvailable} onDoubleClick={() => props.selectColumns(selAvailable)} onChange={e => handleAvailChange(e)} className={`text-sm h-[300px] min-w-[350px] ${className}`}>
                {
                    shownAvailable.map((c, i) => {
                        return <option key={i} value={c}>{c}</option>
                    })
                }
            </select>
        </div>
        <div className='flex flex-col gap-1'>
            <Button className='flex justify-center' disabled={selAvailable.length === 0} onClick={() => props.selectColumns(selAvailable)} icon={<HiArrowSmRight />} color='secondary' />
            <Button className='flex justify-center' disabled={selSelected.length === 0} onClick={() => props.deselectColumns(selSelected)} icon={<HiArrowSmLeft />} color='secondary' />
            <Button className='flex justify-center' disabled={shownAvailable.length === 0} onClick={() => props.selectColumns(shownAvailable)} text='All' iconPosition='right' icon={<HiArrowSmRight />} color='secondary' />
            <Button className='flex justify-center' disabled={selected.length === 0} onClick={() => props.deselectColumns(selected)} text='All' icon={<HiArrowSmLeft />} color='secondary' />
        </div>
        <div className='flex flex-col'>
            <span className='text-gray-600 text-xs'>Included columns {!hasIdColumn ? <b className='text-red-500'>Does not contain an ID column</b> : null}</span>
            <select multiple value={selSelected} onDoubleClick={() => props.deselectColumns(selSelected)} onChange={e => handleSelectedChange(e)} className={`text-sm h-[300px] min-w-[350px] ${!hasIdColumn ? 'border-red-500' : ''} ${className}`}>
                {
                    selected.map((c, i) => {
                        return <option key={i} value={c}>{c}</option>
                    })
                }
            </select>
        </div>
    </div>
}

export default TemplateColumnSelect
