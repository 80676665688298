import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, ReactNode, useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import CustomInput from "./CustomInput";

export interface ClickableMenuOption {
    label: string,
    onClick: () => void,
    icon?: ReactNode,
}

interface SearchableMenuProps {
    label: string,
    options: ClickableMenuOption[],
    className?: string,
}

const SearchableMenu = ({className = "", ...props}: SearchableMenuProps) => {

    const [searchString, setSearchString] = useState("")

    return <Menu as="div" className={`relative inline-block text-left ${className}`}>
        <div className="w-fit">
            <Menu.Button className="inline-flex w-full justify-center rounded-md bg-orange-500 px-4 py-2 text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                {props.label}
                <HiChevronDown
                    className="ml-2 -mr-1 h-5 w-5 text-orange-200 hover:text-orange-100"
                    aria-hidden="true"
                />
            </Menu.Button>
        </div>
        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items className="z-50 absolute left-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-2 border-b-gray-200 border-b">
                    <CustomInput label="Search" type={"text"} value={searchString} onChange={e => setSearchString(e.target.value ?? "")} />
                </div>
                <div className="flex flex-col overflow-y-scroll max-h-[400px] scroll-shadows">
                    {
                        props.options.filter(o => o.label.toLowerCase().includes(searchString.toLowerCase())).map((v, i) => {
                            return <div key={i} className="px-1 py-1">
                                <Menu.Item >
                                    {({ active }) => (
                                        <button
                                            className={`${active ? 'bg-orange-100 text-black' : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm text-left`}
                                            onClick={() => v.onClick()}
                                        >
                                            {v.label}
                                            {v.icon}
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        })
                    }
                </div>
            </Menu.Items>
        </Transition>
    </Menu>
}

export default SearchableMenu
