import React, { forwardRef, ReactNode } from "react"

interface InputProps extends React.ComponentPropsWithRef<"input"> {
    label?: ReactNode;
    password?: boolean;
    numeric?: boolean;
    error?: boolean;
    errorText?: string;
    search?: boolean;
}

const CustomInput = forwardRef<HTMLInputElement, InputProps>(({ label, className, password, numeric, readOnly, error, errorText, search, ...props }: InputProps, ref) => {
    return <label className="block w-full">
        {label ? <span className="text-gray-600 text-xs">{label}</span> : <></>}
        <input
            type={password ? "password" : numeric ? "number" : search ? "search" : "text"}
            className={`${className} ${label ? 'mt-1' : ""} text-sm py-1.5 block w-full border-2 focus:border-2 rounded-md ${error ? "border-red-300 focus:ring-red-300 focus:border-red-300 text-red-500" : "border-gray-300"} shadow-sm focus:ring-0 ${readOnly ? " focus:border-gray-300 text-gray-400" : "focus:border-tangerine-900 "}`}
            ref={ref}
            {...props}
        />
        {error && <span className="text-red-500 text-sm">{errorText}</span>}
    </label>

})

export default CustomInput
