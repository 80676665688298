import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HiHome, HiChevronRight } from "react-icons/hi";
import { get } from "../helpers/Requests";
import { BreadcrumbProps, getBreadcrumbs } from "../helpers/general_helpers";
import { CloudServerOrganization, ServerWrapper } from "../types/cloud_types";
import { HiUser } from "react-icons/hi";
import { useAppContext } from "../App";
import { APAServerAccounts, ServerData } from "../types/types";
import { BucketTemplate } from "../types/template_types";

const AppHeader = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useAppContext();
    const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbProps[]>([]);
    const [cloudServerOrganizations, setCloudServerOrganizations] = useState<CloudServerOrganization[]>([]);
    const [serverAccounts, setServerAccounts] = useState<APAServerAccounts[]>([])
    const [servers, setServers] = useState<ServerData[]>([]);
    const [cloudBucketTemplates, setCloudBucketTemplates] = useState<ServerWrapper<BucketTemplate>[]>([])

    useEffect(() => {
        // get("cloud/organizations")
        //     .then(orgs => setCloudOrganizations(orgs))
        //     .catch(err => console.log(err));

        get("servers")
            .then(servers => 
            {
                setServers(servers)
                const typedServers = servers as ServerData[]
                var apaAccs: APAServerAccounts[] = [];
                var cloudOrgs: CloudServerOrganization[] = [];
                var bucketTemplates: ServerWrapper<BucketTemplate>[] = []
                typedServers.forEach(server => {
                    if(server.type === "apa") {
                        get('apa/' + server.id +'/accounts')
                        .then(result => {
                            for (let i = 0; i < result.length; i++) {
                                result[i].serverId = server.id
                            }
                            const toPush: APAServerAccounts = {
                                server: server,
                                accounts: result,
                            };
                            apaAccs.push(toPush)
                        })
                        .catch(err => console.log(err));
                    }
                    if(server.type === "cloud") {
                        get('cloud/' + server.id +'/organizations')
                        .then(result => {
                            const toPush: CloudServerOrganization = {
                                server: server,
                                organizations: result,
                            };
                            cloudOrgs.push(toPush)
                        })
                        .catch(err => console.log(err));
                        get('cloud/' + server.id + "/templates/buckets")
                        .then((result: BucketTemplate[]) => {
                            result.forEach(v => {
                                bucketTemplates.push({value:v, server: server})
                            })
                        })
                        .catch(err => console.log(err))
                    }
                });
                setServerAccounts(apaAccs)
                setCloudServerOrganizations(cloudOrgs)
                setCloudBucketTemplates(bucketTemplates)

            })
            .catch(err => console.log(err));


    }, [])

    useEffect(() => {
        const p = location.pathname.substring(1, location.pathname.length).split("/");
        const _breadcrumbs: BreadcrumbProps[] = getBreadcrumbs(p, cloudServerOrganizations, serverAccounts, servers, cloudBucketTemplates)

        setBreadcrumbs(_breadcrumbs)
    }, [location.pathname, cloudServerOrganizations, serverAccounts, servers, cloudBucketTemplates]);

    return <div className="py-4 px-6 bg-white shadow-sm h-16 flex flex-row w-full justify-between items-center border-b border-gray-200">
        <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row gap-4 max-w-fit items-center text-gray-500">
                <div onClick={_ => navigate('/')} className="cursor-pointer hover:text-gray-600"><HiHome className="h-5 w-5" /></div>
                {breadcrumbs.length === 0 && <div className="font-semibold opacity-0">Home/</div>} {/* otherwise the logo makes a 1 pixel "jump" */}
                {
                    breadcrumbs.map((p, i) => <div className="flex items-center gap-4" key={i}>
                        <div><HiChevronRight className="h-5 w-5" /></div>
                        <div onClick={_ => navigate(p.path)} className="font-semibold cursor-pointer hover:text-gray-600">{p.name}</div>
                    </div>)
                }
            </div>
        </div>
        <div onClick={_ => navigate('/user')} className="flex flex-row items-center gap-2 text-gray-500 font-semibold hover:text-gray-700 cursor-pointer">
            <div className="">{user?.name}</div>
            <div><HiUser className="w-5 h-5" /></div>
        </div>
    </div>
}

export default AppHeader
