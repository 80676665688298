import React from "react";

const ShowIf = (props: {if: boolean, children: React.ReactNode}) => {
    return <>
        {
            props.if ?
                props.children
            : null
        }
    </>
}
export default ShowIf
