import { PropsWithChildren } from "react"

type TableBodyCellProps = {
    className?: string,
    onClick?: Function
    title?: string
}

const TableBodyCell = ({ children, className, onClick, title }: PropsWithChildren<TableBodyCellProps>) => {
    return <td title={title ?? ""} onClick={() => { if (onClick) { onClick() } }} className={`px-4 py-2 whitespace-nowrap ${className}`}>{children}</td>
}

export default TableBodyCell
