import { useEffect, useState } from "react";
import { FaArrowLeft, FaPencilAlt } from "react-icons/fa";
import { HiPencil } from "react-icons/hi";
import { compareObjects } from "../../helpers/type_helpers";
import { Bucket } from "../../types/bucket_types";
import { CloudOrgInfo } from "../../types/cloud_types";
import { CxmColumn, CxmSetup } from "../../types/cxm";
import Button from "../Button";
import ShowIf from "../ShowIf";
import EditCxmColumn from "./EditCxmColumn";
import { FaSave, FaUndo } from "react-icons/fa";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import { Modal } from "../Modals";
import CustomInput from "../CustomInput";
import { SaveCxmSetup } from "../../api/cxm.api";

interface props {
    setup: CxmSetup;
    saveSetup: (setup: CxmSetup) => void;
    buckets: Map<string, Bucket>;
    org: CloudOrgInfo;
    back: () => void;
}

const EditCxmSetup = ({ org, setup, buckets, ...props }: props) => {
    const [tmpSetup, setTmpSetup] = useState(setup);

    const [showEditName, setShowEditName] = useState(false);
    const [tmpName, setTmpName] = useState("");

    const { confirm, ConfirmDialog } = useConfirmDialog();

    const updateColumn = (index: number) => (f: (col: CxmColumn) => void) => {
        const tmp = structuredClone(tmpSetup);
        try {
            f(tmp.columns[index]);
        } catch (e) {
            console.error(e);
            return;
        }

        if (tmpSetup.columns[index].bucket != tmp.columns[index].bucket) {
            // for (let i = 0; i < tmp.columns.length; i++) {
            //     // If a foreign key refers to a bucket that is not in use, we remove it
            //     if (tmp.columns[i].foreign_bucket == tmpSetup.columns[index].bucket) {
            //         tmp.columns[i].foreign_bucket = ""
            //         tmp.columns[i].foreign_key = ""
            //     }
            // }
            tmp.columns.forEach((c, i) => {
                if (
                    c.foreign_bucket == tmpSetup.columns[index].bucket &&
                    !tmp.columns.some((c2) => c2.bucket == c.foreign_bucket)
                ) {
                    tmp.columns[i].foreign_bucket = "";
                    tmp.columns[i].foreign_key = "";
                }
            });
        }
        setTmpSetup(tmp);
    };

    const onBack = async () => {
        if (hasChanges) {
            if (!(await confirm(
                "You have unsaved changes that will be lost, are you sure?",
                {title: "Are you sure?", declineText: "Cancel"}
            ))) return
        }
        props.back();
    };

    const onColumnRemove = (index: number) => {
        let tmp = structuredClone(tmpSetup);
        const col = tmp.columns.splice(index, 1)[0];
        tmp.columns.forEach((c, i) => {
            if (c.foreign_bucket == col.bucket && !tmp.columns.some((c2) => c2.bucket == c.foreign_bucket)) {
                tmp.columns[i].foreign_bucket = "";
                tmp.columns[i].foreign_key = "";
            }
        });
        setTmpSetup(tmp);
    };

    const onSave = async () => {
        if (disableSave()) return
        props.saveSetup(tmpSetup)
    };

    const addColumn = () => {
        let tmp = structuredClone(tmpSetup)
        const col: CxmColumn ={
            bucket: "",
            categorizationColumn: "",
            categorizationColumn2: "",
            id_column: "",
            foreign_bucket: "",
            foreign_key: ""
        }

        tmp.columns.push(col)
        setTmpSetup(tmp)
    }

    const onUndo = async () => {
        if (!(await confirm("Undo changes?"))) return;
        setTmpSetup(structuredClone(setup));
    };

    const hasChanges = !compareObjects(tmpSetup, setup);

    const disableSave = () => {
        if (!hasChanges) return true;
        return tmpSetup.columns.some((c) => {
            return c.bucket == "" || c.id_column == "" || c.categorizationColumn == "" || (c.foreign_bucket != "" && c.foreign_key == "");
        }) || tmpSetup.columns.length == 0;
    };

    const onSaveName = () => {
        if (tmpName == "") return
        let tmp = structuredClone(tmpSetup);
        tmp.name = tmpName;
        setTmpSetup(tmp);
        setShowEditName(false)
    };

    return (
        <>
            <button onClick={onBack} className="flex text-sm items-center gap-1 text-gray-500 hover:text-black w-fit">
                <FaArrowLeft /> Back
            </button>

            <div className="flex justify-between items-center border-b border-slate-200 mb-2">
                <button
                    className="flex items-center text-gray-500 text-lg gap-2"
                    onClick={() => {
                        setTmpName(tmpSetup.name);
                        setShowEditName(true);
                    }}
                >
                    <h1>{tmpSetup.name}</h1>
                    <HiPencil />
                </button>
                <div className="flex gap-2 p-1">
                    <Button
                        onClick={onSave}
                        disabled={disableSave()}
                        icon={<FaSave />}
                        text="Save"
                        color="primary"
                        className="w-fit"
                    />
                    <Button
                        onClick={onUndo}
                        disabled={!hasChanges}
                        icon={<FaUndo />}
                        text="Undo"
                        color="secondary"
                        className="w-fit "
                    />
                </div>
            </div>

            <div className="grid grid-cols-3 gap-1">
                {tmpSetup.columns.map((c, i) => {
                    return (
                        <EditCxmColumn
                            key={i}
                            column={c}
                            updateColumn={updateColumn(i)}
                            buckets={buckets}
                            org={org}
                            setup={tmpSetup}
                            onRemove={() => onColumnRemove(i)}
                        />
                    );
                })}
                <ShowIf if={tmpSetup.columns.length < 3}>
                    <div className="flex justify-center items-center">
                        <Button color="success" onClick={addColumn}>Add column</Button>
                    </div>
                </ShowIf>
            </div>
            <ConfirmDialog />

            <Modal
                isOpen={showEditName}
                onClose={() => setShowEditName(false)}
                onAction={onSaveName}
                title="Edit name"
                actionText="Save"
                closeText="Cancel"
                size="small"
                disableAction={tmpName === ""}
            >
                <form className="mt-3" onSubmit={e => {e.preventDefault(); onSaveName()}}>
                    <label htmlFor="name-input">Name</label>
                    <CustomInput
                        id="name-input"
                        value={tmpName}
                        onChange={(e) => setTmpName(e.target.value)}
                        placeholder="Insert name..."
                    />
                </form>
            </Modal>
        </>
    );
};

export default EditCxmSetup;
