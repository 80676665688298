import { PropsWithChildren } from "react"

type TableRowProps = {
    className?: string,
}

const TableRow = ({ children, className }: PropsWithChildren<TableRowProps>) => {
    return <tr className={`${className}`}>{children}</tr>
}

export default TableRow