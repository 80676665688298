import { Transition } from "@headlessui/react"
import { Fragment, useCallback, useState } from "react"
import { post } from "../../helpers/Requests"
import { APASetup } from "../../types/types"
import Button from "../Button"
import Select from "../Select"
import TimeSelect from "../Reporting/TimeSelect"
import DaySelect from "../Reporting/DaySelect"

const AddSchedule: React.FC<{
    serverId: string,
    open: boolean,
    onClose(): any,
    setups: APASetup[],
}> = ({ serverId, open, onClose, setups }) => {

    const [hour, setHour] = useState<number>(0)
    const [minute, setMinute] = useState<number>(0)
    const [maxDurationHours, setMaxDurationHours] = useState<number>(1)
    const [maxDurationMinutes, setMaxDurationMinutes] = useState<number>(0)
    const [selectedSetup, setSelectedSetup] = useState<APASetup | null>(null)
    const [selectedDays, setSelectedDays] = useState<boolean[]>([true, true, true, true, true, true, true])

    const doSetHour = (h: number) => {
        h = Math.min(23, Math.max(0, h))
        setHour(h)
    }

    const doSetMinute = (m: number) => {
        m = Math.round(m)
        setMinute(m)
    }

    const doSetMaxDurationHours = (d: number) => {
        d = Math.round(d)
        setMaxDurationHours(d)
    }

    const doSetMaxDurationMinutes = (d: number) => {
        d = Math.round(d)
        setMaxDurationMinutes(d)
    }

    const doSetSelectedSetup = (id: number) => {
        const s = setups.find(s => s.id === id)
        if (!s) return;
        setSelectedSetup(s)
    }

    
    const onCloseLocal = useCallback(() => {
        onClose();
        setTimeout((_: any) => {
        }, 300) // wait for aninmation to finish
    }, [onClose])

    const addSchedule = useCallback(() => {
        if (!selectedSetup) return;
        let maxDurationSeconds = maxDurationHours * 3600 + maxDurationMinutes * 60

        post(`apa/${serverId}/schedules/setup/${selectedSetup?.id}`,
            { hour: hour, minute: minute, max_duration: maxDurationSeconds, days: selectedDays })
            .then(res => {
                onCloseLocal();
            })
            .catch(err => console.log(err))
    }, [selectedSetup, hour, minute, maxDurationHours, maxDurationMinutes, selectedDays, onCloseLocal, serverId])


    return <Transition
        as={Fragment}
        show={open}
        enter="transform transition duration-300"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95 "
    >
        <div className="fixed right-4 top-[220px] z-40 text-sm rounded-lg shadow-2xl border bg-white border-gray-300">
            <div className="w-[500px]">
                <div className="p-4 border-b border-gray-200">
                    <div className="font-semibold text-base">Add schedule</div>
                </div>
                <div className="flex flex-col p-4 gap-2">
                    <div>
                        <Select label="Setup" onChange={e => doSetSelectedSetup(Number(e.target.value))} value={selectedSetup?.id || ''}>
                            <option value="">Select setup</option>
                            {setups.map(setup => <option key={setup?.id} value={setup?.id}>{setup?.description}</option>)}
                        </Select>
                    </div>
                    <div>
                        <label className="text-gray-600 text-xs">Time</label>
                        <TimeSelect hourValue={hour} minuteValue={minute}
                            onChangeHour={doSetHour}
                            onChangeMinute={doSetMinute}
                            stopwatch={false} />
                    </div>
                    <div >
                        <label className="text-gray-600 text-xs">Max Duration</label>
                        <TimeSelect hourValue={maxDurationHours} minuteValue={maxDurationMinutes}
                            onChangeHour={doSetMaxDurationHours}
                            onChangeMinute={doSetMaxDurationMinutes}
                            stopwatch={true} />
                    </div>
                    <div >
                        <label className="text-gray-600 text-xs">Set Days</label>
                        <DaySelect selectedDays={selectedDays} onChangeSelectedDays={setSelectedDays} />
                    </div>
                </div>
                <div className="bg-gray-50 border-t border-gray-300 rounded-br-lg rounded-bl-lg">
                    <div className="p-4 flex flex-row-reverse gap-2">
                        <div><Button disabled={selectedSetup === null} text="Save" color="primary" onClick={addSchedule} /></div>
                        <div><Button text="Cancel" color="secondary" onClick={onCloseLocal} /></div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
}

export default AddSchedule