import { Transition } from "@headlessui/react";
import { Fragment, PropsWithChildren } from "react";

interface ReportingModalProps extends PropsWithChildren{
    open: boolean
    className?: string
}

function ReportingModal(props:ReportingModalProps){
    return (
        <Transition
            as={Fragment}
            show={props.open}
            enter="transform transition duration-300"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95 "
        >
            <div className="absolute z-[9999] inset-0 m-auto bg-black bg-opacity-30 flex justify-center items-center">
            <div className={`text-sm rounded-lg shadow-2xl border bg-white border-gray-300 p-4 ${props.className ? props.className : ""}`}>
                {props.children}
            </div>
            </div>
        </Transition>
    )

}

export default ReportingModal