import { ReactNode, useEffect, useState } from 'react'

export type customButton = {
    text: string,
    onClick: Function,
    cssClass?: string
}

export type AlertBannerProps = {
    type: "danger" | "warning" | "success",
    title: string | null,
    text: ReactNode | string  | null,
    onAction?: any,
    onClose?: any,
    actionText?: string,
    closeText?: string,
    customButtons?: customButton[]
} 

function AlertBanner (props: AlertBannerProps) {

    const warningConfig = {
        bg: "bg-yellow-100",
        text: "text-yellow-800",
        border: "border-yellow-300",
        closeBtn: `text-yellow-800 border-yellow-800 hover:bg-yellow-600 focus:ring-${() => getAlertColor()}-300`,
        activeBtn: "bg-yellow-800 hover:bg-yellow-900 focus:ring-yellow-300"
    }

    const dangerConfig = {
        bg: "bg-red-100",
        text: "text-red-800",
        border: "border-red-300",
        closeBtn: `text-red-800 border-red-800 hover:bg-red-600 focus:ring-${() => getAlertColor()}-300`,
        activeBtn: "bg-red-800 hover:bg-red-900 focus:ring-red-300"
    }
    const successConfig = {
        bg: "bg-green-100",
        text: "text-green-800",
        border: "border-green-300",
        closeBtn: `text-green-800 border-green-800 hover:bg-green-600 focus:ring-${() => getAlertColor()}-300`,
        activeBtn: "bg-green-800 hover:bg-green-900 focus:ring-green-300"
    }

    const getAlertColor = () => {
        switch (props.type) {
            case "success":
                return successConfig
            case "danger":
                return dangerConfig
            case "warning":
                return warningConfig

            default:
                return warningConfig

        }
    }

    const [type, setType] = useState("warning")
    const [cssConfig, setCssConfig] = useState(getAlertColor())

    useEffect(() => {
        if (props.type !== type) {
            setCssConfig(getAlertColor())
            setType(props.type)
        }
    }, [cssConfig, type, props.type],)


    return <>
   
        <div id="alert-additional-content-2" className={`flex items-center justify-between px-4 py-2 border rounded-lg ${cssConfig.bg} ${cssConfig.text} ${cssConfig.border}` } role="alert">
            <div className={`flex items-center w-full mr-3 justify-around`}>
                <h3 className={`text-md font-medium w-full max-w-fit mr-3`}>{props.title}</h3>
            <div className="text-md w-full">
                {props.text}
            </div>
            </div>
            <div className="flex w-full max-w-fit">
                {!!props.onClose && !!props.closeText && <button type="button" onClick={props.onClose} className={` mr-2 bg-transparent border hover:text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 text-center ${cssConfig.closeBtn}`} aria-label="Close">
                    {props.closeText}
                </button>}
                {!!props.onAction && !!props.actionText && <button type="button" onClick={props.onAction} className={`max-w-fit text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 mr-2 text-center inline-flex items-center ${cssConfig.activeBtn}`}>
                   
                    {props.actionText}
                </button>}
                {!!props.customButtons &&

                    props.customButtons.map((b: customButton, index: number) => {
                        return <button key={index} className={`max-w-fit text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-1.5 mr-2 text-center inline-flex items-center ${b.cssClass} `} onClick={() => b.onClick()}>{b.text}</button>
                    })}
            </div>
        </div>
    </>
}

export default AlertBanner
