import CustomCheckbox from "../Buttons/CustomCheckbox"
import CustomRadioGroup from "../Buttons/CustomRadioGroup"
import CustomRadioOption from "../Buttons/CustomRadioOption"
import { comparePermissionsToRoles, getConditionalPermissions, mapRoletoPermissions, Permissions, PermissionCheckbox, getConditionalSimpleView, mapPermChecksToPermList, isDataAdmin, mapPermListToPermChecks, IPermissions } from "../../helpers/permissions"
import { useState, useEffect } from "react"
import { CloudOrgInfo, CloudUser } from "../../types/cloud_types"
import CustomSwitch from "../CustomSwitch"

type MultiEdit = boolean;

type ConditionalProps = {
    user: CloudUser,
    defaultRole: string,
    getPermissionsHandler?: Function,
} | {
    user?: never,
    defaultRole?: never,
    getPermissionsHandler: Function,
}

type CommonProps = {
    multiEdit: MultiEdit,
    permissionsChangedHandler: Function,
    org: CloudOrgInfo
}

type EditPermissionsProps = CommonProps & ConditionalProps

type PermGroup = {[group: string]: {[perm: string]: {text: string, help: string}}}

function EditPermissions(props: EditPermissionsProps) {
    const roles = [
        { value: "admin", label: "Admin" },
        { value: "super_user", label: "Super User" },
        { value: "user", label: "User" },
        { value: "custom", label: "Custom" },
        { value: "reporting_only", label: "Reporting Service" }
    ]
    const permissions: PermGroup = {
        dashboard: {
            dashboardadmin: { text: "Administrate Dashboards", help: "can manage and edit dashboards" },
            dashboard: { text: "View Dashboards", help: "can view dashboards" },
        },
        report: {
            reportadmin: { text: "Administrate Insights", help: "can administrate Insights for the entire organisation. (Includes: Edit mode)" },
            reportcreate: { text: "Create Insights", help: "can create private Insights only for themselves." },
            report: { text: "View Insights", help: "can view Insights." }
            ,
        },
        checklists: {
            checklistsadmin: { text: "Administrate Actions", help: "administrate Actions for the organisation. The total checklist overview for the organisation is visible. " },
            checklistscreate: { text: "Create Actions", help: "can create Actions for themselves and assign them to people. Other people's Actions are not visible. " },
            checklists: { text: "View Actions", help: "can view Actions. " },
        },
        end2end: {
            e2eadmin: {text: "Administrate End2End views", help: "can administrate End2End views for the organization."},
            e2e: {text: "View End2End views", help: "can view End2End views, but not edit them permanently"}
        },
        other: {
            download: { text: "Download Data", help: "can download data." },
            orgadmin: { text: "Administrate Organization", help: "can administrate their organization." }
        },
    }
    if (!props.org.cxm_enabled) {
        delete permissions.end2end
    }
    const [checkedPerms, setCheckedPerms] = useState<IPermissions>(props.multiEdit ? mapPermListToPermChecks(props.getPermissionsHandler ? props.getPermissionsHandler() : null) : props.defaultRole && (!props.user?.permissions || props.user.permissions.length === 0) ? mapRoletoPermissions(props.defaultRole) : mapPermListToPermChecks(props.user ? props.user?.permissions : []))
    const [activeRole, setActiveRole] = useState(Object.keys(comparePermissionsToRoles(checkedPerms))[0])
    const [simpleView, setSimpleView] = useState(props.multiEdit ? false : props.user?.permissions?.includes("simple_menu") ? true : false)

    useEffect(() => {
        if (activeRole === "reporting_only") {
            setSimpleView(false)
            props.permissionsChangedHandler(["reportingserviceonly"])
        } else {

            const permissions = mapPermChecksToPermList(checkedPerms)
            permissions.push("itemaccess")

            if (!props.multiEdit) {

                if (isDataAdmin(props.user ? props.user.permissions : [])) {
                    permissions.push("dataadmin")
                }
            }

            if (simpleView) {
                permissions.push("simple_menu")
            }

            props.permissionsChangedHandler(permissions)

        }

    }, [checkedPerms])

    function formFieldChangeHandler(value: string) {
        const permissions = mapRoletoPermissions(value)
        setCheckedPerms(permissions)
        setActiveRole(value)
    }

    function handlePermissionCheck(perm: Permissions) {
        let newCheckedPerms

        newCheckedPerms = getConditionalPermissions(checkedPerms, perm, simpleView)

        const role = comparePermissionsToRoles(newCheckedPerms)
        setActiveRole(Object.keys(role)[0])
        setCheckedPerms(newCheckedPerms)
    }

    function simpleViewSwitchChangeHandler(checked: boolean) {
        setSimpleView(checked)
        if (checked) {
            const newPerms = getConditionalSimpleView(checkedPerms)

            const role = comparePermissionsToRoles(newPerms)
            setActiveRole(Object.keys(role)[0])
            setCheckedPerms(newPerms)
        } else {
            const role = comparePermissionsToRoles(checkedPerms)
            let roleName = Object.keys(role)[0]
            roleName = roleName === "simple_menu" ? "user" : roleName
            let newPerms = mapRoletoPermissions(roleName)
            setActiveRole(roleName)

            newPerms = {
                ...newPerms,
                report: checkedPerms.report,
                checklists: checkedPerms.checklists,
                dashboard: checkedPerms.dashboard,
            }

            setCheckedPerms(newPerms)
        }
    }

    return (
        <div className='permission-tab'>
            <div className="w-full">
                <div className="flex w-full justify-between">
                    <div className="w-full max-w-md">
                        <div className="flex mb-3">
                            <span className="mr-2 ">Simple view</span>
                            <CustomSwitch enabled={simpleView && activeRole !== "reporting_only"} onChange={(e) => simpleViewSwitchChangeHandler(e.valueOf())}></CustomSwitch>
                        </div>
                        <h2 className="edit-user-headline-2 font-extrabold">Roles</h2>
                        <CustomRadioGroup label="" value={activeRole} className="border-none" onChange={(e: any) => { formFieldChangeHandler(e) }}>
                            {roles.map((role) => {
                                return <CustomRadioOption disabled={(simpleView && role.value !== "user" && activeRole === "user") || (simpleView && role.value !== "custom" && activeRole === "custom") || (!simpleView && role.value === "custom" && activeRole !== "custom")} key={role.value} value={role.value} text={role.label}  ></CustomRadioOption>
                            })}
                        </CustomRadioGroup>
                    </div>
                    <div className="w-full">
                        {!props.multiEdit && <h2 className="edit-user-headline-2">{props.user?.firstname} can...</h2>}
                        {Object.entries(permissions).map(([k, perm], i) => (
                            <div key={k} className={`w-full ${Object.keys(permissions).length > i + 1 ? "border-b" : ""} mb-3`}>
                                {Object.entries(perm).map(([key, value]) => (
                                    <span key={key}><CustomCheckbox type={checkedPerms[key as Permissions] === PermissionCheckbox.PRECHECKED ? "prechecked" : "checked"} value={checkedPerms[key as Permissions] === PermissionCheckbox.CHECKED || checkedPerms[key as Permissions] === PermissionCheckbox.PRECHECKED ? true : false} onChange={() => handlePermissionCheck(key as Permissions)} text={value.text} disabled={checkedPerms[key as Permissions] === PermissionCheckbox.DISABLED || activeRole === "reporting_only"}></CustomCheckbox><label className="org-admin-checkbox" key={key}>
                                    </label>
                                    </span>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditPermissions
