import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { ActionTemplate } from "../../types/template_types"
import Select from "react-select"
import CustomCheckbox from "../Buttons/CustomCheckbox"

interface ActionTemplateKPISetupProps{
    tmpAction: ActionTemplate
    setTmpAction: Dispatch<SetStateAction<ActionTemplate|undefined>>
}

function ActionTemplateKPISetup(props:ActionTemplateKPISetupProps) {
    const [enableKPISelect, setEnableKPISelect] = useState<boolean>(props.tmpAction.selected_kpi !== "" && !!props.tmpAction.selected_kpi)
    const [selectedKPI, setSelectedKPI] = useState<string>(props.tmpAction.selected_kpi ?? "")

    useEffect(() => {
        if(!enableKPISelect){
            setSelectedKPI("")
        }
    }, [enableKPISelect])
    
    useEffect(() => {
        const updated = structuredClone(props.tmpAction)
        updated.selected_kpi = selectedKPI
        props.setTmpAction(updated)  
    }, [selectedKPI])
    
    return <>
        <h2 className='border-b border-slate-200 mt-5 mb-2 text-gray-500 text-lg'>KPI</h2>
        <div className="flex items-center">
            <CustomCheckbox 
                onChange={(bool) => setEnableKPISelect(bool)}
                value={enableKPISelect}
                className={"w-4"}
            />
            <span className="text-sm font-semibold w-20">Show KPI</span>
            <Select
                isDisabled={!enableKPISelect}
                isSearchable={true}
                isClearable={true}
                value={selectedKPI.length > 0 ? {label: selectedKPI, value: selectedKPI} : null}
                options={props.tmpAction.columns.map(c => ({label: c, value: c}))}
                onChange={(o, ) => {if(o) setSelectedKPI(o.value)}}
                placeholder={"Select KPI"}
                className="w-80"
            />
        </div>
    </>
}

export default ActionTemplateKPISetup