import { FaTrash } from "react-icons/fa";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import { Bucket, BucketColumnType } from "../../types/bucket_types";
import { CloudOrgInfo } from "../../types/cloud_types";
import { CxmColumn, CxmSetup } from "../../types/cxm";
import Select from "../Select";

interface props {
    org: CloudOrgInfo;
    column: CxmColumn;
    updateColumn: (f: (col: CxmColumn) => void) => void;
    buckets: Map<string, Bucket>;
    setup: CxmSetup;
    onRemove: () => void;
}

const EditCxmColumn = ({ column, updateColumn, org, buckets, setup, onRemove }: props) => {
    const bucket = buckets.get(column.bucket);
    const foreignBucket = buckets.get(column.foreign_bucket);

    const { confirm, ConfirmDialog } = useConfirmDialog();

    const updateBucket = (bucketId: string) => {
        const b = buckets.get(bucketId);
        if (b == undefined) return;
        const idCol = b.info.model.columns.find((c) => c.type == BucketColumnType.ID);
        updateColumn((c) => {
            c.bucket = bucketId;
            c.categorizationColumn = b.info.model.categorization_name;
            if (idCol != undefined) {
                c.id_column = idCol.name;
            }
        });
    };

    const onRemoveClick = async (override = false) => {
        if (!override && !(await confirm("Are you sure you want to remove this column?"))) return;
        onRemove();
    };

    const otherColumnBuckets = setup.columns
        .filter((c) => c != column)
        .map((c) => buckets.get(c.bucket))
        .filter(Boolean) as Bucket[];

    const unusedBuckets = Array.from(buckets.values()).filter(b => !otherColumnBuckets.includes(b))

    if (bucket == undefined) {
        return (
            <div className="p-3 border rounded bg-white overflow-hidden">
                {column.bucket == "" ? <>
                    <div className="flex justify-between items-center">
                        <h1>{"No bucket"}</h1>
                        <button title="Remove column" onClick={() => onRemoveClick(true)} className="text-gray-500 hover:text-red-500">
                            <FaTrash />
                        </button>
                    </div>
                    <Select label="Bucket" value={column.bucket} onChange={(e) => updateBucket(e.target.value)}>
                        <option value={""}>None (not valid)</option>
                        {unusedBuckets
                            .sort((a, b) => a.info.name.toLowerCase().localeCompare(b.info.name.toLowerCase()))
                            .map((b, i) => {
                                return (
                                    <option value={b.id} key={b.id + i}>
                                        {b.info.name}
                                    </option>
                                );
                            })}
                    </Select>
                </> : (
                    <h2>Bucket could not be found: {column.bucket}</h2>
                )}
            </div>
        );
    }

    const updateIDColumn = (col: string) => {
        updateColumn((c) => {
            c.id_column = col;
        });
    };

    const updateForeignBucket = (bucketId: string) => {
        if (bucketId == "") {
            updateColumn((c) => {
                c.foreign_bucket = "";
                c.foreign_key = "";
            });

            return;
        }
        const b = buckets.get(bucketId);
        if (b == undefined) return;
        const idCol = b.info.model.columns.find((c) => c.type == BucketColumnType.ID);
        updateColumn((c) => {
            c.foreign_bucket = bucketId;
            c.foreign_key = idCol?.name ?? "";
        });
    };

    const updateForeignKey = (key: string) => {
        updateColumn((c) => {
            c.foreign_key = key;
        });
    };

    const updateCategorization1 = (cat: string) => {
        updateColumn((c) => {
            c.categorizationColumn = cat;
        });
    };

    const updateCategorization2 = (cat: string) => {
        updateColumn((c) => {
            c.categorizationColumn2 = cat;
        });
    };


    // .filter(Boolean) removes all elements that are falsy, such as undefined and null

    return (
        <div className="p-3 border rounded bg-white overflow-hidden">
            <div className="flex justify-between items-center">
                <h1>{bucket.info.name}</h1>
                <button title="Remove column" onClick={() => onRemoveClick()} className="text-gray-500 hover:text-red-500">
                    <FaTrash />
                </button>
            </div>
            <Select label="Bucket" value={column.bucket} onChange={(e) => updateBucket(e.target.value)}>
                <option value={""}>None (not valid)</option>
                {unusedBuckets
                    .sort((a, b) => a.info.name.toLowerCase().localeCompare(b.info.name.toLowerCase()))
                    .map((b, i) => {
                        return (
                            <option value={b.id} key={i}>
                                {b.info.name}
                            </option>
                        );
                    })}
            </Select>
            <Select
                label="Aggregation Column"
                value={column.id_column}
                onChange={(e) => updateIDColumn(e.target.value)}
            >
                <option value={""}>None (not valid)</option>
                {bucket.info.model.columns
                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    .map((c) => {
                        return (
                            <option value={c.name} key={c.name}>
                                {c.name}
                            </option>
                        );
                    })}
            </Select>

            <hr className="border-b-gray-500 m-2" />

            <Select
                label="Foreign Bucket"
                value={column.foreign_bucket}
                onChange={(e) => updateForeignBucket(e.target.value)}
            >
                <option value={""}>None</option>
                {otherColumnBuckets.map((b, i) => {
                    return (
                        <option value={b.id} key={i}>
                            {b.info.name}
                        </option>
                    );
                })}
            </Select>
            <Select
                label="Foreign Key"
                value={column.foreign_key}
                onChange={(e) => updateForeignKey(e.target.value)}
                disabled={column.foreign_bucket == ""}
            >
                <option value={""}>None</option>
                {foreignBucket?.info.model.columns
                    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                    .map((c) => {
                        return (
                            <option value={c.name} key={c.name}>
                                {c.name}
                            </option>
                        );
                    }) ?? []}
            </Select>

            <hr className="border-b-gray-500 m-2" />

            <Select
                label="Primary Categorization"
                value={column.categorizationColumn}
                onChange={(e) => updateCategorization1(e.target.value)}
            >
                <option value={""}>None (not valid)</option>
                {bucket.info.model.categorizations.map((c,i) => {
                    return <option value={c.name} key={i}>{c.name}</option>;
                })}
            </Select>
            <Select
                label="Secondary Categorization"
                value={column.categorizationColumn2}
                onChange={(e) => updateCategorization2(e.target.value)}
            >
                <option value={""}>None</option>
                {bucket.info.model.categorizations.map((c,i) => {
                    return <option value={c.name} key={i}>{c.name}</option>;
                })}
            </Select>

            <ConfirmDialog />
        </div>
    );
};

export default EditCxmColumn;
