
import React, { useEffect, useRef, useState } from 'react'
import { HiOutlineTrash } from 'react-icons/hi'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import CustomInput from '../CustomInput'

export interface BucketTemplateRowProps {
    id: number,
    value: string,
    onSave: (id: number, value: string) => void,
    onDelete: (id: number) => void,
    onCancel?: (id: number) => void,
    focus?: boolean,
}


const BucketTemplateValueRow = ({value, focus = false, ...props }: BucketTemplateRowProps) => {
    
    const [tmpName, setTmpName] = useState("")

    const [editName, setEditName] = useState(false)

    const rowRef = useRef<any>()

    useEffect(() => {
        setTmpName(value)

        if (focus) {
            setEditName(true)
        }
    }, [value, focus])

    useEffect(() => {
        if (editName && rowRef?.current) {
            if (focus) {
                rowRef.current.select()
            } else {
                rowRef.current.focus()
            }
        }
    }, [editName, rowRef])

    const onClose = () => {
        if (tmpName !== "") {
            props.onSave(props.id, tmpName)
        } else {
            setTmpName(value)
        }

        setEditName(false)
    }

    useOnClickOutside(() => {
        onClose()
    }, rowRef)

    // onKeyPress does not register "Escape", so we use onKeyDown
    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // If the enter key has been pressed, save the target
        if (e.key === "Enter") {
            onClose()
        }

        // If the escape key has been pressed, cancel
        if (e.key === "Escape") {
            setTmpName(value)
            setEditName(false)
            if (props.onCancel !== undefined) {
                props.onCancel(props.id)
            }
        }
    }


    return <div style={{justifyContent: "stretch"}} className='flex'>
        <div onClick={() => setEditName(true)} className='w-full flex items-center px-4 py-2 whitespace-nowrap cursor-pointer hover:bg-gray-200'>
            {editName ? 
            <div>
                <CustomInput onKeyDown={onKeyPress} value={tmpName} onChange={e => setTmpName(e.target.value)} ref={rowRef} />
            </div> 
            :
            <span>{value}</span>}
        </div>
        <div className='flex items-center justify-center shrink-0 w-[54px]'>
            <HiOutlineTrash onClick={() => props.onDelete(props.id)} title='Delete column' className='w-5 h-5 cursor-pointer hover:stroke-red-600' />
        </div>
    </div>
}

export default BucketTemplateValueRow
