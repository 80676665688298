import React from "react"

interface TableHeadCellProps extends React.ComponentPropsWithoutRef<"th"> {}

const TableHeadCell: React.FC<TableHeadCellProps> = ({ children, className, ...props }) => {
    return <th {...props} className={`px-4 py-3 text-left text-xs font-medium uppercase tracking-wider ${className}`}>
        {children}
    </th>
}

export default TableHeadCell