import { useEffect, useState } from "react"
import { HiOutlinePlus } from "react-icons/hi"
import { compareObjects } from "../../helpers/type_helpers"
import { BucketTemplateColumn } from "../../types/template_types"
import Button from "../Button"
import { Modal } from "../Modals"
import BucketTemplateValueRow from "./BucketTemplateCategory"

interface BucketTemplateRowValuesDialogProps {
    show: boolean,
    onClose: () => void,
    column: BucketTemplateColumn,
    onSave: (values: string[]) => void,
}

const BucketTemplateRowValuesDialog = ({...props}: BucketTemplateRowValuesDialogProps) => {
    const [tmpValues, setTmpValues] = useState<string[]>([])
    const [valueToAdd, setValueToAdd] = useState<string | undefined>()

    useEffect(() => {
        setTmpValues(props.column.values)
    }, [props.column])

    const addValue = (val: string) => {
        let tmp = structuredClone(tmpValues)
        tmp.push(val)
        setTmpValues(tmp)
        setValueToAdd(undefined)
    }

    const onSaveValue = (id: number, val: string) => {
        let tmp = structuredClone(tmpValues)
        tmp[id] = val
        setTmpValues(tmp)
    }

    const onDeleteValue = (id: number) => {
        let tmp = structuredClone(tmpValues)
        tmp.splice(id, 1)
        setTmpValues(tmp)
    }

    const onClose = () => {
        if (!disableSave()) return
        props.onClose()
    }

    const onSave = () => {
        props.onSave(tmpValues)
    }

    const disableSave = () => compareObjects(tmpValues, props.column.values) && valueToAdd === undefined

    return <>
        <Modal disableAction={disableSave()} isOpen={props.show} onClose={onClose} onAction={onSave} title={props.column.name} actionText="Save" closeText="Cancel" >
        <div className="mx-4 flex flex-col gap-4 mt-4 mb-4">
            <div className='flex flex-col border rounded-lg divide-y divide-gray-200 overflow-auto'>
                <div style={{justifyContent: "stretch"}} className='flex uppercase text-xs font-medium tracking-wider bg-neutral-100'>
                    <div className='w-full px-4 py-3'>Category</div>
                    <div className='shrink-0 w-[54px]' />
                </div>
                <div className='bg-white flex flex-col divide-y divide-gray-200 max-h-[500px] overflow-auto'>
                    {tmpValues.map((val, i) => <BucketTemplateValueRow key={i} id={i} value={val} onSave={onSaveValue} onDelete={onDeleteValue} />)}
                    {valueToAdd ? <BucketTemplateValueRow focus id={-1} value={valueToAdd} onSave={(_, cat) => addValue(cat)} onDelete={() => setValueToAdd(undefined)} onCancel={() => setValueToAdd(undefined)} /> : null}
                </div>
            </div>
            <Button onClick={() => {setValueToAdd("Value")}} icon={<HiOutlinePlus />} text='Add value' color='primary' />
        </div>
        </Modal>
    </>
}

export default BucketTemplateRowValuesDialog
