import { useEffect, useState } from "react"
import { get } from "../../helpers/Requests"
import { APAAccount, APASetup } from "../../types/types"
import { FaEdit, FaTimes } from "react-icons/fa"
import Button from "../Button"
import TableButton from "../Buttons/TableButton"
import CustomInput from "../CustomInput"
import CustomCombobox, { IComboboxOption } from "../CustomCombobox"

const APAAccountCard: React.FC<{ serverId: string, account: APAAccount, onClick(): any, orgOptions?: IComboboxOption[] | undefined }> = ({ serverId, account, onClick, orgOptions = [] }) => {
    const [setups, setSetups] = useState<APASetup[]>([])
    const [name, setName] = useState<string>(account.name)
    const [editMode, setEditMode] = useState<boolean>(false)
    const [selectedOption, setSelectedOption] = useState<IComboboxOption>()

    useEffect(() => {
        get(`apa/${serverId}/setups/account/${account.id}`)
            .then(setups => setSetups(setups))
            .catch(e => alert(e))
        const selected = orgOptions.find(o => o.id === account.organization_id)
        setSelectedOption(selected)
    }, [account.id, serverId])


    function editButtonClickedHandler() {
        setEditMode(!editMode)

    }

    function selectedOptionHandler(value: any) {
        setSelectedOption(value)
    }

    function onClickEditMode(e: any) {
        e.preventDefault()
        e.stopPropagation()
    }

    function doCreateAccount() {
        throw new Error("Function not implemented.")
    }

    return <div onClick={!editMode ? onClick : onClickEditMode} className="flex flex-row justify-between items-center cursor-pointer first:rounded-t-md last:rounded-b-md hover:bg-neutral-50 py-3 px-6 last:border-0 border-b border-gray-300">
        {!editMode ?
            <div className="flex w-full justify-between gap-1 text-sm">
                <div className="font-semibold text-base text-sky-600">{account.name}</div>

            </div>


            :
            <div className="flex align-center justify-center gap-1 ">


                <CustomInput className="my-1" name="name" label="" value={name} onChange={e => setName(e.target.value)} />

                <CustomCombobox placeholder="" options={orgOptions} showValue={true} selectedOption={!!selectedOption ? selectedOption : { id: 0, value: 0, label: "No Organization" }} setSelectedOption={value => selectedOptionHandler(value)}></CustomCombobox>


                <div className="ml-3"><Button className="py-1.5 mb-0" disabled={name === ""} text="Save" color="primary" onClick={() => { doCreateAccount() }} /></div>
            </div>
        }
        <div className="flex flex-row items-center text-gray-500 gap-4 ">
            <div className="font-semibold text-gray-500 border-r border-gray-300 pr-4">{setups.length}</div>
            {setups.filter(s => s.quarantined).length > 0 ? <div className="font-semibold text-red-500">{setups.filter(s => s.quarantined).length}</div>
                : <div className="font-semibold text-green-500">{0}</div>
            }
            {editMode ?
                <TableButton className="p-1" onClick={editButtonClickedHandler}><FaTimes className="h-4 w-4 text-dark-forest-500" /></TableButton>

                :
                <TableButton className="p-1" onClick={editButtonClickedHandler}><FaEdit className="h-4 w-4 text-dark-forest-500" /></TableButton>
            }
        </div>
    </div >
}
export default APAAccountCard