import { Dispatch, SetStateAction, useState } from "react"
import { ActionTemplate, BucketTemplate, CompareType, ReportFilter, SortDirection } from "../../types/template_types"
import CustomCheckbox from "../Buttons/CustomCheckbox"
import CustomInput from "../CustomInput"
import Select from "react-select"
import SearchableMenu from "../SearchableMenu"
import ReportTemplateSlicerEditor from "./ReportTemplateSlicerEditor"
import { FaTh, FaTimes } from "react-icons/fa"
import ActionTemplateKPISetup from "./ActionTemplateKPISetup"
import { MdClose, MdMoreHoriz } from "react-icons/md"
import ShowIf from "../ShowIf"
import { bsColorToTWMap } from "../../helpers/template_helpers"

interface ActionTemplateSlicerSetupProps {
    tmpAction: ActionTemplate
    setTmpAction: Dispatch<SetStateAction<ActionTemplate | undefined>>
    tmpLimit: string
    setTmpLimit: Dispatch<SetStateAction<string>>
    categoryLimitEnabled: boolean;
    setCategoryLimitEnabled: Dispatch<SetStateAction<boolean>>
    toggleLimit: () => void
    limitRegex: RegExp
    bucket: BucketTemplate
    onSortColumnChange: (v: string) => void
    onSortDirectionChange: (v: SortDirection) => void
}

function ActionTemplateSlicerSetup(props:ActionTemplateSlicerSetupProps) {
    const [slicerToEdit, setSlicerToEdit] = useState<ReportFilter>()
    const [showEditSlicer, setShowEditSlicer] = useState(false)

    const addFilter = (column: string) => {
        let tmp = structuredClone(props.tmpAction)
        let filter = {column: column, compare_type: CompareType.EQ, target_values: [], is_column: false}
        tmp.filters.push(filter)
        props.setTmpAction(tmp)
        setSlicerToEdit(filter)
        setShowEditSlicer(true)
    }
    const onSaveSlicer = (slicer: ReportFilter) => {
        let tmp = structuredClone(props.tmpAction)
        const index = props.tmpAction.filters.findIndex(x => x.column === slicer.column)
        if (index !== -1) {
            tmp.filters[index] = slicer
        } else {
            tmp.filters.push(slicer)
        }
        tmp.filters = tmp.filters.filter(f => f.target_values.length > 0)
        props.setTmpAction(tmp)
        setSlicerToEdit(undefined)
        setShowEditSlicer(false)
    }

    const onEditSlicer = (slicer: ReportFilter) => {
        setSlicerToEdit(slicer)
        setShowEditSlicer(true)
    }

    const onRemoveSlicer = (index: number) => {
        let tmp = structuredClone(props.tmpAction)
        tmp.filters.splice(index, 1)
        props.setTmpAction(tmp)
    }

    const onCancelEditSlicer = () => {
        let tmp = structuredClone(props.tmpAction)
        const index = props.tmpAction.filters.findIndex(x => x.column === slicerToEdit?.column)
        if (index === -1) return
        if (slicerToEdit?.target_values.length === 0) {
            tmp.filters.splice(index, 1)
        }
        props.setTmpAction(tmp)
        setSlicerToEdit(undefined)
        setShowEditSlicer(false)
    }

    return (
        <div>
            <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg mb-2'>Limit</h2>
            <div className='flex items-center'>
                <CustomCheckbox value={props.tmpAction.limit !== -1 || props.tmpAction.category_limit !== -1} onChange={props.toggleLimit} className={"w-4"}/>
                <span className="text-sm font-semibold w-20">Enable limit</span>
                <Select
                    value={{label: props.tmpAction.sort_direction === "asc" ? "Bottom" : "Top", value: props.tmpAction.sort_direction}}
                    options={[{label: "Top", value: "desc"}, {label: "Bottom", value: "asc"}]}
                    placeholder="Sort direction"
                    onChange={(v) => {if(v) props.onSortDirectionChange(v?.value)}}
                    isDisabled={props.tmpAction.limit === -1 && props.tmpAction.category_limit === -1}
                    className="ml-2"
                />
                <Select
                    value={{label: props.tmpLimit, value: props.tmpLimit}}
                    options={["5", "10", "25", "50", "75", "100"].map(n => ({label: n, value: n}))}
                    isDisabled={props.tmpAction.limit === -1 && props.tmpAction.category_limit === -1}
                    placeholder="Limit"
                    onChange={v => {if(v) props.setTmpLimit(v.value)}}
                    isSearchable={false}
                />
                {/* <div className='w-64 ml-2'>
                    
                    
                    <CustomInput 
                        value={props.tmpLimit} 
                        onChange={(e) => props.setTmpLimit(e.target.value)} 
                        pattern="\d*" 
                        disabled={props.tmpAction.limit === -1 && props.tmpAction.category_limit === -1} 
                        error={!props.limitRegex.test(props.tmpLimit) && props.tmpAction.limit > -1} 
                        placeholder='Limit' 
                    />
                </div> */}
                <Select
                    value={{label: props.tmpAction.sort_column, value: props.tmpAction.sort_column}}
                    options={props.tmpAction.columns.map(c => ({label: c, value: c}))}
                    isSearchable={true}
                    placeholder="Sort column"
                    className='w-80 ml-2'
                    onChange={(v) => {if(v) props.onSortColumnChange(v.value)}}
                    isDisabled={props.tmpAction.limit === -1 && props.tmpAction.category_limit === -1}
                />
                <ShowIf if={!props.categoryLimitEnabled}>
                    <MdMoreHoriz className="text-2xl ml-2 cursor-pointer" onClick={() => props.setCategoryLimitEnabled(true)}/>
                </ShowIf>
                <ShowIf if={props.categoryLimitEnabled}>
                    <span className="mx-2">of</span>
                    <div className={`${bsColorToTWMap.get(props.tmpAction.layout[0].color)} text-white p-1.5 rounded-md font-semibold pointer-events-none`}>{props.tmpAction.layout[0].name}</div>
                    <MdClose className="cursor-pointer mb-4 ml-0.5" onClick={() => props.setCategoryLimitEnabled(false)}/>
                </ShowIf>
            </div>
            <ActionTemplateKPISetup
                tmpAction={props.tmpAction} 
                setTmpAction={props.setTmpAction}
            />
            <h2 className='border-b border-slate-200 mt-5 mb-2 text-gray-500 text-lg'>Slicer setup</h2>
            <SearchableMenu 
                label="Add slicer"
                options={props.tmpAction.columns.filter(v => !props.tmpAction.filters.some(x => x.column === v)).sort().map((v) => ({label: v, onClick: () => {addFilter(v)}, icon: v === props.bucket.categorization_name ? <FaTh className="ml-1" /> : null}))} 
            />
            <div className="flex gap-2 mt-2">
                {
                    props.tmpAction.filters.map((f, i) => {
                        return <button key={i} onClick={() => onEditSlicer(f)} className="flex justify-between items-center bg-white rounded-md shadow-md p-3 w-[300px] hover:bg-neutral-50">
                            <span className="truncate">{f.column} - {f.target_values.length}</span>
                            <div onClick={(e) => {e.stopPropagation(); onRemoveSlicer(i)}} className="text-gray-400 hover:text-black cursor-pointer"><FaTimes /></div>
                        </button>
                    })
                }
            </div>
            <ReportTemplateSlicerEditor bucket={props.bucket} slicer={slicerToEdit} tmpReport={props.tmpAction} saveSlicer={onSaveSlicer} onClose={onCancelEditSlicer} show={showEditSlicer} slicers={props.tmpAction.filters} />
        </div>
    )
}

export default ActionTemplateSlicerSetup