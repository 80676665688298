import { useNavigate } from "react-router-dom"
import ServerCard from "./ServerCard"
import { ServerData } from "../../types/types"
import BucketsCard from "../Cloud/BucketsCard"
import ErrorsCard from "../Cloud/ErrorsCard"

const ServerAndBucketsErrorsCard: React.FC<{ server: ServerData}> = ({ server }) => {
       const navigate = useNavigate()

    return <div>
    {
      <>
      <ServerCard server={server} onClick={() => navigate(`/cloud/${server.id}`)} />
      <BucketsCard serverId={server.id.toString()} onClick={() => navigate(`/cloud/${server.id}`)} />
      <ErrorsCard serverId={server.id.toString()} onClick={() => navigate(`/cloud/${server.id}/errors`)} />
      </>
    }
    </div>

}

export default ServerAndBucketsErrorsCard