import React, { useEffect, useRef, useState } from 'react'
import {  HiOutlineCog, HiOutlineTrash } from 'react-icons/hi'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { BucketColumnType, BucketTemplateColumn } from '../../types/template_types'
import CustomInput from '../CustomInput'
import Select from '../Select'
import BucketTemplateRowValuesDialog from './BucketTemplateRowValuesDialog'

export interface BucketTemplateRowProps {
    id: number,
    column: BucketTemplateColumn,
    onSaveColumn: (id: number, column: BucketTemplateColumn) => void,
    onDeleteColumn: (id: number) => void,
    onCancel?: (id: number) => void,
    focus?: boolean,
    disableCategorization?: boolean,
    isActiveCategory?: boolean
    setTab: (id: number) => void,
}


const BucketTemplateRow = ({column, isActiveCategory = false, focus = false, disableCategorization = false, ...props }: BucketTemplateRowProps) => {
    
    const [tmpName, setTmpName] = useState("")
    const [tmpType, setTmpType] = useState<BucketColumnType>(BucketColumnType.Text)

    const [editName, setEditName] = useState(false)

    const [showEditValues, setShowEditValues] = useState(false)

    const rowRef = useRef<any>()

    useEffect(() => {
        setTmpName(column.name)
        setTmpType(column.type)

        if (focus) {
            setEditName(true)
        }
    }, [column, focus])

    useEffect(() => {
        if (editName && rowRef?.current) {
            if (focus) {
                rowRef.current.select()
            } else {
                rowRef.current.focus()
            }
        }
    }, [editName, rowRef])

    const onClose = () => {
        if (tmpName !== "") {
            props.onSaveColumn(props.id, {name: tmpName, type: tmpType, values: column.values})
        } else {
            setTmpName(column.name)
            setTmpType(column.type)
        }

        setEditName(false)
    }

    const setType = (value: BucketColumnType) => {
        props.onSaveColumn(props.id, {name: tmpName, type: value, values: column.values})
    }

    const saveValues = (values: string[]) => {
        props.onSaveColumn(props.id, {name: tmpName, type: tmpType, values: values})
        setShowEditValues(false)
    }

    useOnClickOutside(() => {
        onClose()
    }, rowRef)

    // onKeyPress does not register "Escape", so we use onKeyDown
    const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        // If the enter key has been pressed, save the target
        if (e.key === "Enter") {
            onClose()
        }

        // If the escape key has been pressed, cancel
        if (e.key === "Escape") {
            setTmpName(column.name)
            setEditName(false)
            if (props.onCancel !== undefined) {
                props.onCancel(props.id)
            }
        }
    }

    const onEditValues = () => {
        if (isActiveCategory) {
            props.setTab(1)
        } else {
            setShowEditValues(true)
        }
    }


    const isCategorization = column.type === BucketColumnType.Categorization
    const TypeOptions = [
        <option key={1} value={BucketColumnType.Text}>Text</option>,
        <option key={2} value={BucketColumnType.ID}>ID</option>,
        <option key={3} value={BucketColumnType.Date}>Date</option>,
        <option key={4} value={BucketColumnType.Decimal}>Decimal</option>,
        <option key={5} disabled={disableCategorization && !isCategorization} value={BucketColumnType.Categorization}>Categorization {disableCategorization && !isCategorization ? "(Already exists)" : ""}</option>,
    ] 

    return <div style={{justifyContent: "stretch"}} className='flex'>
        <div onClick={() => setEditName(true)} className='w-full flex items-center px-4 py-2 whitespace-nowrap cursor-pointer hover:bg-gray-200'>
            {editName ? 
            <div>
                <CustomInput onKeyDown={onKeyPress} value={tmpName} onChange={e => setTmpName(e.target.value)} ref={rowRef} />
            </div> 
            :
            <span>{column.name}</span>}
        </div>
        <div className='w-full px-4 py-2 flex items-center whitespace-nowrap'>
            <Select className='w-[260px]' value={tmpType} onChange={e => setType(e.target.value as BucketColumnType)}>
                {TypeOptions}
            </Select>
            {
                tmpType === BucketColumnType.Categorization ? <>
                    <HiOutlineCog onClick={onEditValues} title='Edit standard values' className='w-5 h-5 ml-2 cursor-pointer stroke-gray-400 hover:stroke-black' />
                    <BucketTemplateRowValuesDialog show={showEditValues} onClose={() => setShowEditValues(false)} column={column} onSave={saveValues} />
                </> : null
            }
        </div>
        <div className='flex items-center justify-center shrink-0 w-[54px]'>
            <HiOutlineTrash onClick={() => props.onDeleteColumn(props.id)} title='Delete column' className='w-5 h-5 cursor-pointer hover:stroke-red-600' />
        </div>
    </div>
}

export default BucketTemplateRow
