import React from "react";
import { FaCalculator, FaChartLine, FaList, FaTable, FaTh, FaWindowMaximize } from "react-icons/fa";
import { HiOutlineMinus } from "react-icons/hi";
import { AggregationType, BucketTemplate, getPossibleAggTypes, ReportTemplate } from "../../types/template_types";
import CustomCheckbox from "../Buttons/CustomCheckbox";
import Divider from "../Divider";
import Select from "../Select";
import ShowIf from "../ShowIf";

interface ReportTemplateViewSettingsProps {
    tmpReport: ReportTemplate,
    setTmpReport: React.Dispatch<React.SetStateAction<ReportTemplate | undefined>>,
    bucket: BucketTemplate,
}

const ReportTemplateViewSettings = ({tmpReport, setTmpReport, bucket}: ReportTemplateViewSettingsProps) => {

    const addKpi = () => {
        let tmp = structuredClone(tmpReport)
        tmp.kpis.push({column: tmpReport.columns[0], aggregation: getPossibleAggTypes(tmpReport.columns[0], bucket)[0].value, showPercentage: false, showKPIDecimals: false, trend: false })
        setTmpReport(tmp)
    }

    const onKpiColumnChange = (index: number, column: string) => {
        let tmp = structuredClone(tmpReport)
        const oldCol = bucket.columns.find(x => x.name === tmp.kpis[index].column)
        const newCol = bucket.columns.find(x => x.name === column)
        tmp.kpis[index].column = column
        if (oldCol?.type !== newCol?.type) {
            tmp.kpis[index].aggregation = getPossibleAggTypes(column, bucket)[0].value
        }
        setTmpReport(tmp)
    }

    const onKpiAggregationChange = (index: number, aggtype: AggregationType) => {
        let tmp = structuredClone(tmpReport)
        tmp.kpis[index].aggregation = aggtype
        setTmpReport(tmp)
    }

    const onKpiDecimalChange = (index: number, checked: boolean) => {
        let tmp = structuredClone(tmpReport)
        tmp.kpis[index].showKPIDecimals = checked
        setTmpReport(tmp)
    }

    const onKpiRemove = (index: number) => {
        let tmp = structuredClone(tmpReport)
        tmp.kpis.splice(index, 1)
        if (tmp.kpis.length === 0) {
            tmp.view_settings.settings.trend = false
            if (tmp.view_settings.settings.default_view === "trend") {
                tmp.view_settings.settings.default_view = tmp.view_settings.settings.overview ? "overview" : tmp.view_settings.settings.grid ? "grid" : tmp.view_settings.settings.kpis ? "kpis" : tmp.view_settings.settings.sum ? "sum" : tmp.view_settings.settings.profit ? "profit" : tmp.view_settings.settings.trend ? "trend" : ""
            }
        }
        setTmpReport(tmp)
    }

    const onDefaultViewChange = (value: string) => {
        let tmp = structuredClone(tmpReport)
        tmp.view_settings.settings.default_view = value as "overview" | "grid" | "kpis" | "sum" | "trend" | "profit"
        setTmpReport(tmp)
    }

    const onViewEnableChange = (value: boolean, view: "overview" | "grid" | "kpis" | "sum" | "trend" | "profit" | "enableLabel") => {
        let tmp = structuredClone(tmpReport)
        tmp.view_settings.settings[view] = value
        if (!value && tmp.view_settings.settings.default_view === view) {
            tmp.view_settings.settings.default_view = tmp.view_settings.settings.overview ? "overview" : tmp.view_settings.settings.grid ? "grid" : tmp.view_settings.settings.kpis ? "kpis" : tmp.view_settings.settings.sum ? "sum" : tmp.view_settings.settings.profit ? "profit" : tmp.view_settings.settings.trend ? "trend" : ""
        }
        if (value && view === "trend"){
            // Setting first selected KPI as default kpi for trend, as well as setting other default values trend view requires
            tmp.view_settings.trend = {
                kpigraph:{
                    goToDiff: false,
                    selectedKpiView: tmp.kpis[0].column,
                    selectedKpi1: {
                        kpi: tmp.kpis[0],
                        color: "blue",
                        index: 0,
                        settings: {
                            type: "bar"
                        }
                    },
                    selectedKpi2: null,
                    selectedCategory: null,
                    graphOptions: {
                        zoom: 0,
                        axisNum: 1,
                        startAtZero: true
                    }
                }
            }
        }
        setTmpReport(tmp)
    }

    const anyViewActive = tmpReport.view_settings.settings.kpis || tmpReport.view_settings.settings.trend || tmpReport.view_settings.settings.overview || tmpReport.view_settings.settings.profit || tmpReport.view_settings.settings.sum || tmpReport.view_settings.settings.grid
    return <div className="flex flex-col gap-3">
        <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>KPI setup</h2>
        <div className="flex divide-x divide-almondine-mist-800">
          {
              tmpReport.kpis.map((v, i) => {
                return <div key={i} className="w-[285px] h-[150px] bg-almondine-mist-500 flex flex-col justify-center items-center p-2 relative">
                    <HiOutlineMinus onClick={() => onKpiRemove(i)} title="Remove KPI" className="w-5 h-5 stroke-gray-400 hover:stroke-red-500 cursor-pointer absolute top-2 right-2" />
                    <Select className="w-full" value={v.column} onChange={(e) => onKpiColumnChange(i, e.target.value)}>
                        {
                            tmpReport.columns.map((x, i) => <option key={i} value={x}>{x}</option>)
                        }
                    </Select>
                    <div className="flex w-full">
                        <Select className="grow w-full" value={v.aggregation} onChange={(e) => onKpiAggregationChange(i, e.target.value as AggregationType)}>
                            {
                                getPossibleAggTypes(v.column, bucket).map((x, i) => <option key={i} value={x.value}>{x.label}</option>)
                            }
                        </Select>
                        <CustomCheckbox text="0.0" value={v.showKPIDecimals} onChange={(val) => onKpiDecimalChange(i, val)} />
                    </div>
                </div>
              })
          }
          <ShowIf if={tmpReport.kpis.length < 4 && tmpReport.columns.length > 0}>
            <div onClick={addKpi} className="flex justify-center items-center w-[285px] h-[150px] bg-almondine-mist-500 hover:bg-almondine-mist-600 cursor-pointer">
                Add KPI
            </div>
          </ShowIf>
        </div>

        <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>Show/hide views {!anyViewActive ? <span className="text-red-500">No views active!</span> : null}</h2>
        <Select className="w-fit" label={<>Default view {tmpReport.view_settings.settings.default_view === "" ? <span className="text-red-500">No default view!</span> : null}</>} value={tmpReport.view_settings.settings.default_view} onChange={e => onDefaultViewChange(e.target.value)}>
            <option value={""}>None</option>
            <ShowIf if={tmpReport.view_settings.settings.overview}><option value="overview">Matrix</option></ShowIf>
            <ShowIf if={tmpReport.view_settings.settings.grid}><option value="grid">Grid</option></ShowIf>
            <ShowIf if={tmpReport.view_settings.settings.kpis}><option value="kpis">KPI</option></ShowIf>
            <ShowIf if={tmpReport.view_settings.settings.sum}><option value="sum">Sum</option></ShowIf>
            <ShowIf if={tmpReport.view_settings.settings.profit}><option value="profit">Profit</option></ShowIf>
            <ShowIf if={tmpReport.view_settings.settings.trend}><option value="trend">Trend</option></ShowIf>
        </Select>
        <div>
            <CustomCheckbox icon={<FaTh />} text="Matrix" value={tmpReport.view_settings.settings.overview} onChange={(value) => onViewEnableChange(value, "overview")} />
            <CustomCheckbox icon={<FaList />} text="Grid" value={tmpReport.view_settings.settings.grid} onChange={(value) => onViewEnableChange(value, "grid")} />
            <CustomCheckbox icon={<FaWindowMaximize />} text="KPI" value={tmpReport.view_settings.settings.kpis} onChange={(value) => onViewEnableChange(value, "kpis")} />
            <CustomCheckbox icon={<FaTable />} text="Sum" value={tmpReport.view_settings.settings.sum} onChange={(value) => onViewEnableChange(value, "sum")} />
            <CustomCheckbox icon={<FaCalculator />} text="Profit" value={tmpReport.view_settings.settings.profit} onChange={(value) => onViewEnableChange(value, "profit")} />
            <CustomCheckbox icon={<FaChartLine />} text="Trend" value={tmpReport.view_settings.settings.trend} onChange={(value) => onViewEnableChange(value, "trend")} disabled={tmpReport.kpis.length === 0} title={tmpReport.kpis.length === 0 ? "Requires at least 1 KPI" : ""} />
            <Divider />
            <CustomCheckbox text="Enable label" value={tmpReport.view_settings.settings.enableLabel} onChange={(value) => onViewEnableChange(value, "enableLabel")} />
        </div>
    </div>
}

export default ReportTemplateViewSettings
