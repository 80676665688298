import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { FaEdit, FaSave, FaUndo } from 'react-icons/fa'
import { HiChevronDown } from 'react-icons/hi'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '../components/Button'
import CustomInput from '../components/CustomInput'
import { Modal, WarningModal } from '../components/Modals'
import TabBar from '../components/TabBar'
import { del, get, put, post } from '../helpers/Requests'
import { compareObjects } from '../helpers/type_helpers'
import { ActionTemplate, ActionTemplateCategory, ActionTemplateLayoutWithTemplateName, BucketTemplate, defaultNewActionTemplate, SortDirection } from '../types/template_types'
import TemplateColumnSelect from '../components/Template/TemplateColumnSelect'
import TemplateColumnShowHide from '../components/Template/TemplateColumnShowHide'
import ActionTemplateLayoutSetup from '../components/Template/ActionTemplateLayoutSetup'
import ActionTemplateSlicerSetup from '../components/Template/ActionTemplateSlicerSetup'


const TemplateActionEdit = () => {
    const { cloudId, actionId } = useParams()
    const navigate = useNavigate();
    const [action, setAction] = useState<ActionTemplate>()
    const [tmpAction, setTmpAction] = useState<ActionTemplate>()
    const [bucket, setBucket] = useState<BucketTemplate>()

    const [showDeleteTemplateWarning, setShowDeleteTemplateWarning] = useState(false)

    const [tmpName, setTmpName] = useState("")
    const [showEditName, setShowEditName] = useState(false)
    const [showSaveAs, setShowSaveAs] = useState<boolean>(false);
    const [tmpDesc, setTmpDesc] = useState("")
    const [tmpSaveAsName, setTmpSaveAsName] = useState("");
    const [tmpSaveAsDesc, setTmpSaveAsDesc] = useState("");

    const defaultLayout : ActionTemplateCategory[] = [
        {color: "btn-primary", name: "TO DO", donecount: false},
        {color: "btn-success", name: "DONE", donecount: true}
    ] 
    const [doneLayout, setDoneLayout] = useState<ActionTemplateCategory[]>(defaultLayout.filter(c => c.donecount))
    const [notDoneLayout, setNotDoneLayout] = useState<ActionTemplateCategory[]>(defaultLayout.filter(c => !c.donecount))
    const [recentLayouts, setRecentLayouts] = useState<ActionTemplateLayoutWithTemplateName[]>([])

    const [tab, setTab] = useState(0)

    const [tmpLimit, setTmpLimit] = useState(``)
    const [categoryLimitEnabled, setCategoryLimitEnabled] = useState<boolean>(false);

    const [saving, setSaving] = useState(false)

    useEffect(() => {
        if(recentLayouts.length === 0){
            const queryLimit = 15
            get(`cloud/${cloudId}/templates/actions/layouts?limit=${queryLimit}`).then((res:ActionTemplateLayoutWithTemplateName[]) => {
                setRecentLayouts(res)
            }).catch(e => console.log(e))
        }
    }, [])
    
    useEffect(() => {
        get(`cloud/${cloudId}/templates/action/${actionId}`)
            .then((a: ActionTemplate) => {
                setAction(a)
            }).catch(e => console.log(e))

    }, [cloudId, actionId])

    useEffect(() => {
        setTmpAction(action)
        if(!!action?.limit && action.limit > -1){
            setTmpLimit(`${action.limit}`)
        } else if (!!action?.category_limit && action.category_limit > -1){
            setCategoryLimitEnabled(true)
            setTmpLimit(`${action.category_limit}`)
        } else {
            setTmpLimit("")
        }
        setDoneLayout(action?.layout.filter(c => c.donecount) ?? defaultLayout.filter(c => c.donecount))
        setNotDoneLayout(action?.layout.filter(c => !c.donecount) ?? defaultLayout.filter(c => !c.donecount))

        if (action === undefined) return
        get("cloud/" + cloudId + "/templates/bucket/" + action.bucket_id).then(result => {
            setBucket(result)
        }).catch(err => console.log(err))

        setTmpName(action?.name ?? "Name")
        setTmpDesc(action?.description ?? "")
    }, [action])

    const limitRegex = new RegExp(/^-?\d+$/)
    useEffect(() => {
        if (tmpAction === undefined) return
        let tmp = structuredClone(tmpAction)
        if (limitRegex.test(tmpLimit) && Number(tmpLimit) >= 0) {
            if(categoryLimitEnabled){
                tmp.limit = -1
                tmp.category_limit = Number(tmpLimit)
            } else {
                tmp.category_limit = -1
                tmp.limit = Number(tmpLimit)
            }
            setTmpAction(tmp)
        }
    }, [tmpLimit, categoryLimitEnabled])
    
    useEffect(() => {
        if (tmpAction === undefined) return
        const tmp = structuredClone(tmpAction)
        tmp.layout = [...notDoneLayout, ...doneLayout].filter(c => c.name.length > 0)
        setTmpAction(tmp)
    }, [notDoneLayout, doneLayout])

    const onDeleteTemplate = () => {
        setShowDeleteTemplateWarning(true)
    }

    const onSaveAs = () => {
        if(!tmpAction) return;
        const newTemplate = defaultNewActionTemplate;
        newTemplate.name = tmpSaveAsName;
        newTemplate.description = tmpSaveAsDesc;
        newTemplate.group_id = tmpAction.group_id;
        newTemplate.bucket_id = tmpAction.bucket_id
        
        post(`cloud/${cloudId}/templates/action`, newTemplate).then((templateId: number) => {
            const tmp = structuredClone(tmpAction);
            tmp.id = templateId;
            tmp.name = tmpSaveAsName;
            tmp.description = tmpSaveAsDesc;
            put(`cloud/${cloudId}/templates/action`, tmp).then(n => {
                navigate(`/templates/${cloudId}/actions/${templateId}`)
            }).catch(e => console.log(e))
        }).catch(e => console.log(e))
        setShowSaveAs(false);
    }

    const onConfirmDeleteTemplate = () => {
        del(`cloud/${cloudId}/templates/action/${actionId}`).then(() => {
            navigate(`/templates/${cloudId}/actions`)
        }).catch(e => console.log(e))
        setShowDeleteTemplateWarning(false)
    }

    const onSaveName = () => {
        if (tmpName === "" || tmpAction === undefined) return
        let tmp = structuredClone(tmpAction)
        tmp.name = tmpName
        tmp.description = tmpDesc
        setTmpAction(tmp)
        setShowEditName(false)
    }

    const onEditName = () => {
        setTmpName(tmpAction?.name ?? "Name")
        setTmpDesc(tmpAction?.description ?? "")
        setShowEditName(true)
    }

    const onShowSaveAs = () => {
        setTmpSaveAsDesc(tmpAction?.description ?? "")
        setTmpSaveAsName(tmpAction?.name + " - Copy")
        setShowSaveAs(true);
    }

    const disableSave = () => {
        if (tmpAction === undefined) return true
        return compareObjects(tmpAction, action) || (!limitRegex.test(tmpLimit) && tmpAction.limit > -1) || ((tmpAction.limit > -1 || tmpAction.category_limit > -1) && (tmpAction.sort_direction === "" || tmpAction.sort_column === "")) || tmpAction.layout?.length === 0 || tmpAction.columns.length === 0
    }

    const onSave = () => {
        if (tmpAction === undefined) return
        setSaving(true)

        put(`cloud/${cloudId}/templates/action`, tmpAction).then(result => {
            setAction(result)
            setSaving(false)
        }).catch(e => console.log(e))
    }

    const onUndo = () => {
        let tmpaction = structuredClone(action)
        setTmpAction(tmpaction)
          
        setDoneLayout(tmpaction?.layout.filter(c => c.donecount) ?? defaultLayout.filter(c => c.donecount))
        setNotDoneLayout(tmpaction?.layout.filter(c => !c.donecount) ?? defaultLayout.filter(c => !c.donecount))
        setTmpName(tmpaction?.name ?? "Name")
        setTmpDesc(tmpaction?.description ?? "")
        
        setCategoryLimitEnabled(false)
        if(!!tmpaction?.limit && tmpaction.limit > -1){
            setTmpLimit(`${tmpaction.limit}`)
        } else if (!!tmpaction?.category_limit && tmpaction.category_limit > -1){
            setCategoryLimitEnabled(true)
            setTmpLimit(`${tmpaction.category_limit}`)
        } else {
            setTmpLimit("")
        }

    }

    const selectColumns = (cols: string[]) => {
        if (tmpAction === undefined) return
        let tmp = structuredClone(tmpAction)
        tmp.columns.push(...cols)
        if (tmp.shown_columns.length < 8) {
            let c = cols.slice(0, 8 - tmp.shown_columns.length)
            tmp.shown_columns.push(...c)
        }
        if (tmp.limit > -1 && tmp.sort_column === "" && tmp.columns.length > 0) {
            tmp.sort_column = tmp.columns[0]
        }
        setTmpAction(tmp)
    }
    
    const deselectColumns = (cols: string[]) => {
        if (tmpAction === undefined) return
        let tmp = structuredClone(tmpAction)
        tmp.columns = tmp.columns.filter(a => !cols.some(b => a === b))
        tmp.shown_columns = tmp.shown_columns.filter(a => !cols.some(b => a === b))
        if (cols.some(c => c === tmp.sort_column)){
            tmp.sort_column = tmp.columns.length > 0 ? tmp.columns[0] : ""
        }
        setTmpAction(tmp)
    }

    const toggleLimit = () => {
        if (tmpAction === undefined) return
        let tmp = structuredClone(tmpAction)
        if (tmp.limit === -1 && tmp.category_limit === -1) {
            setTmpLimit("10")
            if(categoryLimitEnabled){
                tmp.category_limit = 10;
                tmp.limit = -1
            } else {
                tmp.limit = 10
                tmp.category_limit = -1
            }
            tmp.sort_column = tmp.columns.length > 0 ? tmp.columns[0] : ""
            tmp.sort_direction = "desc"
        } else {
            tmp.limit = -1
            tmp.category_limit = -1
            setCategoryLimitEnabled(false)
            setTmpLimit("Limit")
            tmp.sort_column = ""
            tmp.sort_direction = ""
        }
        setTmpAction(tmp)
    }

    const onSortColumnChange = (value: string) => {
        if (tmpAction === undefined) return
        let tmp = structuredClone(tmpAction)
        tmp.sort_column = value
        setTmpAction(tmp)
    }

    const onSortDirectionChange = (value: SortDirection) => {
        if (tmpAction === undefined) return
        let tmp = structuredClone(tmpAction)
        tmp.sort_direction = value
        setTmpAction(tmp)
    }
    
    return <div className="bg-neutral-100 min-h-full pb-8">
        <div className="flex justify-between items-end mb-4">
            <div className="px-4 py-2"><h1 onClick={onEditName} className="cursor-pointer text-gray-500 text-lg font-semibold">Action Setup - {tmpAction?.name ?? "Loading"} <FaEdit className='inline-block mb-1 ml-1' /></h1></div>
            <Menu as="div" className="relative inline-block text-left mx-4">
                <div>
                    <MenuButton className="inline-flex w-full justify-center rounded-md bg-orange-500 px-4 py-2 text-sm font-medium text-white hover:bg-orange-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        Options
                        <HiChevronDown
                            className="ml-2 -mr-1 h-5 w-5 text-orange-200 hover:text-orange-100"
                            aria-hidden="true"
                        />
                    </MenuButton>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <MenuItems className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

                        <div className="px-1 py-1 ">
                            <MenuItem>
                                {({ focus }) => (
                                    <button
                                        className={`${
                                            focus ? 'bg-orange-100 text-black' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={onEditName}
                                    >
                                        Edit name
                                    </button>
                                )}
                            </MenuItem>
                        </div>
                        <div className="px-1 py-1 ">
                            <MenuItem>
                                {({ focus }) => (
                                    <button
                                        className={`${
                                            focus ? 'bg-red-600 text-white' : 'text-gray-900'
                                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                                        onClick={onDeleteTemplate}
                                    >
                                        Delete
                                    </button>
                                )}
                            </MenuItem>
                        </div>
                    </MenuItems>
                </Transition>
            </Menu>
        </div>
        <div className={`mx-4 flex flex-col gap-4 ${saving ? 'pointer-events-none opacity-50':''}`}>
            {
                tmpAction === undefined || bucket === undefined ? <h1>Loading...</h1>
                    : <>
                        <div className='flex gap-2 justify-between'>
                            <div className='flex gap-2'>
                                <Button onClick={onSave} disabled={disableSave()} icon={<FaSave />} text='Save' color='primary' className='w-fit' />
                                <Button onClick={onUndo} disabled={disableSave()} icon={<FaUndo />} text='Undo' color='secondary' className='w-fit' />
                                <Button onClick={onShowSaveAs} disabled={!tmpAction} icon={<FaSave />} text='Save as' color='primary' className='w-fit' />
                            </div>
                        </div>
                        <TabBar style={{marginBottom: 0}} setTab={i => setTab(i)} tabs={["Columns", "Slicers/KPI", "Layout"]} selected={tab} />
                        {/* Column setup */}
                        {tab === 0 && <>
                            <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>Column setup</h2>
                            <TemplateColumnSelect
                                available={bucket.columns.map(c => c.name)}
                                selected={tmpAction.columns}
                                selectColumns={selectColumns}
                                deselectColumns={deselectColumns}
                                bucket={bucket}
                            />

                            <h2 className='border-b border-slate-200 mt-5 text-gray-500 text-lg'>Column visibility</h2>
                            <TemplateColumnShowHide tmpReport={tmpAction} setTmpReport={setTmpAction} />
                        </>
                        }
                        {/* Slicer setup */}
                        {tab === 1 && <>
                            <ActionTemplateSlicerSetup 
                                tmpAction={tmpAction} 
                                setTmpAction={setTmpAction} 
                                limitRegex={limitRegex}
                                onSortColumnChange={onSortColumnChange}
                                onSortDirectionChange={onSortDirectionChange}
                                setTmpLimit={setTmpLimit}
                                tmpLimit={tmpLimit}
                                toggleLimit={toggleLimit}
                                bucket={bucket} 
                                categoryLimitEnabled={categoryLimitEnabled}
                                setCategoryLimitEnabled={setCategoryLimitEnabled}
                            />
                        </>}
                        {/* Layout setup */}
                        {tab === 2 && <>
                            <ActionTemplateLayoutSetup 
                                doneLayout={doneLayout}
                                notDoneLayout={notDoneLayout}
                                setDoneLayout={setDoneLayout}
                                setNotDoneLayout={setNotDoneLayout}
                                recentLayouts={recentLayouts}
                            />
                        </>}
                    </>
            }
        </div>
        <WarningModal
            isOpen={showDeleteTemplateWarning}
            title='Delete action template'
            actionText='Delete'
            closeText='Cancel'
            onClose={() => setShowDeleteTemplateWarning(false)}
            onAction={onConfirmDeleteTemplate}
        >
            Are you sure you want to delete this template? <span className='text-red-600 font-bold'>This is permanent and cannot be undone.</span>
        </WarningModal>

        <Modal isOpen={showEditName} 
            onClose={() => {setShowEditName(false); setTmpName(tmpAction?.name ?? "")}} 
            onAction={onSaveName} 
            title='Edit name' 
            actionText='Save' 
            closeText='Cancel'
            size='small'
            disableAction={tmpName === ""}>
            <div className='mt-3 flex flex-col gap-2'>
                <CustomInput label='Name' id='name-input' value={tmpName} onChange={e => setTmpName(e.target.value)} placeholder='Insert name...' />
                <label htmlFor='new-desc' className='text-gray-600 text-xs mt-3'>Description</label>
                <textarea id='new-desc' className='text-sm' value={tmpDesc} onChange={e => setTmpDesc(e.target.value)} />
            </div>
        </Modal>
        <Modal isOpen={showSaveAs} 
            onClose={() => {setShowSaveAs(false)}} 
            onAction={onSaveAs} 
            title='Save as' 
            actionText='Save' 
            closeText='Cancel'
            size='small'
            disableAction={tmpSaveAsName === "" || tmpSaveAsName === tmpAction?.name}>
            <div className='mt-3 flex flex-col gap-2'>
                <span className='text-sm text-gray-600'>This will create a new template with the current setup, without affecting <strong>{tmpAction?.name}</strong></span>
                {tmpSaveAsName === tmpAction?.name && <span className='text-sm text-gray-400'>Note: New template can't have the same name as current</span>}
                <CustomInput label='Name' id='name-input' value={tmpSaveAsName} onChange={e => setTmpSaveAsName(e.target.value)} placeholder='Insert name...' />
                <label htmlFor='new-desc' className='text-gray-600 text-xs mt-3'>Description</label>
                <textarea id='new-desc' className='text-sm rounded' value={tmpSaveAsDesc} onChange={e => setTmpSaveAsDesc(e.target.value)} />
            </div>
        </Modal>
    </div>
}

export default TemplateActionEdit
