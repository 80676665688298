import { createContext, useContext } from "react"
import { SideBarMenuItem } from "./SideBar"

export type SideBarMenu = {
	items: SideBarMenuItem[],
	setItems: (items: SideBarMenuItem[]) => void,
	up: string | null,
	setUp: (up: string | null) => void,
}

export const SideBarMenuContext = createContext<SideBarMenu>({
	items: [],
	setItems: () => { },
	up: null,
	setUp: () => { },
})

export const useSideBarMenuContext = () => useContext(SideBarMenuContext)
