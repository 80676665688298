import { useState, useEffect, useMemo, SetStateAction, Dispatch } from "react";
import { useParams } from "react-router-dom";
import AddEmail from "../components/Reporting/AddEmail";
import ReportingTable from "../components/Reporting/ReportingTable";
import { del, get, post, put } from "../helpers/Requests";
import { CloudReport, CloudReportMap, CloudUser } from "../types/cloud_types";
import { Report, Plan, TrendView } from "../types/reporting_types";
import { useSideBarMenuContext } from "../components/Sidebar/SideBarContext";
import { ServerData} from "../types/types"
import ReportingFilterBar from "../components/Reporting/ReportingFilterBar";
import { getPlanInterval, getReportingStats } from "../helpers/reporting_helpers";

const ReportingTrendViews: React.FC<{
    server: ServerData | undefined,
    reporting_reports: TrendView[],
    plans: Plan[],
    users: CloudUser[],
    update(): any,
    sendmail(id: number, email: string, test: boolean): void,
    add: boolean,
    setAdd: Dispatch<SetStateAction<boolean>>
}> = ({ server, reporting_reports, plans, users, update, sendmail, add, setAdd }) => {
    const { orgId } = useParams()
    const { setUp } = useSideBarMenuContext()
    const serverId = server?.id.toString()

    const [editReportEmail, setEditReportEmail] = useState<TrendView | null>(null);
    const [cloudReportsObj, setCloudReportsObj] = useState<CloudReportMap>()
    const [cloudReports, setCloudReports] = useState<CloudReport[]>([])

    const [shownTrendViews, setShownTrendViews] = useState<TrendView[]>([])

    const [searchText, setSearchText] = useState<string>("");
    const [filteredTrendViews, setFilteredTrendViews] = useState<TrendView[]>([]);

    const [planFilter, setPlanFilter] = useState<"D" | "W" | "M" | "A">("A")
    const [userFilter, setUserFilter] = useState<number|undefined>()

    const shownCloudUser = useMemo(() => users.find(u => u.id === userFilter), [userFilter, users])

    useEffect(() => {
        setUp("/reporting")
        get(`cloud/${server?.cloud}/reports/${orgId}`) // cloud reports are retrieved in order to display report name in the table
            .then((res: CloudReport[]) => {
                // making an object of all reports with id as key for faster lookup when rendering in table
                const _tmp: CloudReportMap = res.reduce((acc: CloudReportMap, cur) => { acc[cur.report_id] = cur; return acc }, {})
                setCloudReportsObj(_tmp)
                setCloudReports(res)
            }).catch(err => console.log(err))
    }, [orgId, setUp, server])

    useEffect(() => {
        setFilteredTrendViews(reporting_reports)
        setShownTrendViews(reporting_reports)
    }, [reporting_reports])

    useEffect(() => {
        search(searchText)
    }, [filteredTrendViews, searchText])

    useEffect(() => {
        filterTrendViews(reporting_reports)
    }, [planFilter, userFilter])

    const onClose = () => {
        setEditReportEmail(null)
        setAdd(false);
    }

    const onEdit = (report: TrendView) => {
        setEditReportEmail(report);
        setAdd(true)
    }

    const onDelete = (id: number) => {
        del(`reporting/${serverId}/trendview/${id}`).then(res => {
            update()
        }).catch(err => console.log(err))
    }

    const onDuplicate = (d: TrendView) => {
        d.id = 0
        post(`reporting/${serverId}/trendview`, d).then(res => {
            update()
        }).catch(err => console.log(err))
    }

    const onToggleActive =(d: Report) => {
        d.active = !d.active
        put(`reporting/${serverId}/trendview/${d.id}`, d).then(res => {
            update();
        }).catch(err => console.log(err))
    }
    
    const onSendMail = (d: Report, test:boolean) => {
        sendmail(d.plan_id?? 0, d.email, test)
    }

    function filterTrendViews(trendviews : TrendView[]){
        if(trendviews.length === 0) return;
        let filtered = [...trendviews]
        switch(planFilter){
            case "D":
            case "W":
            case "M":
                filtered = filtered.filter(d => getPlanInterval(d.plan_id ?? -1, plans).startsWith(planFilter))
                setFilteredTrendViews(filtered)
                break;
            case "A":
            default:
                // do nothing
        }
        if(userFilter && shownCloudUser){
            filtered = filtered.filter(d => d.email === shownCloudUser.email)
        }
        setFilteredTrendViews(filtered)
        if(!searchText) setShownTrendViews(filtered)
    }

    const search = (query:string) => {
        if (!query || query.length < 1) {
            setShownTrendViews(filteredTrendViews)
            return
        }

        const filtered = filteredTrendViews.filter(searchFilterFunc)
        setShownTrendViews(filtered)
    }

    const searchFilterFunc = (t: TrendView) => {
        if(!cloudReportsObj) return
        const name = cloudReportsObj[t.report_id].name ?? ""
        const nameMatch = name.toLowerCase().includes(searchText.toLowerCase())
        let recipientMatch = (t.firstname + " " + t.lastname).toLowerCase().includes(searchText.toLowerCase())
            
        return nameMatch || recipientMatch
    }


    return <div className="">
        <ReportingFilterBar
            items={reporting_reports}
            stats={getReportingStats(reporting_reports, plans)}
            shownItems={shownTrendViews}
            users={users}
            searchText={searchText}
            setSearchText={setSearchText}
            selectedPlan={planFilter}
            setSelectedPlan={setPlanFilter}
            userFilter={userFilter}
            setUserFilter={setUserFilter}
            setShowAddModal={setAdd}
            buttonColor="trendview"
        />
        <ReportingTable items={shownTrendViews} plans={plans} color="trendview" cloudObj={cloudReportsObj ?? {}} onEdit={onEdit} onDelete={onDelete} onDuplicate={onDuplicate} onToggleActive={onToggleActive} onSendMail={onSendMail} />
        <AddEmail orgId={parseInt(orgId ?? "-1")} server={server} users={users} cloudItems={cloudReports} open={add} route="trendview" onClose={onClose} getEmailItems={update} edittedEmailItem={editReportEmail} plans={plans} />
    </div>
}

export default ReportingTrendViews