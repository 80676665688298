import { ReportingItem, Plan } from "../../types/reporting_types"
import Table from "../Table/Table"
import TableBody from "../Table/TableBody"
import TableBodyCell from "../Table/TableBodyCell"
import TableHead from "../Table/TableHead"
import TableHeadCell from "../Table/TableHeadCell"
import TableHeadRow from "../Table/TableHeadRow"
import MenuDropdown from "../MenuDropdown"
import MenuDropdownItem from "../MenuDropdownItem"
import { HiDotsCircleHorizontal, HiPencil, HiTrash, HiArrowSmUp, HiArrowSmDown } from "react-icons/hi"
import { useMemo, useState } from "react"
import { CloudDashboardMap, CloudReportMap } from "../../types/cloud_types"
import { FiSend } from "react-icons/fi"
import { IoDuplicate } from "react-icons/io5"

const ReportingTable: React.FC<
    {
        items: ReportingItem[],
        plans: Plan[],
        cloudObj: CloudDashboardMap | CloudReportMap,
        onEdit(item: ReportingItem): any,
        onDelete(id: number): any,
        onDuplicate(item: ReportingItem): any,
        onToggleActive(item: ReportingItem): any,
        onSendMail(item: ReportingItem, test: boolean): any,
        color: "dashboard" | "dashboardtable" | "trendview" | "report",
    }
> = ({ items, plans, onEdit, onDelete, onDuplicate, onToggleActive, onSendMail, color, cloudObj}) => {
    const [sortedProp, setSortedProp] = useState("");
    const [direction, setDirection] = useState(false); // false = asc, true = desc

    const sortedItems = useMemo(() => items.slice().sort((a, b) => {
        if (typeof a[sortedProp] === "string") {
            const x = a[sortedProp].toLowerCase();
            const y = b[sortedProp].toLowerCase();
            if (!direction) {
                return x > y ? 1 : x < y ? -1 : 0
            } else {
                return x < y ? 1 : x > y ? -1 : 0;
            }
        } else {
            if (!direction) {
                return a[sortedProp] > b[sortedProp] ? 1 : a[sortedProp] < b[sortedProp] ? -1 : 0
            } else {
                return a[sortedProp] < b[sortedProp] ? 1 : a[sortedProp] > b[sortedProp] ? -1 : 0

            }
        }
    }), [items, sortedProp, direction]);

    const setSorting = (prop: string) => {
        if (sortedProp === prop) {
            setDirection(!direction)
        } else {
            setSortedProp(prop)
            setDirection(false)
        }
    }

    const formatDate = (timestamp: number): string[] => {
        const time = new Date(timestamp * 1000).toLocaleString('da-DK', { timeStyle: "short" })
        const date = new Date(timestamp * 1000).toLocaleDateString('da-DK', { timeZone: 'Europe/Copenhagen', year: 'numeric', month: '2-digit', day: '2-digit' })
        return [date.replaceAll('.', '-'), time.replaceAll('.', ':')]
    }

    const colorObj: { headerBg: string, headerBgHover: string } = useMemo(() => {
        if (color === "dashboard") return { headerBg: "bg-blue-400 bg-opacity-75 cursor-pointer", headerBgHover: "hover:bg-blue-400 hover:text-gray-900 bg-opacity-100" };
        if (color === "report") return { headerBg: "bg-green-400 bg-opacity-75 cursor-pointer", headerBgHover: "hover:bg-green-400 hover:text-gray-900 bg-opacity-100" };
        if (color === "dashboardtable") return { headerBg: "bg-yellow-400 bg-opacity-75 cursor-pointer", headerBgHover: "hover:bg-yellow-400 hover:text-gray-900 bg-opacity-100" };
        if (color === "trendview") return { headerBg: "bg-red-400 bg-opacity-75 cursor-pointer", headerBgHover: "hover:bg-red-400 hover:text-gray-900 bg-opacity-100" };
        return { headerBg: "", headerBgHover: "" };
    }, [color]);

    const getPlan = (plan_id: number): string => {
        var plan = plans.find(x => x.id === plan_id);
        if(plan !== undefined)
            return plan.description
        else
            return "??"
    }

    const headers: { header: string, propName: string }[] = [{ header: "ID", propName: "id" }, { header: "Dashboard Name", propName: "dashboard_id" }, { header: "Report Name", propName: "report_id" }, { header: "Email", propName: "email" }, { header: "Firstname", propName: "firstname" }, { header: "Lastname", propName: "lastname" }, { header: "Plan", propName: "plan" }, { header: "Last sent", propName: "last_sent" }, { header: "Link", propName: "link" }, {header: "Include table", propName: "include_table"}]
    const dashObj = cloudObj as CloudDashboardMap;
    const repObj = cloudObj as CloudReportMap;

    return <Table>
        <TableHead className={`rounded-table ${colorObj.headerBg}`}>
            <TableHeadRow className="text-gray-700">
                {headers.map((h, i) => items[0] && items[0][h.propName] !== undefined &&
                    <TableHeadCell key={i} className={`select-none`} onClick={() => setSorting(h.propName)}>
                        <div className="flex flex-row items-center">
                            <div className="mr-1">{h.header}</div>
                            <div className="w-5 h-5">
                                {h.propName === sortedProp && direction && <HiArrowSmUp className="h-5 w-5 text-gray-700" />}
                                {h.propName === sortedProp && !direction && <HiArrowSmDown className="h-5 w-5 text-gray-700" />}
                            </div>
                        </div>
                    </TableHeadCell>)}
                {items[0]?.dashboard_id && <TableHeadCell className={`cursor-default`}></TableHeadCell>}
                {items[0]?.report_id && <TableHeadCell className={`cursor-default`}></TableHeadCell>}
                <TableHeadCell className="text-center select-none" onClick={() => setSorting("active")}>
                    <div className="flex flex-row items-center">
                        <div className="mr-1">Status</div>
                        <div className="w-5 h-5">
                            {sortedProp === "active" && direction && <HiArrowSmUp className="h-5 w-5 text-gray-700" />}
                            {sortedProp === "active" && !direction && <HiArrowSmDown className="h-5 w-5 text-gray-700" />}
                        </div>
                    </div>
                </TableHeadCell>
                <TableHeadCell></TableHeadCell>
            </TableHeadRow>
        </TableHead>
        <TableBody className="rounded-table text-sm">
            {sortedItems.map((item, i) => <tr key={i} className={`${item.active ? "even:bg-gray-50" : "bg-gray-300"}`}>
                <TableBodyCell>{item.id}</TableBodyCell>
                {item.dashboard_id && <TableBodyCell>{dashObj[item.dashboard_id]?.title ?? "No name"}</TableBodyCell>}
                {item.report_id && <TableBodyCell>{repObj[item.report_id]?.name ?? "No name"}</TableBodyCell>}
                <TableBodyCell>{item.email}</TableBodyCell>
                <TableBodyCell>{item.firstname}</TableBodyCell>
                <TableBodyCell>{item.lastname}</TableBodyCell>
                {item.plan_id && <TableBodyCell>{getPlan(item.plan_id)}</TableBodyCell>}
                <TableBodyCell>{formatDate(item.last_sent)[0]} <span className="text-gray-500">{formatDate(item.last_sent)[1]}</span></TableBodyCell>
                <TableBodyCell>{item.link && <span>Yes</span>}{!item.link && <span className="text-gray-400">No</span>}</TableBodyCell>
                {item.include_table !== undefined && <TableBodyCell>{item.include_table && <span>Yes</span>}{!item.include_table && <span className="text-gray-400">No</span>}</TableBodyCell>}
                <TableBodyCell className="w-10">
                    <div  
                        title={`${item.last_sent !== 0 ? "Resend email":"Send email"}`}
                        onClick={() => {onSendMail(item, false)}}
                        className={`${ item.last_sent !== 0 && item.active ? "text-green-500 border-green-500 cursor-pointer" : "text-gray-500 border-gray-500 cursor-pointer"} border rounded-3xl px-2 h-6 w-fit`}
                    >
                        {item.last_sent !== 0 && item.active ? "Resend" : "Send"}
                    </div>
                </TableBodyCell> 
                <TableBodyCell className="w-10">
                    <div  
                        title={`${item.active ? "Deactivate" : "Activate"}`} 
                        onClick={() => onToggleActive(item)}
                        className={`${item.active ? "border-green-500 text-white bg-green-500" : "text-gray-500 border-gray-500"} cursor-pointer border rounded-3xl px-2 w-fit h-6`} 
                    >
                        {item.active ? "Activated" : "Activate"}
                    </div>
                </TableBodyCell>
                <TableBodyCell className="w-10">
                    <MenuDropdown color={"icon"} direction="up" expand="left" icon={<HiDotsCircleHorizontal className="w-6 h-6 text-indigo-700 transition duration-300 transform hover:scale-110 cursor-pointer" aria-hidden="true" />}>
                        <div className="px-1 py-1 ">
                            <MenuDropdownItem onClick={() => onEdit(item)} text={"Edit"} icon={<HiPencil className="w-5 h-5 mr-2 text-gray-700 opacity-80" />} />
                            <MenuDropdownItem onClick={() => onDuplicate(item)} text="Duplicate" icon={<IoDuplicate className="w-5 h-5 mr-2 text-blue-600 opacity-80" />}/>
                            <MenuDropdownItem onClick={() => {onSendMail(item, true)}} text={"Send test mail"} icon={<FiSend className="w-5 h-5 mr-2 text-yellow-500 opacity-80" />} />
                            <MenuDropdownItem onClick={() => {onDelete(item.id)}} text={"Delete"} icon={<HiTrash className="w-5 h-5 mr-2 text-red-700 opacity-80" />} />
                        </div>
                    </MenuDropdown>
                </TableBodyCell>
            </tr>)}
        </TableBody>
    </Table>
}

export default ReportingTable