import { get } from "../helpers/Requests";
import { Bucket } from "../types/bucket_types";
import { CloudOrgInfo } from "../types/cloud_types";
import { ServerData } from "../types/types";

export const getOrganizations = async function(servers: ServerData[], ) {
    let error: any[] = [];
    let response: CloudOrgInfo[] = []
    for ( const s of servers) {
        if (s.type === "cloud") {
           await get('cloud/' + s.id + '/organizations/info')
                .then((result: CloudOrgInfo[]) => {
                    result.forEach(r => {
                        r.server = s;
                        response.push(r)
                    })
                    
                })
                .catch(err => {
                    error.push(err);
                });
        }
        if (error.length > 0) {
            break;
        }
    } 
    return error.length > 0 ? Promise.reject(error) : Promise.resolve(response)
}

export const getBuckets = (serverId: number, orgId: number): Promise<Bucket[]> => {
        return get(`cloud/${serverId}/buckets/${orgId}`)
}
