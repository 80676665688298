import { Dispatch, SetStateAction, useState } from "react"
import { ActionCategoryColor, ActionTemplateCategory, ActionTemplateLayoutWithTemplateName } from "../../types/template_types"
import CustomInput from "../CustomInput"
import { useOutsideClick } from "../../helpers/useOutsideClick"
import { FaArrowDown, FaArrowLeft, FaArrowUp, FaCheck, FaClock, FaPlus, FaTrashAlt } from "react-icons/fa"
import { actionCategoryColors as colors } from "../../types/template_types"
import { bsColorToTWMap } from "../../helpers/template_helpers"
import { DragEndEvent, DragStartEvent } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import SortableList from "../SortableList"

interface ActionTemplateLayoutSetupProps {
    recentLayouts: ActionTemplateLayoutWithTemplateName[]
    doneLayout: ActionTemplateCategory[]
    notDoneLayout: ActionTemplateCategory[]
    setDoneLayout: Dispatch<SetStateAction<ActionTemplateCategory[]>>
    setNotDoneLayout: Dispatch<SetStateAction<ActionTemplateCategory[]>>
}

function ActionTemplateLayoutSetup(props:ActionTemplateLayoutSetupProps){
    const [activeDragCategory, setActiveDragCategory] = useState<ActionTemplateCategory | null>(null)
    
    const handleCategoryNameChange = (newName: string, index: number, donecount: boolean) => {
        const updatedLayout = structuredClone(donecount ? props.doneLayout : props.notDoneLayout)
        updatedLayout[index].name = newName
        donecount ? props.setDoneLayout(updatedLayout) : props.setNotDoneLayout(updatedLayout)
    }

    const handleCategoryColorChange = (newColor: ActionCategoryColor, index: number, donecount: boolean) => {
        const updatedLayout = structuredClone(donecount ? props.doneLayout : props.notDoneLayout)
        updatedLayout[index].color = newColor
        donecount ? props.setDoneLayout(updatedLayout) : props.setNotDoneLayout(updatedLayout)
    }

    const handleRemoveCategory = (index: number, donecount: boolean) => {
        const updatedLayout = structuredClone(donecount ? props.doneLayout : props.notDoneLayout)
        updatedLayout.splice(index, 1)
        donecount ? props.setDoneLayout(updatedLayout) : props.setNotDoneLayout(updatedLayout)
    }

    const handleAddCategory = (donecount: boolean) => {
        const newCategory:ActionTemplateCategory = {
            name: "",
            color: colors[Math.floor(Math.random()*colors.length)],
            donecount
        }
        donecount ? props.setDoneLayout([...props.doneLayout, newCategory]) : props.setNotDoneLayout([...props.notDoneLayout, newCategory])
    }
    
    const handleDoneCountToggle = (index: number, donecount: boolean) => {
        const updatedLayout = donecount ? props.doneLayout : props.notDoneLayout
        const cToMove = updatedLayout[index]
        cToMove.donecount = !cToMove.donecount
        if(cToMove.donecount){
            props.setDoneLayout([...props.doneLayout, cToMove])
            props.setNotDoneLayout([...props.notDoneLayout.filter(c => !c.donecount)])
        } else {
            props.setNotDoneLayout([...props.notDoneLayout, cToMove])
            props.setDoneLayout([...props.doneLayout.filter(c => c.donecount)])
        }
    }

    const moveCategory = (oldIndex: number, newIndex: number, donecount: boolean | undefined) => {
        donecount ? props.setDoneLayout(arrayMove(props.doneLayout, oldIndex, newIndex)) : props.setNotDoneLayout(arrayMove(props.notDoneLayout, oldIndex, newIndex))
    }

    const onDragStart = (e: DragStartEvent) => {
        const {active} = e
        // categories does not have a unique identifier, so we use category name + index in layout list
        let draggedCategory = props.doneLayout.find((c, i) => "done"+i === active.id)
        if(!draggedCategory){
            draggedCategory = props.notDoneLayout.find((c, i) => "notdone"+i === active.id)
        }
        if(!draggedCategory){
            return;
        }
        setActiveDragCategory(draggedCategory)
    }

    const onDragEnd = (e: DragEndEvent) => {
        const {active, over} = e
        if(active.id !== over?.id && !!over && !!activeDragCategory) {
            const donecount = activeDragCategory.donecount 
            const oldIndex = donecount 
                ? props.doneLayout.findIndex((c, i) => "done"+i === active.id) 
                : props.notDoneLayout.findIndex((c, i) => "notdone"+i === active.id)
            const newIndex = donecount 
                ? props.doneLayout.findIndex((c, i) => "done"+i === over.id) 
                : props.notDoneLayout.findIndex((c, i) => "notdone"+i === over.id)
            if((oldIndex === 0 || newIndex === 0) && !donecount) {
                return;
            }
            moveCategory(oldIndex, newIndex, donecount)
        }
    }

    return <div className="flex justify-between w-full">
        <div>
            <div className="text-xl font-semibold mb-5">Edit Layout</div>
            <div className="text-sm font-semibold mb-1"><FaClock className="inline-block mr-1 mb-1 text-xs" />Not completed</div>
            <SortableList
                items={props.notDoneLayout.map((c, i) => ({
                    id: "notdone"+i,
                    children: (<LayoutCategoryInput 
                        key={"notdone"+i}
                        index={i} 
                        category={c}
                        donecount={false}  
                        handleColorChange={handleCategoryColorChange} 
                        handleNameChange={handleCategoryNameChange} 
                        handleRemove={handleRemoveCategory}
                        handleDoneCountToggle={handleDoneCountToggle}
                        length={props.notDoneLayout.length}
                    />),
                    disabled: i===0
                }))}
                dragHandle={true}
                itemClassName="flex items-center gap-2 p-1 rounded"
                containerClassName="flex flex-col gap-2 z-0"
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
            />
            <AddCategoryButton onClick={() => handleAddCategory(false)}/>
            <div className="text-sm font-semibold mt-5 mb-1"><FaCheck className="inline-block mr-1 text-xs mb-1" />Completed</div>
            <SortableList
                items={props.doneLayout.map((c, i) => ({
                    id: "done"+i,
                    children: (<LayoutCategoryInput 
                        key={"done"+i}
                        index={i} 
                        category={c}
                        donecount={true}  
                        handleColorChange={handleCategoryColorChange} 
                        handleNameChange={handleCategoryNameChange} 
                        handleRemove={handleRemoveCategory}
                        handleDoneCountToggle={handleDoneCountToggle}
                        length={props.doneLayout.length}
                    />)
                }))}
                dragHandle={true}
                itemClassName="flex items-center gap-2 p-1 rounded"
                containerClassName="flex flex-col gap-2"
                onDragEnd={onDragEnd}
                onDragStart={onDragStart}
            />
            <AddCategoryButton onClick={() => handleAddCategory(true)}/>
        </div>
        {props.recentLayouts && props.recentLayouts.length > 0 && <div className="flex flex-col gap-2 w-fit max-w-[900px] min-w-[500px]">
            <div className="font-semibold">Recent custom layouts</div>
            <div className="max-h-[500px] overflow-y-auto">
                {props.recentLayouts.map((l, i) => <RecentLayout 
                    key={i} 
                    layout={l.layout} 
                    templateName={l.template_name} 
                    onSelectLayout={() => {
                        props.setNotDoneLayout(structuredClone(l.layout.filter(c => !c.donecount)))
                        props.setDoneLayout(structuredClone(l.layout.filter(c => c.donecount)))
                    }} 
                />)}
            </div>
        </div>}
    </div>
}

interface RecentLayoutProps {
    templateName: string
    layout: ActionTemplateCategory[]
    onSelectLayout: () => void
}

function RecentLayout(props:RecentLayoutProps){
    return (
        <div>
            <div className="ml-8 text-sm font-semibold">{props.templateName}</div>
            <div className="flex gap-2 items-center">
                <div 
                    className="rounded border bg-white hover:bg-gray-100 flex items-center justify-center w-7 h-7"
                    onClick={props.onSelectLayout}    
                >
                    <FaArrowLeft />
                </div>
                <div className="border-t-2 border-gray-300 py-2 flex gap-1 w-full flex-wrap">
                    {props.layout.map((c, i) => <div key={i} className={`${bsColorToTWMap.get(c.color)} text-white p-0.5 rounded`}>{c.name}</div>)}
                </div>
            </div>
        </div>
    )
}

interface LayoutCategoryInputProps{
    category: ActionTemplateCategory
    index: number
    donecount: boolean
    length: number
    handleColorChange: (c : ActionCategoryColor, index: number, donecount: boolean) => void
    handleNameChange: (name: string, index: number, donecount: boolean) => void
    handleRemove: (index: number, donecount: boolean) => void
    handleDoneCountToggle: (index: number, donecount: boolean) => void
}

function LayoutCategoryInput(props:LayoutCategoryInputProps){
    
    return (
        <div className="flex gap-2">
            <ColorBox color={props.category.color} onColorSelect={(co) => props.handleColorChange(co, props.index, props.donecount)} />
            <div className="w-96">
                <CustomInput value={props.category.name} onChange={(e) => props.handleNameChange(e.target.value, props.index, props.donecount)} placeholder="Category name"/>
            </div>
            {(props.index !== 0 || props.donecount) && <>
                <div className="flex items-center justify-center w-9 h-9 rounded cursor-pointer hover:bg-white text-xs" onClick={() => props.handleDoneCountToggle(props.index, props.donecount)}>
                    {!props.category.donecount && <FaArrowDown />}
                    {props.category.donecount && <FaArrowUp />}
                </div>
                <div 
                    className="rounded border flex items-center justify-center w-9 h-9 bg-white cursor-pointer hover:bg-gray-100"
                    onClick={() => props.handleRemove(props.index, props.donecount)}
                >
                    <FaTrashAlt />
                </div>
            </>}
        </div>
    )
}

interface AddCategoryButtonProps {
    onClick: () => void
}

function AddCategoryButton(props:AddCategoryButtonProps){
    return (
        <div 
            className="border rounded bg-white text-sm font-semibold w-fit px-2 py-0.5 mt-2 hover:bg-gray-100 cursor-pointer"
            onClick={props.onClick}
        >
            <FaPlus className="inline-block mr-1 text-xs mb-1"/>
            Add category
        </div>
    )
}

interface ColorBoxProps{
    color: ActionCategoryColor
    onColorSelect: (c: ActionCategoryColor) => void
}

function ColorBox(props:ColorBoxProps){
    const [showOptions, setShowOptions] = useState<boolean>(false)
    const ref = useOutsideClick(() => setShowOptions(false));
    
    return (<div className="relative" ref={ref}>
        <div 
            className={`${bsColorToTWMap.get(props.color)} w-9 h-9 rounded cursor-pointer`} 
            onClick={() => setShowOptions(prev => !prev)}
        >      
        </div>
        {showOptions && <ColorPicker 
            open={showOptions}
            onColorSelect={props.onColorSelect} 
            onClose={() => setShowOptions(false)}
        />}
    </div>
    )
}

interface ColorPickerProps {
    onColorSelect: (c: ActionCategoryColor) => void
    onClose: () => void
    open: boolean
}

function ColorPicker(props:ColorPickerProps){
    return (
        <div className={`${props.open ? "": "hidden"} absolute top-10 bg-white border rounded flex gap-1 items-center flex-wrap w-64 p-2 z-50`}>
            {colors.map((c, i) => (
                <div 
                    key={i} 
                    className={`${bsColorToTWMap.get(c)} w-9 h-9 cursor-pointer rounded`}
                    onClick={() => {props.onColorSelect(c); props.onClose()}}
                ></div>
            ))}
        </div>
    )
}

export default ActionTemplateLayoutSetup