import { Combobox, Transition } from "@headlessui/react";
import React, { ComponentProps, Fragment, useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ShowIf from "./ShowIf";

interface InputWithSuggestionsProps extends ComponentProps<typeof Combobox> {
    value: string,
    onChange: (value: string) => void,
    options: string[],
    placeholder?: string,
    direction?: "up" | "down"
}

const InputWithSuggestions = ({value, onChange, options, direction = "down", ...props}: InputWithSuggestionsProps) => {

    const [query, setQuery] = useState("")

    const filteredOptions = query === "" ? options.filter(o => o.trim() !== "") : options.filter(o => o.trim() !== "" && o.toLowerCase().includes(query.toLowerCase()))

    return (
        <Combobox value={value} onChange={onChange} {...props}>
            <div className="relative mt-1">
                <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                    <Combobox.Input
                        className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                        onChange={(event) => setQuery(event.target.value)}
                        displayValue={v => v as string}
                        placeholder={props.placeholder}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        {direction === "up" ? <FaChevronUp className="h-4 w-4 text-gray-400" /> : <FaChevronDown className="h-4 w-4 text-gray-400" />}
                    </Combobox.Button>
                </div>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Combobox.Options className={`absolute mt-1 max-h-60 ${direction === "up" ? "bottom-full" : ""} w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm`}>
                        <ShowIf if={query.trim() !== ""}>
                        <Combobox.Option 
                            value={query} 
                            className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-orange-100 text-black' : 'text-gray-900'}`
                            }
                        >
                            <span className="font-bold">
                                Create '{query}'
                            </span>
                        </Combobox.Option>
                        </ShowIf>
                        <Combobox.Option 
                            value={""} 
                            className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-orange-100 text-black' : 'text-gray-900'}`
                            }
                        >
                            <span className="text-gray-400">
                                None
                            </span>
                        </Combobox.Option>
                        {filteredOptions.map((o) => (
                                    <Combobox.Option
                                        key={o}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-orange-100 text-black' : 'text-gray-900'}`
                                        }
                                        value={o}
                                    >
                                        {({ active }) => (
                                            <>
                                                <span className={`block truncate font-normal`} >
                                                    {o}
                                                </span>
                                            </>
                                        )}
                                    </Combobox.Option>
                                ))
                        }
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    )
}

export default InputWithSuggestions
