import moment from "moment"
import { Table, TableHead, TableHeadRow, TableHeadCell, TableBody, TableBodyRow, TableBodyCell } from "../Table"
import { CloudUser, CloudUserSession } from "../../types/cloud_types"

type UserLogProps = {
    user: CloudUser | null
    logs: CloudUserSession[]
}
export default function UserLog({ user, logs }: UserLogProps) {

    return <div className='edit-user-tab-content'>
        <Table>
            <TableHead >
                <TableHeadRow>
                    <TableHeadCell>Email</TableHeadCell>
                    <TableHeadCell>Name</TableHeadCell>
                    <TableHeadCell>Requests</TableHeadCell>
                    <TableHeadCell>Login Time</TableHeadCell>
                    <TableHeadCell>Duration</TableHeadCell>
                </TableHeadRow>
            </TableHead>
            <TableBody >
                {
                    logs.map((log, i) => {
                        return (
                            <TableBodyRow key={i} >
                                <TableBodyCell >{user?.email}</TableBodyCell>
                                <TableBodyCell >{`${user?.firstname} ${user?.lastname}`}</TableBodyCell>
                                <TableBodyCell>{log.requests}</TableBodyCell>
                                <TableBodyCell>{moment.unix(log.start_time).fromNow()}</TableBodyCell>
                                <TableBodyCell>{moment.duration({ second: log.last_activity - log.start_time }).humanize()}</TableBodyCell>
                            </TableBodyRow>
                        )
                    })
                }
            </TableBody>
        </Table>
    </div>

}